import { useAtomValue } from 'jotai';
import { Perf } from 'r3f-perf';
import { showPerformanceAtom } from '../../atoms/three';

const ThreePerformance = () => {

	const showPerformance = useAtomValue(showPerformanceAtom);

	return ( 
		<>
			{/* performance hud */}
			{showPerformance && <Perf position="bottom-right" />}
		</>
	);
};
 
export default ThreePerformance;