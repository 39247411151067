import * as THREE from 'three';
import { useEffect, useMemo, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF, SkeletonUtils } from 'three-stdlib';
import rig from '../glb/bg_armature.glb';
import { useGraph } from '@react-three/fiber';
import marcModel from '../glb/bg_marc.glb';
import morganModel from '../glb/bg_morgan.glb';
import chrisModel from '../glb/bg_chris.glb';
import yaraModel from '../glb/bg_yara.glb';
import carlosModel from '../glb/bg_carlos.glb';
import randomInterval from 'react-random-interval-hook'; 


type GLTFResult = GLTF & {
  nodes: {
    bg_character: THREE.SkinnedMesh
    mixamorigHips: THREE.Bone
    neutral_bone: THREE.Bone
  }
  materials: {
    bg_material: THREE.MeshStandardMaterial
  }
  animations: GLTFAction[]
};

export type ActionName =
  | 'basic_apose.001'
  | 'checking_pockets'
  | 'checking_storage'
  | 'chicken_dance'
  | 'idle_01'
  | 'idle_dontuse'
  | 'placing_cards'
  | 'taking_02'
  | 'talking_01'
  | 'talking_phone_01'
  | 'talking_texting_01'
  | 'talking_texting_02';
interface GLTFAction extends THREE.AnimationClip {
  name: ActionName
}

export const backgroundModels = {
	'marc': marcModel,
	'chris': chrisModel,
	'carlos': carlosModel,
	'morgan': morganModel,
	'yara': yaraModel,
	// 'lisa': lisaModel,
};

type props = {
  model: Models,
  anim: ActionName
};

export type Models = keyof typeof backgroundModels;

export default function BackgroundCharacter(props: JSX.IntrinsicElements['group'] & props) {
	const group = useRef<THREE.Group>(null);
	const meshModel = useGLTF(backgroundModels[props.model]) as GLTFResult;
	const rigModel = useGLTF(rig) as GLTF;
	const { actions } = useAnimations(rigModel.animations, group);
	
	//Skinned meshes cannot be re-used in threejs without cloning them
	const meshClone = useMemo(() => SkeletonUtils.clone(meshModel.scene), [meshModel.scene]);
	const mesh = useGraph(meshClone) as GLTFResult;
	const meshRef = useRef<THREE.SkinnedMesh>(null);

	useEffect(() => {
		const anim = props.anim as ActionName;
		Object.values(actions).map((action) => action?.stop().reset());
		actions[anim]?.play();
	}, [props.anim]);

	const Blink = () => {		
		if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;

		meshRef.current.morphTargetInfluences[0] = 1;
		setTimeout(() => {
			if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;
			meshRef.current.morphTargetInfluences[0] = 0;
		}, 100);
	};

	randomInterval(() => {
		Blink();
	}, 1000, 5000);

	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<group name="bg_armature">
					<primitive object={mesh.nodes.mixamorigHips} />
				</group>
				<skinnedMesh 
					ref={meshRef}
					name="bg_character" 
					geometry={mesh.nodes.bg_character.geometry} 
					material={mesh.materials.bg_material} 
					skeleton={mesh.nodes.bg_character.skeleton} 
					morphTargetDictionary={mesh.nodes.bg_character.morphTargetDictionary}
					morphTargetInfluences={mesh.nodes.bg_character.morphTargetInfluences}
				/>
			</group>
		</group>
	);
}

// useGLTF.preload('/bg_marc-transformed.glb');
