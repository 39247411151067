import styled from 'styled-components';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Smiley } from '../smileys/Smileys';
import EllieSays from '../../Ellie/EllieSays';
import { gameResultAtom } from '../../../atoms/gameResults';
import { Answer } from '../../../atoms/content';
import { MdOutlineReplay } from 'react-icons/md';
import { FaFlag, FaLock, FaLockOpen } from 'react-icons/fa6';
import { seenMessagesAtom } from '../../../atoms/messaging';
import { RiKey2Fill } from 'react-icons/ri';

const ChoicesTimeline = () => {

	const gameResult = useAtomValue(gameResultAtom);
	const {t} = useTranslation();
	const [seenMessages,] = useAtom(seenMessagesAtom);

	return ( 
		<>
			<EllieSays>
				{t('game.feedback.steps.1.ellie_explains')}
			</EllieSays>
			<Container>
				<Subcontainer>
					<Timeline>
						<IconBubble>
							<RiKey2Fill/>
						</IconBubble>
						<Line/>
						<IconBubble>
							<FaFlag/>
						</IconBubble>
					</Timeline>
					<Choices>
						{gameResult.choices.map((choice) => {
							const selectedAnswer = choice.answers[choice.selected];
							return (
								<>
									<Choice>
										<ChoiceHeading>
											{t(`message.${choice.messageId}.a${choice.selected + 1}_feedback_title`)}
											{ selectedAnswer.correctness === 0 ? <Smiley $variant='sad'/>
												: selectedAnswer.correctness === 1 ? <Smiley $variant='neutral'/>
													:<Smiley $variant='happy'/>}
										</ChoiceHeading>
										<p style={{marginBottom: '20px'}}>
											{t(`message.${choice.messageId}.a${choice.selected + 1}_feedback_body`)}</p>
										<p><b>{t('game.feedback.timeline.options.title')}</b></p>
										<ul style={{marginTop: 0}}>
											{ choice.answers.map((answer, ii) => {
												const conditional = answer.conditional_requirement ? seenMessages.includes(answer.conditional_requirement) : undefined;
												return (
													<Li conditional={conditional} key={`feedback-choice-answers-entry-${ii}`}>
														<p> {conditional != undefined && (conditional === true ? <FaLockOpen/> : <FaLock/>)}
															{' ' + t(`message.${choice.messageId}.a${ii+1}_text`)+ ' '}
															<b>{ii === choice.selected && `(${t('game.feedback.timeline.options.selected')})`}</b>
														</p>
													</Li>
												);}
											)}
										</ul>
										<ChoiceLink $correct={selectedAnswer.correctness}/>
									</Choice>
									{ choice.reset && 
										<Reset>
											<MdOutlineReplay/>
											<ChoiceLink $correct={undefined}/>
										</Reset> 
									}
								</>
							);
						})}
					</Choices>
				</Subcontainer>
			</Container>
		</>
	);
};

// styled
const Li = styled.li<{conditional: boolean | undefined}>`
	& > :first-child{
		& > b{
			color: ${p => p.theme.colors.neutralDarkest};
		}
		color: ${p => 
		p.conditional === undefined ? p.theme.colors.neutralDarkest :
			(p.conditional === true ? 
				p.theme.colors.correct 
				: p.theme.colors.danger)
};
	}
`;

const Container = styled.div`
	flex-grow: 1;
`;

const Subcontainer = styled.div`
	display: flex;
`;

const TimelineVariables = {
	sm: {
		marginBlock: 10,
		width: 50
	},
	lg: {
		marginBlock: 50,
		width: 100
	}
};


const Timeline = styled.div`
	margin-block: ${TimelineVariables.lg.marginBlock}px;
	width: ${TimelineVariables.lg.width}px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		width: ${TimelineVariables.sm.width}px;
		margin-block: ${TimelineVariables.sm.marginBlock}px;
	}
`;

const Choices = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 50px;
	margin-inline-end: ${TimelineVariables.lg.width / 2}px;
	margin-block: ${TimelineVariables.lg.marginBlock * 2}px;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		margin-block: ${TimelineVariables.sm.marginBlock * 2}px;
		margin-inline-end: ${TimelineVariables.sm.marginBlock}px;
	}


`;

const Choice = styled.div`

	position: relative;

	width: 100%;
	background-color: ${p => p.theme.colors.neutralLight};
	border-radius: ${p => p.theme.properties.borderRadius};
	padding-inline: 20px;
	padding-top: 15px;

	& > ul{
		padding-inline-start: 1.5em;
	}
`;


const Reset = styled(Choice)`

	display: flex;
	align-items: center;
	width: fit-content;
	padding: 20px;
	font-size: 3em;

`;

const ChoiceHeading = styled.h3`
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	& > div {
		margin-inline-start: .5em;
		flex-shrink: 0;
	}
`;

const ChoiceLink = styled.div<{$correct: Answer['correctness']}>`
	position: absolute;
	inset-inline-start: -${TimelineVariables.lg.width / 2}px;
	top: 50%;
	translate: 0 -50%;
	width: ${TimelineVariables.lg.width / 2}px;
	height: 3.5px; 
	background-color: ${p => p.theme.colors.neutral};

	&:before {
		content: ' ';
		position: absolute;
		inset-inline-start: 0;
		top:50%;
		translate: ${p => `${p.theme.direction === 'ltr' ? '-' : ''}50%`} -50%;
		outline: solid 3.5px ${p => p.theme.colors.neutral};
		background-color: ${p => p.$correct === 0 ? p.theme.colors.danger 
		: p.$correct === 1 ? p.theme.colors.alert 
			: p.$correct === 2 ? p.theme.colors.correct
				: p.theme.colors.neutral
};
		height: 15px;
		aspect-ratio: 1/1;
		border-radius: 100%;
	}

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		left: -${TimelineVariables.sm.width / 2}px;
		width: ${TimelineVariables.sm.width / 2}px;
	}
`;

const Line = styled.div`
	flex-grow: 1;
	width: 5px;
	background-color: ${p => p.theme.colors.neutral};
`;

const IconBubble = styled.div`
	border-radius: 20%;
	background-color: ${p => p.theme.colors.neutral};
	height: 40px;
	aspect-ratio: 1/1;

	color: ${p => p.theme.colors.neutralLightest};

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 1.75em;
`;
 
export default ChoicesTimeline;