import styled from 'styled-components';
import { fadeIn, slideInBottom } from '../../../../style/animations';
import EllieAvatar from '../../../EllieAvatar';
import { activeMessageAtom, seenClickToContinueContextMessageAtom } from '../../../../atoms/messaging';
import { useAtom } from 'jotai';
import i18n from '../../../../tools/i18n';
import { useEffect, useMemo } from 'react';
import { ContextMessage as ContetMessageType, Message as MessageType } from '../../../../atoms/content';
import { t } from 'i18next';
import ReactMarkdown from 'react-markdown';
import { AudioLibrary } from '../../../audio/Audio';

const ContextMessage = ({messageData}: MessageProps) => {
    
	const [seenClickToContinueContextMessage] = useAtom(seenClickToContinueContextMessageAtom);
	const [activeMessageId] = useAtom(activeMessageAtom);

	const clickToContinue = useMemo(() => {
		if(!messageData || messageData.message_type === 1) return false;
		else return true;
	},[messageData]);

	useEffect(() => {
		if(messageData.reset) 
			setTimeout(() => {
				AudioLibrary['ellie_reset'].load().play();
			}, 500);
	}, [messageData]);

	return ( 
		<ContextContainer style={{direction: i18n.dir()}}>
			<Message>
				<ReactMarkdown>{t(`message.${activeMessageId}.text`)}</ReactMarkdown>
			</Message>
			<Ellie $emotion={messageData.ellie_emotion}/>
			{ !seenClickToContinueContextMessage && clickToContinue && <Tutorial>{t('game.contextmessage.tutorial.clicktocontinue')}</Tutorial> }
		</ContextContainer>
	);
};

const ContextContainer = styled.div`
	position: relative;
	animation: ${slideInBottom} 1s ease forwards;

	width: 100%;
	height: 100%;

	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	
	align-items: flex-end;
	justify-items: stretch;
	align-items: center;

	gap: 10px;

	padding: 20px;

	& > * {
		pointer-events: all;
	}
	
`;

const Ellie = styled(EllieAvatar)`
	grid-row: 3;
	grid-column: 3 / span 1;

	height: unset;
	align-self: stretch;

`;

const Message = styled.div`

	position: relative;


	grid-row: 3;
	grid-column: span 2;
	background-color: white;
	border-radius: 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	padding: 30px;
	font-size: .75em;

	@media (max-width: ${p => p.theme.responsive.media.lg}){

		font-size: 1em;
	}

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		padding: 15px;
		font-size: 1.3em;
	}

	&:before {
		content:'';
		position:absolute;
		border: 20px solid transparent;
		border-block-width: 15px;
		border-inline-start-color: ${p => p.theme.colors.neutralLightest};
		height:0; width: 0;

		inset-inline-start: 100%;
	}
`;

const Tutorial = styled.div`
	grid-row: 2;
	grid-column: span 3;
	color: white;
	text-align: center;

	opacity: 0;
	animation: ${fadeIn} 1s ease-in forwards;
	animation-delay: 1.5s;
`;

// types

type MessageProps = {
	messageData: MessageType<ContetMessageType>,
};

export default ContextMessage;