import styled from 'styled-components';
import { fadeIn, fadeOut } from '../../style/animations';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { enableFifoAtom, fifoDelay } from '../../atoms/fifo';
import { handleGemQueueAtom } from '../../atoms/gem';
import { resetingStateAtom } from '../../atoms/messaging';

/**
 * A component which obscures the whole screen and then instructs the GEM to handle the queue.
 * @returns 
 */
export const FIFO = () => {

	const [enableFifo, setEnableFifo] = useAtom(enableFifoAtom);

	const [state, setState] = useState<FIFOState>(0);
	const [, setHandleGemQueue] = useAtom(handleGemQueueAtom);
	const [resetingState, setResetingState] = useAtom(resetingStateAtom);

	const onAnimationEnd = () => {
		switch(state){
		case 1:
			setState(2);
			break;
		case 3:
			if(resetingState === 1) setResetingState(2);
			setEnableFifo(false);
		}
	};

	// toggles state for fifo based on the 'enableFifo' value.
	useEffect(() => {
		if(enableFifo){
			setState(1);
		}
		else{
			setState(0);
		}
	}, [enableFifo]);

	useEffect(() => {
		if(state === 2){ 
			setHandleGemQueue(true);
			
			setTimeout(() => {
				setState(3);
			},fifoDelay);
		}
	}, [state]);

	return ( 
		<>
			{state !== 0 && <Container $state={state} onAnimationEnd={onAnimationEnd}/> }
		</>
	);
};

// styled components

const Container = styled.div<{$state: FIFOState}>`
	position: absolute;
	inset: 0;
	z-index:2;
	background-color: black;
	animation: ${p => p.$state === 1 ? fadeIn : p.$state === 3 ? fadeOut : ''} 1s forwards;
`;

// types

/**
 * FIFOState indicates in which state the FIFO component is currently. 
 * 0 means not being rendered, 1 means fading in, 2 means fade in complete and processing actions and 3 means fading out.
 */
export type FIFOState = 0 | 1 | 2 | 3;
 
