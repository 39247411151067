import styled, { css } from 'styled-components';
import { activeMessageAtom, messageTimerAtom, resetingStateAtom, seenMessagesAtom, selectionDelay } from '../../../../atoms/messaging';
import { PillButton } from '../../../buttons/PillButton';
import { fadeOut, slideInRight } from '../../../../style/animations';
import { useAtom, useAtomValue } from 'jotai';
import { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { shuffle } from 'lodash';
import { Answer, Message as MessageType, StandardMessage } from '../../../../atoms/content';
import { gameResultAtom } from '../../../../atoms/gameResults';
import ImportantDecisionIndicator from './ImportantDecisionIndicator';
import { t } from 'i18next';

const Options = ({ data, timerEnded, onContinue }: OptionsProps) => {

	//#region data needed for component
	const activeMessageId = useAtomValue(activeMessageAtom);
	const [seenMessages,] = useAtom(seenMessagesAtom);
	const timer = useAtomValue(messageTimerAtom);
	const [gameResults, gameResultDispatch] = useAtom(gameResultAtom);
	const [resetingState, setReseting] = useAtom(resetingStateAtom);
	const [interactive, setInteractive] = useState<boolean>(false);
	const [selected, setSelected] = useState<number | undefined>();
	/** used to determine wether the choice made is correct or not. Undefined means that the player has not made a choice yet.*/
	const [correct, setCorrect] = useState<number | undefined>();

	// variables to make sure that the state is rerendered.
	const [showOptions, setShowOptions] = useState<boolean>(true);
	const showOptionsTimeout = useRef<NodeJS.Timeout>();

	const order = useMemo(() => {
		if (!data.answers) return undefined;
		const newOrder = [...Array(data.answers.length).keys()];
		return shuffle(newOrder);
	}, [data.answers]);

	/**
	 * Indicates wether the current message has an important decision that can be made.
	 */
	const importantDecision = useMemo(() => {
		let important = false;
		data.answers.map((answer) => {
			setCorrect(undefined);
			if (answer.correctness !== null && answer.correctness !== undefined) important = true;
		});
		return important;
	}, [data]);

	/**
	 * Stores answers that are still available
	 */
	const optionsAvailable = useMemo(() => {
		const options: number[] = [];

		data.answers.map((answer, index) => {
			// //check if conditional is met
			if (answer.conditional_requirement && !seenMessages.includes(answer.conditional_requirement))
				return;
			if (gameResults.choices.find((choice) => choice.messageId === activeMessageId && choice.selected === index))
				return;

			options.push(index);
		});

		return options;
	}, [activeMessageId]);

	//#endregion

	//#region useEffects

	useEffect(() => {
		// this block of code makes sure that options are rerenderd even if the messageData immediately requests a new set of options.
		setShowOptions(false);
		if (showOptionsTimeout.current) clearTimeout(showOptionsTimeout.current);
		showOptionsTimeout.current = setTimeout(() => {
			setShowOptions(true);
		}, 100);

		setInteractive(false);
	}, [data.answers]);

	//  triggers on time end.
	useEffect(() => {
		if (!timerEnded || optionsAvailable.length === 0) return;

		let selectedIncorrectAnswer = false;
		if (importantDecision) {
			for (let index = 0; index < data.answers.length; index++) {
				const answer = data.answers[index];
				if (answer.correctness === 0 && optionsAvailable.includes(index)) {
					onSelect(index); selectedIncorrectAnswer = true;
					console.log('yeet');
					break;
				}
			}
		}
		if (!selectedIncorrectAnswer) //select random option
			onSelect(optionsAvailable[Math.floor(Math.random() * optionsAvailable.length)]);

	}, [timerEnded]);

	useEffect(() => {
		if (!activeMessageId) return;
		setSelected(undefined);
	}, [activeMessageId]);

	//#endregion

	//#region methods

	/**
	 * Stores crucial choices made by the player.
	 * @param selected index of the selected answer
	 * @param answers array of all the answers 
	 */
	const storeChoice = (selected: number, answers: Answer[]) => {
		gameResultDispatch({ type: 'store-choice', entry: { selected: selected, answers: answers, messageId: activeMessageId } });
		setTimeout(() => { if (resetingState === 2) setReseting(0); }, 500);//delay
	};

	const onSelect = (index: number) => {
		if (selected !== undefined) return;
		timer && timer.pause();
		setSelected(index);

		// save choice if correct is not undefined
		data.answers[index].correctness !== null && data.answers[index].correctness !== undefined && storeChoice(index, data.answers);

		// set correct for the important decision indicator
		setCorrect(data.answers[index].correctness);

		// delay before the next message is shown
		setTimeout(() => {
			onContinue(index);
		}, selectionDelay * 1000);
	};

	//#endregion


	return (
		<>
			{showOptions && order &&
				<>
					<ImportantDecisionIndicator important={importantDecision} correct={correct} />
					{data.answers.map((option, index) =>
						<Option
							$disabled={!optionsAvailable.includes(index)}
							$interactive={!optionsAvailable.includes(index) ? false : interactive ? true : false}
							$selected={selected === index}
							$index={order[index]}
							$fadeout={selected !== undefined && selected !== index ? true : false}
							onAnimationEnd={() => !interactive && setInteractive(true)}
							style={{ order: order[index] }}
							key={`message-option-${option}-${index}`}
							onClick={() => { onSelect(index); }}

						>
							{t(`message.${activeMessageId}.a${index + 1}_text`)}
						</Option>
					)}
				</>
			}
		</>
	);
};

// styled components

const Option = styled(PillButton) <{ $selected: boolean, $index: number, $fadeout: boolean, $disabled: boolean }>`
	max-width: 100%;
	line-height: 100%;
	${p => p.$selected && css`
		background-color: ${p => p.theme.colors.neutralLightest};
		color: ${p => p.theme.colors.primary};
	`}
	opacity: 0;
	animation: ${slideInRight} 1s forwards;
	animation-delay: ${p => `${1 + p.$index * 0.2}s`};

	${p => p.$fadeout && css`
		translate: 0 0;
		animation: ${fadeOut} 1s forwards;
		pointer-events: none;
		animation-delay: 0s;
	`}
	
	${p => p.$disabled && css`
		filter: grayscale(100%);
		color: ${p => p.theme.colors.neutral};
	`}
`;

// types

interface OptionsProps extends HTMLAttributes<HTMLDivElement> {
	data: MessageType<StandardMessage>
	onContinue: (index: number) => void
	timerEnded: boolean
}

export default Options;