import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { defaultScenarioId } from './atoms/scenario';
import Scenario from './Scenario';
import Reset from './Reset';

const Routing = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={'/:scenarioId'} element={<Scenario />} />
				{/* redirect all unspecified routes to the defaultScenarioId */}
				<Route path="*" element={<Navigate to={defaultScenarioId} replace />} />
				<Route path='/reset' element={<Reset />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Routing;