import i18n from '../tools/i18n';

const baseTheme = {
	colors:{
		neutralLightestT: 'rgba(255, 255, 255, 0.85)',
		neutralLightest: '#FFFFFF', // white
		neutralLighter: '#f4f4f4', // lighter grey
		neutralLight: '#d9d9d9', // light grey
		neutral: '#9e9e9e', // grey
		neutralDarker: 'hsl(0, 0%, 50%)',
		neutralDarkest: '#000000', // black
		neutralDarkestT: 'hsl(250deg 0% 0% / .85)',

		primaryLighter: '#ff0909',
		primary: '#D50101',
		primaryDarker: '#BC0707',
		primaryDarkest: 'hsl(0, 100%, 22%)',
		secondary: '#FF3228',
		secondaryDarker: '#c9b0a1',

		tertiary: 'hsl(203, 62%, 71%)',

		danger: '#DF2E20',	
		correct: '#55D25A',	
		correctLighter: '#95DE4C',
		alert: '#eda566',	
	},
	styles: {
		padding: 20,
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace: {
			xs: 10,
			sm: 15,
			lg: 25,
			xl: 25,
		},
		fontSize: {
			xs: '.8em',
			sm: '.8em',
			lg: '1.2em',
			xl: '1.4em',
		}
	},
	properties: {
		borderRadius: '20px',
		borderWidth: '0.25em'
	},
};

// get the reading direction from i18next and feed it to the theme.
const direction = i18n.dir();

export const theme = {
	...baseTheme,
	direction: direction,
};	
