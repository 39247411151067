import { useEffect, useMemo } from 'react';
import InteractiveSceneObject from '../InteractiveSceneObject';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { activeMessageAtom, talkingToAtom } from '../../../../atoms/messaging';
import { SceneObjectProps } from './SceneObject';
import { SceneObjectState } from '../sceneReducer';
import { Human } from '../../models/characters/Human/Human';
import { Character as CMSCharacterType, scenarioDataAtom } from '../../../../atoms/content';
import { Character as LocalCharacterType, SceneObjectType } from '../../../../atoms/scene';
import { AudioLibrary } from '../../../audio/Audio';
import Male04drunk from '../../models/characters/Human/Male_04_drunk_mesh';


const Character = (props: CharacterProps) => {

	const setTalkingTo = useSetAtom(talkingToAtom);
	const scenarioData = useAtomValue(scenarioDataAtom);
	const [activeMessage, setActiveMessage] = useAtom(activeMessageAtom);

	const characterData = useMemo<(CMSCharacterType & SceneObjectType<LocalCharacterType>) | undefined>(() => {
		if(props.object.type === 'drunkcharacter') return {...props.object, type: 'character', gender: 'male', mesh: 'male04'};
		if(!scenarioData || ! scenarioData.characters[props.id]) return;
		return {
			...scenarioData.characters[props.id],//cms data
			...props.object //local data
		};
	},[scenarioData, props]);

	const interactive = useMemo(() => {
		if(!props.interactive) return false;
		if(!props.state) return false;
		if(!characterData || !characterData.startMessages) return false;
		if(characterData.startMessages[props.state.messageState] === '') return false;
		return true;
	},[props.interactive, props.state, characterData]); 

	useEffect(() => {
		if(characterData && characterData.startMessages && activeMessage === characterData.startMessages[props.state.messageState]){
			setTalkingTo(props.id);
		}
	}, [activeMessage]);

	return ( 
		<group key={`character-${props.id}`}>
			{characterData && 
			<InteractiveSceneObject
				onClick={() => {
					if(characterData.startMessages && interactive){setActiveMessage(characterData.startMessages[props.state.messageState]);}
					if(characterData.start_sound) AudioLibrary[characterData.start_sound].load().play();
				}}
				interactable={interactive}
				position={characterData.position ?? [0,0,0]}
				data={characterData}
				objectId={props.id}
			>
				{props.object.type === 'drunkcharacter' ?
					<Male04drunk
						animation={props.state.animation ?? ''}
						rotation={props.object.rotation ? [props.object.rotation[0], props.object.rotation[1], props.object.rotation[2]] : [0,0,0]}  
						objectId={props.id}
						renderOrder={1}
						mood={props.state.mood ?? 'neutral'}
					/>
					:
					<Human
						gender={characterData.gender} 
						mesh={characterData.mesh} 
						animation={props.state.animation ?? ''}
						rotation={characterData.rotation ? [characterData.rotation[0], characterData.rotation[1], characterData.rotation[2]] : [0,0,0]}  
						idle={characterData.idle}
						sitting={characterData.sitting ?? false}
						objectId={props.id}
						renderOrder={1}
						mood={props.state.mood ?? 'neutral'}
						useProps={props.object.props ?? false}
					/>	
				}
			</InteractiveSceneObject>}
			

		</group>
	);
};

// types

type CharacterProps = SceneObjectProps & {
	object: SceneObjectType<LocalCharacterType>
	state: SceneObjectState
};
 
export default Character;