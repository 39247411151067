import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './smartphone_animated-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    ['3D_smartphone_01']: THREE.Mesh
  }
  materials: {
    mat_smartphone_01: THREE.MeshStandardMaterial
  }
};

type ActionName = 'phone_idle' | 'phone_rob';

export default function Phone(props: JSX.IntrinsicElements['group'] & {animation: string}) {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF(model) as GLTFResult;
	const { actions, mixer } = useAnimations(animations, group) as unknown as {actions: {[key in ActionName]: THREE.AnimationAction}, mixer: THREE.AnimationMixer};
	const [visible, setVisible] = useState(false);

	const options: {[key: string] : ActionName} = {
		'jet_robber_03': 'phone_rob',
		// 'robber_threatening_02':'robberknife_threatening_01',
		// 'robber_threatening_03':'robberknife_threatening_01',
	};

	
	const idles: {[key: string] : ActionName} = {
		'phone_rob': 'phone_idle',
	};
	
	useEffect(() => {
		if(options[props.animation]){			
			Object.values(actions).map((a) => a.stop().reset());
			setVisible(true);
			actions[options[props.animation]].reset().play().setLoop(THREE.LoopOnce, 1).clampWhenFinished = true;
		} 

		mixer.addEventListener('finished', (e) => {
			if(actions[idles[e.action.getClip().name]]){
				actions[idles[e.action.getClip().name]].play();
			}
			return;
		});

	}, [props.animation]);
  
	return (
		<group visible={visible} ref={group} {...props} dispose={null}>
			<group name="Scene">
				<group name="phone_armature">
					<group name="Bone" position={[0, -0.01, -0.06]} rotation={[Math.PI / 2, 0, 0]}>
						<mesh name="3D_smartphone_01" geometry={nodes['3D_smartphone_01'].geometry} material={materials.mat_smartphone_01} position={[0, 0.06, 0]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} />
					</group>
				</group>
			</group>
		</group>
	);
}

useGLTF.preload(model);
