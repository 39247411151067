import { useAtomValue, useSetAtom } from 'jotai';
import { activeScenarioAtom } from './atoms/scenario';
import { Navigate } from 'react-router-dom';
import { gameResultAtom } from './atoms/gameResults';
import { useEffect } from 'react';
import { seenMessagesAtom } from './atoms/messaging';

const Reset = () => {

	const activeScenarioId = useAtomValue(activeScenarioAtom);
	const setGameResults = useSetAtom(gameResultAtom);
	const setSeenMessages = useSetAtom(seenMessagesAtom);

	useEffect(() => {
		setGameResults({ type: 'clear' });
		setSeenMessages([]);
	}, []);

	return (
		<>
			<Navigate to={`/${activeScenarioId}`} />
		</>
	);
};

export default Reset;