import * as THREE from 'three';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF, SkeletonUtils } from 'three-stdlib';
import { objectsStatesAtom } from '../../../../../atoms/scene';
import { activeScenarioAtom } from '../../../../../atoms/scenario';
import { activeMessageAtom } from '../../../../../atoms/messaging';

import { useGraph } from '@react-three/fiber';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

// model imports
import maleRig from '../glb/male_rig.glb';
import male01Model from '../glb/male_01_mesh.glb';
import male02Model from '../glb/male_02_mesh.glb';
import male03Model from '../glb/male_03_mesh.glb';
import male04Model from '../glb/male_04_mesh.glb';

import femaleRig from '../glb/female_rig.glb';
import female01Model from '../glb/female_01_mesh.glb';
import female02Model from '../glb/female_02_mesh.glb';
import female03Model from '../glb/female_03_mesh.glb';
import female04Model from '../glb/female_04_mesh.glb';
import female05Model from '../glb/female_05_mesh.glb';

import BagMale from '../props/Bag_male';
import Knife from '../props/Knife_animated';
import Phone from '../props/Smartphone_animated';
import MorphTargets from './MorphTargets';
import { scenarioDataAtom } from '../../../../../atoms/content';
import SittingAnimationHandler from './SittingAnimationHandler';
import StandingAnimationHandler from './StandingAnimationHandler';


type GLTFResultMesh = GLTF & {
	nodes: {
		character: THREE.SkinnedMesh;
		Hips: THREE.Bone;
		kneeIKL: THREE.Bone;
		heelIKL: THREE.Bone;
		elbowIKL: THREE.Bone;
		handIKL: THREE.Bone;
		kneeIKR: THREE.Bone;
		heelIKR: THREE.Bone;
		elbowIKR: THREE.Bone;
		handIKR: THREE.Bone;
	};
	materials: {
		character_material: THREE.MeshPhysicalMaterial;
	};
};

export type ModelProps = {
	animation: string
	mood: Expression
	gender: 'male' | 'female'
	mesh: Meshes
	objectId: string
	idle?: 'idle_body_01' | 'idle_body_02' | 'idle_sitting_lap' | 'idle_sitting_desk' | 'idle_sitting_rest'
	sitting: boolean,
	useProps: boolean
};

export type Expression = 'neutral' | 'happy' | 'mad' | 'sad' | 'insecure'; //add one here if theres a new one

export const models = {
	'male01': male01Model,
	'male02': male02Model,
	'male03': male03Model,
	'male04': male04Model,
	'female01': female01Model,
	'female02': female02Model,
	'female03': female03Model,
	'female04': female04Model,
	'female05': female05Model,
};
export type Meshes = keyof typeof models;
export type Actions = { [x: string]: THREE.AnimationAction };

export function Human(props: JSX.IntrinsicElements['group'] & ModelProps) {

	const group = useRef<THREE.Group>(null);
	const meshModel = useGLTF(models[props.mesh]) as GLTFResultMesh;
	const rigModel = useGLTF(props.gender === 'male' ? maleRig : femaleRig) as GLTF;

	// Skinned meshes cannot be re-used in threejs without cloning them
	const meshClone = useMemo(() => SkeletonUtils.clone(meshModel.scene), [meshModel.scene]);
	const mesh = useGraph(meshClone) as GLTFResultMesh;
	const meshRef = useRef<THREE.SkinnedMesh>(null);

	const { actions, mixer } = useAnimations(rigModel.animations, group) as unknown as { actions: Actions, mixer: THREE.AnimationMixer };

	const [nowPlaying, setNowPlaying] = useState<string>(props.idle ?? 'idle_conversation');
	const [previousData, setPreviousData] = useState<{ previous: string, idle: string }>({ previous: '', idle: '' });
	const [scenarioData] = useAtom(scenarioDataAtom);
	const objectsStatesDispatch = useSetAtom(objectsStatesAtom);
	const activeScenarioId = useAtomValue(activeScenarioAtom);
	const activeMessageId = useAtomValue(activeMessageAtom);

	const NPCSpeaking = useMemo(() => {
		if (!scenarioData || !activeScenarioId || !activeMessageId || !scenarioData.messages) return;
		const message = scenarioData.messages[activeMessageId];
		if (message && message.message_type != 0) return;
		return message.speaking_character != -1;
	}, [activeMessageId]);

	//#region playing aniamtions

	const playAnimation = (animName: string, idleName: string) => {
		if(animName === 'action_body_just_talking'){ actions['action_head_talking_02'].reset().play().setLoop(THREE.LoopOnce, 1); return;}
		NPCSpeaking && actions['action_head_talking_02'].reset().play().setLoop(THREE.LoopOnce, 1);
		const previous = actions[nowPlaying];
		const anim = actions[animName];

		//animation
		if (anim && previous) {
			anim.reset().play().setLoop(THREE.LoopOnce, 1).clampWhenFinished = true;
			previous.crossFadeTo(anim, 0.8, false);
			setNowPlaying(animName);
		}

		mixer.addEventListener('finished', (e) => {
			setPreviousData({ previous: e.action.getClip().name, idle: idleName });
		});
	};

	useEffect(() => {
		if (previousData.previous === '' || previousData.idle === '') return;
		if (previousData.previous === nowPlaying) {

			const previous = actions[previousData.previous];
			const idle = actions[previousData.idle];

			if (idle && previous) {
				idle.reset().play();
				previous.crossFadeTo(idle, 0.5, false);
				setNowPlaying(previousData.idle);
			}				
			
			props.objectId != undefined && objectsStatesDispatch({//reset props.animation
				type: 'set-character-animation',
				object_id: props.objectId,
				animation: ''
			});
		}
	}, [previousData]);


	//#endregion

	//render

	return (
		<group {...props}>
			<group ref={group} dispose={null}>
				<skinnedMesh
					ref={meshRef}
					name="character"
					geometry={mesh.nodes.character.geometry}
					material={mesh.materials.character_material}
					skeleton={mesh.nodes.character.skeleton}
					morphTargetDictionary={mesh.nodes.character.morphTargetDictionary}
					morphTargetInfluences={mesh.nodes.character.morphTargetInfluences}
				/>
				<group name="cube_skeleton">
					<primitive object={mesh.nodes.Hips} />
					<primitive object={mesh.nodes.kneeIKL} />
					<primitive object={mesh.nodes.heelIKL} />
					<primitive object={mesh.nodes.elbowIKL} />
					<primitive object={mesh.nodes.handIKL} />
					<primitive object={mesh.nodes.kneeIKR} />
					<primitive object={mesh.nodes.heelIKR} />
					<primitive object={mesh.nodes.elbowIKR} />
					<primitive object={mesh.nodes.handIKR} />
				</group>
				
				{props.sitting ?
					<SittingAnimationHandler actions={actions} playAnimation={playAnimation} props={props} />
					:
					<StandingAnimationHandler actions={actions} playAnimation={playAnimation} props={props} />
				}
				<MorphTargets mood={props.mood ?? ''} meshRef={meshRef} />
			</group>
			{/* props */}
			{props.useProps && <>
				<BagMale animation={props.animation} />
				<Knife animation={props.animation} />
				<Phone animation={props.animation} />
			</>}
		</group>
	);
}

// useGLTF.preload(model);
