import { OfficeScreenWithMail } from './3D_office_screen_email';
import { Posters } from './3D_poster_pile';
import Notepad from './3D_notepad';
import { Suitcase } from './Suitcase';
import JetBike from './3D_justeat_bike';
import Bike from './3D_normal_bike';
import Crate from './Beercrate';
import Bottle from './Beerbottle';
import KitchenMug from './Kitchen_mug';


export const itemList = {
	'notepad' : Notepad,
	'kopje' : KitchenMug,
	'posters': Posters,
	'monitor': OfficeScreenWithMail,
	'suitcase': Suitcase,
	'bike' : Bike,
	'jetBike' : JetBike,
	'crate' : Crate,
	'bottle' : Bottle,
};
