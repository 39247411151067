import { Canvas } from '@react-three/fiber';
import { ReactNode, Suspense } from 'react';
import styled from 'styled-components';
import { controlsEnabledAtom} from '../../atoms/three';
import { useAtomValue } from 'jotai';
import { NoToneMapping } from 'three';
import ThreeLoading from './TheeLoading';

const ThreeCanvas = (props: { children: ReactNode }) => {

	const controlsEnabled = useAtomValue(controlsEnabledAtom);

	return (
		<CanvasContainer>
			<Canvas
				dpr={1}
				frameloop={'always'}
				id={'three-canvas'}
				gl={{ powerPreference: 'high-performance', toneMapping: NoToneMapping }}
				style={{ pointerEvents: controlsEnabled ? 'all' : 'none' }}
			>
				<Suspense fallback={ <ThreeLoading />}>
					{props.children}
				</Suspense>
			</Canvas>
		</CanvasContainer>
	);

};



// styled components


const CanvasContainer = styled.div`
	position: absolute;
	inset:0;
`;

export default ThreeCanvas;