import { useEffect, useMemo } from 'react';
import { Actions, ModelProps } from './Human';
export type ActionName =
  | 'action_sitting_talking_01'
  | 'action_sitting_talking_02'
  | 'action_sitting_talking_03'
  | 'action_sitting_talking_happy'
  | 'action_sitting_talking_insecure'
  | 'action_sitting_talking_mad'
  | 'action_sitting_talking_sad';


export type IdleName =
  | 'idle_sitting_desk'
  | 'idle_sitting_happy'
  | 'idle_sitting_insecure'
  | 'idle_sitting_lap'
  | 'idle_sitting_mad'
  | 'idle_sitting_rest'
  | 'idle_sitting_sad';

const SittingAnimationHandler = ({playAnimation, actions, props} :{playAnimation: (anim: string, idle: string) => void, actions: Actions, props: ModelProps}) => {

	const defaultIdle = useMemo<string>(() => {
		return props.idle ?? 'idle_sitting_lap';
	},[props.idle]);

	useEffect(() => {
		Object.values(actions).map((a) => a.stop().reset());
		actions[defaultIdle].play();
	}, [defaultIdle]);	

	useEffect(() => {	
		if(props.animation === '') return;
		
		//plays animations with idles
		const idles: {[key in ActionName]? : IdleName} = {
			action_sitting_talking_happy: 'idle_sitting_happy',
			action_sitting_talking_insecure: 'idle_sitting_insecure',
			action_sitting_talking_mad: 'idle_sitting_mad',
			action_sitting_talking_sad: 'idle_sitting_sad'
		};

		const animation = ('action_sitting_'+props.animation) as ActionName;

		const emotionIdle = idles[animation] ? idles[animation] : undefined; 
		playAnimation(animation, emotionIdle ?? defaultIdle);

	}, [props.animation]);

	return ( 
		<></>
	);
};
 
export default SittingAnimationHandler;