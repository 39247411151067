import { Euler, Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_56: {[key: string]: SceneData} = {
	'bos2_michelle' : {		
		camera_settings: {
			position: new Vector3(2.79636359, 1.60549361, -3.314934),
			target: new Vector3(1, 1.4, -3.7),
		},
		objects: {
			'12':{	
				type : 'character', 
				position: new Vector3(1.5, 0, -3.6),
				rotation: [0,(Math.PI/2)-0.2,0],
			},
			'22':{	
				type : 'character', 
				position: new Vector3(-2.5, 0, 2),
			}
		},			
		background_characters: [
			{
				position: new Vector3(0, 0, -3.5),
				rotation: new Euler(0,Math.PI/2,0),
				anim: 'idle_01',
				model: 'morgan'
			},
			{
				position: new Vector3(-1, 0, -4.7),
				rotation: new Euler(0,Math.PI/2-0.3,0),
				anim: 'idle_01',
				model: 'chris'
			}
		]
	},
	'bos2_angela' : {		
		camera_settings: {
			position: new Vector3(-2.50153, 1.5234851, 3.16549707),
			target: new Vector3(-2.5, 1.5, 3),
		},
		objects: {
			'22':{	
				type : 'character', 
				position: new Vector3(-2.5, 0, 2),
			}
		},			
		background_characters: [
			{
				position: new Vector3(0, 0, -3.5),
				rotation: new Euler(0,Math.PI/2,0),
				anim: 'taking_02',
				model: 'morgan'
			},
			{
				position: new Vector3(1, 0, -3.5),
				rotation: new Euler(0,-Math.PI/2,0),
				anim: 'idle_01',
				model: 'chris'
			}
		]
	},
};

export default scenes_scenario_56;