import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_14: {[key: string]: SceneData} = {
	'st1_shop' : {		
		camera_settings: {
			position: new Vector3(0.2763734636, 1.5502317629, -12.716591497),
			target: new Vector3(0,1,-10),
			// minAzimuthAngle: 0,
			// maxAzimuthAngle: 3.2,
		},
		objects: {
			'5':{
				type : 'character',
				position: new Vector3(-2.3,0,-8),
				rotation: [0,Math.PI+0.3,0],					
				startMessages: ['630'],
			},
			'12': {
				type : 'character',
				startMessages: ['624', ''], 
				position: new Vector3(0,0,-5),
				rotation: [0,Math.PI,0],
			},
			'suitcase' : {
				position: new Vector3(1.5,0.2,-10.9),
				rotation: [0,0,0],
				type : 'item', 
				item_id: '7',
				mesh: 'suitcase',
				indicatorScale: 0.3,
				indicatorOffset: {
					x: 0,
					y: 1.1,
					z: 0,
				},
			},
		},
	}
};


export default scenes_scenario_14;