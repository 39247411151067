import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './glb/3D_office_screen_email-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    Cube013: THREE.Mesh
    Cube013_1: THREE.Mesh
  }
  materials: {
    image_atlas: THREE.MeshStandardMaterial
    email_screen: THREE.MeshStandardMaterial
  }
};

export const OfficeScreenWithMail = (props: JSX.IntrinsicElements['group']) => {
	const { nodes, materials } = useGLTF(model) as GLTFResult;
	return (
		<group {...props} dispose={null}>
			<group>
				<group position={[0.011, -0.001, 0.017]}>
					<mesh geometry={nodes.Cube013.geometry} material={materials.image_atlas} />
					<mesh geometry={nodes.Cube013_1.geometry} material={materials.email_screen} />
				</group>
			</group>
		</group>
	);
};

useGLTF.preload(model);
