
import * as THREE from 'three';
import { useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './glb/3D_trolley_base-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    Cube120: THREE.Mesh
    Cube120_1: THREE.Mesh
  }
  materials: {
    ['tumi-trolley_base']: THREE.MeshStandardMaterial
    ['tumi-trolley']: THREE.MeshStandardMaterial
  }
};

export function Suitcase(props: JSX.IntrinsicElements['group']) {
	const { nodes, materials } = useGLTF(model) as GLTFResult;

	useEffect(() => {
		materials['tumi-trolley_base'].color = new THREE.Color('#0e2a5e');
	}, []);

	return (
		<group {...props} dispose={null}>
			<group name="3D_store_trolley_base" position={[0, 0.4, 0]}>
				<mesh name="Cube120" geometry={nodes.Cube120.geometry} material={materials['tumi-trolley_base']} >
				</mesh>
				<mesh name="Cube120_1" geometry={nodes.Cube120_1.geometry} material={materials['tumi-trolley']} />
			</group>
		</group>
	);
}

useGLTF.preload(model);