
import { useAtom } from 'jotai';
import styled from 'styled-components';
import { HTMLAttributes, useMemo, useState } from 'react';
import IconButton from '../../buttons/IconButton';
import ChoicesTimeline from './ChoicesTimeline';
import { useTranslation } from 'react-i18next';
import PopoverModal from '../../containers/PopoverModal';
import Completion from './Completion';
import Summary from './Summary';
import { gameResultAtom } from '../../../atoms/gameResults';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import i18n from '../../../tools/i18n';
import { Ending, scenarioDataAtom } from '../../../atoms/content';

const Feedback = () => {

	const [gameResult] = useAtom(gameResultAtom);
	const [scenarioData] = useAtom(scenarioDataAtom);

	const [step, setStep] = useState<number>(0);

	const { t } = useTranslation();

	// to keep track wether step1 has done the animation sequence already.
	const [step1Initialized, setStep1Initializzed] = useState<boolean>(false);
	// to keep track wether step3 has done the animation sequence already.
	const [step3Initialized, setStep3Initializzed] = useState<boolean>(false);

	const endingData = useMemo<Ending | undefined>(() => {
		if (!gameResult.ending_id || !scenarioData) return;
		else return scenarioData.endings[gameResult.ending_id];
	}, [gameResult.ending_id]);

	const changeStep = (increment: boolean) => {
		if (increment) {
			if (step === 2) return;
			setStep(s => s + 1);
		}
		else {
			if (step === 0) return;
			setStep(s => s - 1);
		}

		const scrollDiv = document.getElementById('feedback-scrolldiv');
		if (scrollDiv) {
			scrollDiv.scrollTo(0, 0);
		}
	};


	return (<>
		{endingData && <PopoverModal title={t('game.feedback.title')} $scrollable $scrollabledivid={'feedback-scrolldiv'}>
			<Subcontainer>
				<SubheaderBar>
					<h1>
						{t(`game.feedback.steps.${step}.title`)}
					</h1>
				</SubheaderBar>
				{/* Step 1 | Show completion */}
				{step === 0 && <Completion endingData={endingData} onInitialized={() => setStep1Initializzed(true)} initialized={step1Initialized} style={{ height: '100%' }} />}
				{/* Step 2 | Show choices */}
				{step === 1 && <ChoicesTimeline />}
				{/* Step 3 | Summarize */}
				{step === 2 && <Summary endingData={endingData} onInitialized={() => setStep3Initializzed(true)} initialized={step3Initialized} />}
				<NavigationContainer>
					<IconButton $primary disabled={step === 0} onClick={() => { changeStep(false); }}>
						{i18n.dir() === 'ltr' ?
							<IoIosArrowRoundBack /> :
							<IoIosArrowRoundForward />
						}
					</IconButton>
					<IconButton $primary disabled={step === 2 || !step1Initialized} onClick={() => { changeStep(true); }}>
						{i18n.dir() === 'ltr' ?
							<IoIosArrowRoundForward /> :
							<IoIosArrowRoundBack />
						}
					</IconButton>
				</NavigationContainer>
			</Subcontainer>
		</PopoverModal>}
	</>
	);
};

const SubheaderBar = styled.div`
	display:flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	& > svg {
		color: ${p => p.theme.colors.primary};
	}
`;

const NavigationContainer = styled.div`

	position: sticky;
	display: flex;
	justify-content: space-between;
	margin-top: auto;
	padding-top: 20px;
	bottom:0;
`;

const Subcontainer = styled.div`
	position: relative;
	height: 100%;
	gap: 20px;
	display: flex;
	flex-direction: column;
`;

//types

export interface CompletionProps extends HTMLAttributes<HTMLDivElement> {
	onInitialized: () => void;
	initialized: boolean;
	endingData: Ending
}

export default Feedback;