import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_53: {[key: string]: SceneData} = {
	'bos3_dana' : {		
		camera_settings: {
			position: new Vector3(1.94255319, 1.362335771, 0.353509014),
			target: new Vector3(-2.5, 1.4, 4),
		},
		objects: {
			'6':{	
				type : 'character', 
				position: new Vector3(-2.5, 0, 5),
				rotation: [0,Math.PI-1,0],
			},
			
		},
	},
	'bos3_dana_jeans' : {		
		camera_settings: {
			position: new Vector3(-0.3122044909, 1.54326124697, 0.60892729),
			target: new Vector3(-1.5, 1.4, 0),
		},
		objects: {
			'6':{	
				type : 'character', 
				position: new Vector3(-1.5, 0, 0),
				rotation: [0,Math.PI/2-0.5,0],
			},
			
		},
		
	}
};

export default scenes_scenario_53;