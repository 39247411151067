import { SceneObjectsType } from '../../../atoms/scene';
import { Expression } from '../models/characters/Human/Human';

export const reducer = (state : SceneObjectStates, action: SceneObjectAction ) : SceneObjectStates => {
	
	switch(action.type){

	case 'add-object' :
		return {...initializeSceneObjects(state, action)};
	case 'set-object-state' :
		return {...changeSceneObjectState(state, action)};
	case 'set-object-visibility' :
		return {...changeSceneObjectVisibility(state, action)};
	case 'set-character-animation' :
		return {...changeCharacterAnimation(state, action)};
	case 'set-character-mood' :
		return {...changeCharacterMood(state, action)};
	case 'reset-objects' :
		return {...resetAppearences(state)};
	}
};

const initializeSceneObjects = (state: SceneObjectStates, action : InitializeAction) => {
	
	//add given data to list of objects in objectsState
	const shared = {messageState: 0, visible: action.payload.data.visible ?? true};
	if(action.payload.data.type === 'character')
		state[action.payload.key] = { mood: action.payload.data.default_mood ?? 'neutral', animation: '', ...shared};
	else
		state[action.payload.key] = { ...shared};

	return state;
};

const changeSceneObjectState = (state: SceneObjectStates, action : ObjectStateAction) => {
	//change state to given value

	state[action.object_id].messageState = action.state;

	return state;
};

const changeSceneObjectVisibility = (state: SceneObjectStates, action : ObjectVisibilityAction) => {
	//change state to given value
	state[action.object_id].visible = action.visible;
	
	return state;
};

const changeCharacterAnimation = (state: SceneObjectStates, action : AnimationAction) => {
	if(!state[action.object_id]){console.error(action.object_id+' does not exist in '+Object.keys(state)); return state;}
	//change emotion to given value
	state[action.object_id].animation = action.animation;

	return state;
};

const changeCharacterMood = (state: SceneObjectStates, action : MoodStateAction) => {
	if(!state[action.object_id]){console.error(action.object_id+' does not exist in '+Object.keys(state)); return state;}
	//change emotion to given value
	state[action.object_id].mood = action.mood;

	return state;
};

const resetAppearences = (state: SceneObjectStates) => {
	state = {};

	return state;
};

// types
type SceneObjectStates = {[key: string]: SceneObjectState};

export type SceneObjectState =  {
	messageState: number
    animation?: string
	mood?: Expression
	visible: boolean
};

export type SceneObjectAction = InitializeAction | ResetSceneObjectsAction | ObjectStateAction | AnimationAction | MoodStateAction |  ObjectVisibilityAction;

type InitializeAction = {
    type: 'add-object'
    payload: {key: string, data: SceneObjectsType}
};

type ResetSceneObjectsAction = {
    type: 'reset-objects'
};

export type ObjectStateAction = {
	type: 'set-object-state'
	object_id: string, state: number
};

export type ObjectVisibilityAction = {
	type: 'set-object-visibility'
	object_id: string, visible: boolean
};

export type AnimationAction = {
	type: 'set-character-animation'
	object_id: string, animation: string
};

export type MoodStateAction = {
	type: 'set-character-mood'
	object_id: string, mood: Expression
};