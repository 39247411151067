import { atomWithReducer } from 'jotai/utils';
import { Answer } from './content';

const emptyGameResult: gameResultData = {
	ending_id: undefined,
	achieved: false,
	choices: [],
	correct: 0,
	neutral: 0,
	incorrect: 0,
};


export const gameResultReducer = (state: gameResultData, action: gameResultAction) => {
	let newState = { ...state };

	const uid = localStorage.getItem('userId');

	switch (action.type) {
	case 'store-attempt':
		if (uid) {
			// const parsedId = JSON.parse(uid);
			// const ts = Timestamp.now();
			// const attempt: attemptData = {topic: action.topic, scenario: action.scenario, version: packageJson.version, completed_at: ts, ...state};
			//set new entry	
			// updateDoc(doc(db, 'whitelisted_domains', customer, 'user_results', parsedId), {
			// 	game_results: arrayUnion(attempt),
			// 	latest_attempt: ts
			// });
		}
		break;
	case 'store-choice':
		newState.choices.push(action.entry);
		//set incorrect correct and neutral amount
		if (action.entry.answers[action.entry.selected].correctness === 0) newState.incorrect = state.incorrect + 1;
		if (action.entry.answers[action.entry.selected].correctness === 1) newState.neutral = state.neutral + 1;
		if (action.entry.answers[action.entry.selected].correctness === 2) newState.correct = state.correct + 1;
		break;
	case 'store-reset':
		newState.choices[newState.choices.length - 1].reset = true;
		break;
	case 'set-ending':
		newState.ending_id = action.ending_id;
		break;
	case 'clear':
		newState = emptyGameResult;
		newState.choices = [];
		break;
	}

	//updates the data in firebase database
	return newState;
};

export const gameResultAtom = atomWithReducer<gameResultData, gameResultAction>(emptyGameResult, gameResultReducer);

export type attemptData = gameResultData & { topic: string, scenario: string, version: string };

export type gameResultData = {
	ending_id: string | undefined,
	achieved?: boolean,
	choices: ChoiceData[]
	correct: number,
	neutral: number,
	incorrect: number,
};

export type ChoiceData = {
	selected: number,
	answers: Answer[],
	messageId: string
	reset?: boolean
};

type gameResultAction = gameResultStoreAction | gameResultClearAction | GameResultEndingAction | attemptStoreAction | gameResultResetAction;

type gameResultStoreAction = {
	type: 'store-choice'
	entry: ChoiceData
};
type gameResultResetAction = {
	type: 'store-reset'
};

type attemptStoreAction = {
	type: 'store-attempt'
	scenario: string
};

type GameResultEndingAction = {
	type: 'set-ending'
	ending_id: string,
};

type gameResultClearAction = {
	type: 'clear',
};
