import { CameraControls, CameraControlsProps } from '@react-three/drei';
import { Suspense, useEffect, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { useAtom, useAtomValue } from 'jotai';
import { controlsAtom, debugCameraAtom } from '../../../atoms/three';
import { activeScenarioAtom } from '../../../atoms/scenario';

const ThreeControls = () => {

	const [controls, setControls] = useAtom(controlsAtom);
	const debugControls = useAtomValue(debugCameraAtom);

	const activeScenario = useAtomValue(activeScenarioAtom);

	const cameraControls = useRef<CameraControlsReference>(null);
	const [initialized, setInitialized] = useState<boolean>(false);

	// sets the camera to a starting position
	const setCameraToStartingPos = () => {
		if (initialized) return;
		if (!cameraControls.current) return;
		const cameraPos: Vector3 = cameraStartingPosition;
		const targetPos: Vector3 = targetStartingPosition;
		cameraControls.current.setPosition(cameraPos.x, cameraPos.y, cameraPos.z, false);
		cameraControls.current.setTarget(targetPos.x, targetPos.y, targetPos.z, false);
		// cameraControls.current.rotateTo(5.6, 1.5, false);
		if (!initialized) setInitialized(true);
	};

	// if the active scenario changes and the global controls reference has been set, set the camera to starting position
	useEffect(() => {
		if (activeScenario && controls) setCameraToStartingPos();
	}, [activeScenario, cameraControls.current]);

	// when controls ref is set, set camera to starting position
	useEffect(() => {
		setControls(cameraControls.current);
		if (cameraControls.current && !controls) {
			setCameraToStartingPos();
		}
	}, [cameraControls]);

	useEffect(() => {
		return () => setInitialized(false);
	}, []);

	// sets config values when debugControls boolean changes
	useEffect(() => {

		if (!controls) return;
		if (debugControls) {
			controls.truckSpeed = debugConfig.truckSpeed || 1;
			controls.dollySpeed = debugConfig.dollySpeed || 1;
			controls.polarRotateSpeed = debugConfig.polarRotateSpeed || 0;
			controls.minAzimuthAngle = -Infinity;
			controls.maxAzimuthAngle = Infinity;
			controls.minDistance = 1;
			controls.maxDistance = 5;
			controls.infinityDolly = true;

		}
		else if (initialized) {
			controls.truckSpeed = config.truckSpeed || 0;
			controls.dollySpeed = config.dollySpeed || 0;
			controls.polarRotateSpeed = config.polarRotateSpeed || 0;
			controls.minAzimuthAngle = config.minAzimuthAngle ?? -Infinity;
			controls.maxAzimuthAngle = config.maxAzimuthAngle ?? Infinity;
			controls.infinityDolly = false;
			controls.minDistance = Number.EPSILON;
			controls.maxDistance = Infinity;
			setCameraToStartingPos();
		}
	}, [debugControls]);

	return (
		<Suspense>
			<CameraControls
				ref={cameraControls}
				makeDefault
				// enabled={false}
				{...config}
			/>
		</Suspense>
	);
};

// data

export const cameraStartingPosition: Vector3 = new Vector3(0, 1.8, -2);
export const targetStartingPosition: Vector3 = new Vector3(0, 1.4, 0);

const config: CameraControlsProps = {
	truckSpeed: 0,
	dollySpeed: 0,
	azimuthRotateSpeed: 1,
	restThreshold: 0.001,

	// min maxes
	polarRotateSpeed: 0,
	// minAzimuthAngle: 5.0,
	// maxAzimuthAngle: 8.11,
};

const debugConfig: CameraControlsProps = {
	truckSpeed: 10,
	dollySpeed: 1,
	polarRotateSpeed: 1,
	azimuthRotateSpeed: 1
};



// types

// type for scenes to apply values to the camera when loaded
export type CameraSettings = {
	position: Vector3,
	target: Vector3,
	minAzimuthAngle?: number,
	maxAzimuthAngle?: number,
	minPolarAngle?: number,
	maxPolarAngle?: number,
};
export type CameraControlsReference = CameraControls | null;

export default ThreeControls;