import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_42: {[key: string]: SceneData} = {
	'ce1_marc' : {		
		camera_settings: {
			position: new Vector3(3.056160796918933, 1.5005433322386412, -2.792722723869409),
			target: new Vector3(0, 1, -7),
		},
		objects: {
			'5':{	
				type : 'character', 
				position: new Vector3(0, 0, -7),
			},
			'10':{	
				type : 'character', 
			},
			'4':{	
				type : 'character', 
			},
		},
	},
	'ce1_lisa' : {		
		camera_settings: {
			position: new Vector3(2.6862872858943074, 1.6771195220701542, 3.4480016830000952),
			target: new Vector3(0, 1, -7),
		},
		objects: {
			'10':{	
				type : 'character', 
				position: new Vector3(1, 0, -2),
			},
			'5':{	
				type : 'character', 
				position: new Vector3(-1.5, 0, 0.5),
				rotation: [0,-0.5,0],
				idle: 'idle_body_01',
			},
		},
	},
	'ce1_lisacarlos' : {		
		camera_settings: {
			position: new Vector3(1.1, 1.6006794430343012, -1.1),
			target: new Vector3(1, 1.5, -2),
		},
		objects: {
			'4':{	
				type : 'character', 
				position: new Vector3(-0.5, 0, -7),
			},
			'10':{	
				type : 'character', 
				position: new Vector3(1, 0, -2),
			},
		},
	},
	'ce1_carlos' : {		
		camera_settings: {
			position: new Vector3(2.1908396, 1.5753218, 5.18772795),
			target: new Vector3(2, 1.5, 7),
		},
		objects: {
			'4':{	
				type : 'character', 
				position: new Vector3(2, 0, 7),
				rotation: [0,Math.PI-0.5,0],
			},
		},
	},
	'ce1_carlos_jacket' : {		
		camera_settings: {
			position: new Vector3(-2.5106248436851275, 1.5879035354959135, 3.141786010531283),
			target: new Vector3(-2.5, 1.4, 4),
		},
		objects: {
			'4':{	
				type : 'character', 
				position: new Vector3(-2.5, 0, 4),
				rotation: [0,Math.PI,0],
			},
		},
	},
};

export default scenes_scenario_42;