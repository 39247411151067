import { useEffect, useMemo } from 'react';
import SceneObjects from './SceneObjects';
import { useAtom, useAtomValue } from 'jotai';
import { activeSceneAtom, objectsStatesAtom } from '../../../atoms/scene';
import { activeScenarioAtom } from '../../../atoms/scenario';
import { sceneList } from '../../../content/scenes/scene_list';
import BackgroundCharacters from '../models/characters/BackgroundCharacters/BackgroundCharacters';
import Environment from '../models/environments/Environment';

const SceneManager = () => {

	const [activeSceneId, setActiveSceneId] = useAtom(activeSceneAtom);
	const activeScenarioId = useAtomValue(activeScenarioAtom);

	const [, objectsDispatch] = useAtom(objectsStatesAtom);

	useEffect(() => {
		if(!activeScenarioId || !sceneList[activeScenarioId])return;
		setActiveSceneId(Object.keys(sceneList[activeScenarioId])[0]);
	}, [activeScenarioId]);

	const sceneData = useMemo(() => {
		if (!activeSceneId || !activeScenarioId || !sceneList[activeScenarioId][activeSceneId]) return;
		objectsDispatch({ type: 'reset-objects' });
		Object.entries(sceneList[activeScenarioId][activeSceneId].objects).map(([key, sceneObject]) => {
			objectsDispatch({ type: 'add-object', payload: { key: key, data: sceneObject } });
		});
		return sceneList[activeScenarioId][activeSceneId];
	}, [activeSceneId]);

	return (
		<>
			{sceneData && <Environment sceneData={sceneData}/>}
			{/* Background characters of the activeScene */}
			{sceneData && sceneData.background_characters && <BackgroundCharacters characters={sceneData.background_characters}/>}
			{/* objects in scene */}
			{sceneData && Object.keys(sceneData.objects).length != 0 && <SceneObjects objects={sceneData.objects} />}
		</>
	);
};

export default SceneManager;