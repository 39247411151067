import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './glb/beerbottle-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    beerbottle: THREE.Mesh
  }
  materials: {
    mat_beerbottle2: THREE.MeshStandardMaterial
  }
  // animations: GLTFAction[]
};

// type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>;

export default function Bottle(props: JSX.IntrinsicElements['group']) {
	const { nodes, materials } = useGLTF(model) as GLTFResult;
	return (
		<group {...props} dispose={null}>
			<mesh name="beerbottle" position={[0.1,0,0]} geometry={nodes.beerbottle.geometry} material={materials.mat_beerbottle2} />
			<mesh name="beerbottle" position={[0,0,0.1]} geometry={nodes.beerbottle.geometry} material={materials.mat_beerbottle2} />
			<mesh name="beerbottle" position={[0,0,0]} geometry={nodes.beerbottle.geometry} material={materials.mat_beerbottle2} />
		</group>
	);
}

useGLTF.preload(model);
