import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_28: {[key: string]: SceneData} = {
	'sj5_s1' : {		
		camera_settings: {
			position: new Vector3(-4.9660289, 1.406621323, -1.27),
			target: new Vector3(-4.9,1,8),
		},
		objects: {
			'3':{	
				type : 'character', 
				props: true,
				default_mood: 'mad',
				position: new Vector3(-5,-0.1,0),
				rotation: [0,Math.PI,0],
				startMessages: ['1060',''],
			},
			'jetBike':{
				type:'item',
				mesh: 'jetBike',
				position: new Vector3(-4.5,-0.1,-0.8),
				rotation: [0,-2,0],
			},
			'bike':{
				type:'item',
				mesh: 'bike',
				position: new Vector3(-5.3,0,0.1),
				rotation: [0,0.5,1.4],
			}
		}
	}
	
};


export default scenes_scenario_28;