import { RefObject, useEffect } from 'react';
import { BufferGeometry, Material, SkinnedMesh } from 'three';
import randomInterval from 'react-random-interval-hook'; 
import { Expression } from './Human';

const MorphTargets = ({mood, meshRef} :{mood: Expression, meshRef: RefObject<SkinnedMesh<BufferGeometry, Material | Material[]>>}) => {

	//#region setting facial moods

	const resteMood = () => {
		if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;
		//reset
		meshRef.current.morphTargetInfluences[0] = 0; // = mouth open
		meshRef.current.morphTargetInfluences[1] = 0; // = mouth smile
		meshRef.current.morphTargetInfluences[2] = 0; // = mouth down (mad)
		meshRef.current.morphTargetInfluences[3] = 0; // = eye browes down (mad)
		meshRef.current.morphTargetInfluences[4] = 0; // = eye browes up (suprised)
		meshRef.current.morphTargetInfluences[5] = 0; // = eyelid closed (wink)
	};

	useEffect(() => {
		if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;
		resteMood();

		switch(mood){
		case 'mad':
			meshRef.current.morphTargetInfluences[2] = 0.3;
			meshRef.current.morphTargetInfluences[3] = 1;
			break;
		case 'happy':
			meshRef.current.morphTargetInfluences[4] = 0.8;
			meshRef.current.morphTargetInfluences[1] = 0.4;
			break;
		case 'sad':
			meshRef.current.morphTargetInfluences[2] = 0.8;
			break;
		case 'insecure':
			meshRef.current.morphTargetInfluences[2] = 0.5;
			break;
		}
	}, [mood]);

	const Blink = () => {		
		if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;

		meshRef.current.morphTargetInfluences[5] = 1;
		setTimeout(() => {
			if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;
			meshRef.current.morphTargetInfluences[5] = 0;
		}, 100);
	};

	randomInterval(() => {
		Blink();
	}, 1000, 5000);

	//#endregion
	return ( 
		<></>
	);
};
 
export default MorphTargets;