import {Howler} from 'howler';
import {HiVolumeOff, HiVolumeUp} from 'react-icons/hi';
import { useEffect } from 'react';
import IconButton from '../buttons/IconButton';
import { mutedAtom } from '../../atoms';
import { useAtom } from 'jotai';

const AudioToggle = (props: React.HTMLProps<HTMLButtonElement>) => {

	const [muted, setMuted] = useAtom(mutedAtom);

	useEffect(() => {
		Howler.mute(muted);
	}, [muted]);

	return ( 
		<IconButton tabIndex={props.tabIndex} $selected={muted} onClick={() => setMuted(m => !m)}>
			{ !muted ? <HiVolumeUp/> : <HiVolumeOff/> }
		</IconButton>
	);
};
 
export default AudioToggle;