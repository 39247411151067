import { Euler, Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_68: {[key: string]: SceneData} = {
	'fr2_busy' : {		
		camera_settings: {
			position: new Vector3(3.056160796918933, 1.5, -4),
			target: new Vector3(2, 1.2, -2.792722723869409),
		},
		objects: {
			'22':{	
				type : 'character', 
				position: new Vector3(3.056160796918933, 0, -2.792722723869409),
				rotation: [0,Math.PI / 1,0]
			}
		},
		background_characters: [
			{	
				position: new Vector3(1, 0, 3),
				rotation: new Euler(0,0,0),
				model: 'carlos',
				anim: 'checking_storage'
			},
			{	
				position: new Vector3(-1.5, 0, 0.7),
				rotation: new Euler(0,-Math.PI / 2,0),
				model: 'marc',
				anim: 'checking_storage'
			},
			{	
				position: new Vector3(-2.2, 0, 3),
				rotation: new Euler(0,1,0),
				model: 'morgan',
				anim: 'taking_02'
			},
			{	
				position: new Vector3(-1.2, 0, 3),
				rotation: new Euler(0,-1.5,0),
				model: 'chris',
				anim: 'talking_01'
			}
		]
	},
	'fr2_less_busy' : {
		camera_settings: {
			position: new Vector3(3.056160796918933, 1.5005433322386412, -4),
			target: new Vector3(2, 1.2, -2.792722723869409),
		},
		objects: {
			'22':{	
				type : 'character', 
				position: new Vector3(3.056160796918933, 0, -2.792722723869409),
				rotation: [0,Math.PI / 1,0]
			}
		},
		background_characters: [
			{	
				position: new Vector3(-2.5, 0, 5),
				rotation: new Euler(0,-Math.PI / 2,0),
				model: 'carlos',
				anim: 'checking_storage'
			},
		]
	},
	
};

export default scenes_scenario_68;