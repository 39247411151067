import { atom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';
import { GameEvent } from './content';
/**
 * Reducer that handles events to be triggered in a FIFO transition.
 * @param state current value of the state.
 * @param action action given to be handled.
 * @returns 
 */
const GemQueueReducer = (state: GemEventQueue, action: GemEventQueueActions) => {
	switch(action.type){
	case 'add':
		state = queueAdd(state, action);
		return [...state];
	case 'remove':
		state = queueRemove(state, action);
		return [...state];
	case 'clear':
		return [];
	}
};

/**
 * Method used by the reducer when adding to the queue.
 * @param state the original state
 * @param action the action given to the reducer.
 * @returns 
 */
const queueAdd = (state: GemEventQueue, action:GemEventQueueActionAdd) : GemEventQueue => {
	const newEvent : GemEventQueueEntry = action.payload;
	// const searchEventId = state.findIndex((e) => 
	// 	e.type === action.payload.type
	// );
	// if(searchEventId !== -1) return state;
	state.push(newEvent);
	return state;
};

/**
 * Method used by the reducer when removing from the queue.
 * @param state the original state.
 * @param action the action given to the reducer.
 * @returns 
 */
const queueRemove = (state: GemEventQueue, action:GemEventQueueActionRemove) : GemEventQueue => {
	const searchEventId = state.findIndex((e) => 
		e.type === action.payload.type
	);
	if(searchEventId === -1) return state;
	state.splice(searchEventId, 1);
	return state;
};


/**
 * Atom that handles events to be triggered in a FIFO transition.
 */
export const GemQueueAtom = atomWithReducer<GemEventQueue, GemEventQueueActions>([],GemQueueReducer);
export const GemDirectQueueAtom = atom<GemEventQueue | []>([]);
export const handleGemQueueAtom = atom(false);

// types

type GemEventQueue = GemEventQueueEntry[];
export type GemEventQueueEntry = GameEvent ;

type GemEventQueueActions = GemEventQueueActionAdd | GemEventQueueActionRemove | GemEventQueueActionClear;

type GemEventQueueActionAdd = {
	type: 'add',
	payload: GameEvent;
};

type GemEventQueueActionRemove = {
	type: 'remove',
	payload: GameEvent;
};

type GemEventQueueActionClear = {
	type: 'clear',
};






