import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './tools/i18n';
import CustomThemeProvider from './style/CustomThemeProvider';
import { DeveloperConsole } from './components/console/DeveloperConsole';
import packageJson from '../package.json';
import Scorm from './components/scorm/Scorm';
import './style/fonts.css';

import Plausible from 'plausible-tracker';
import App from './App';

import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://7f4d7e907301dc734595f36edd379da1@o4507492530782208.ingest.de.sentry.io/4507588941709392',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const { trackPageview } = Plausible({
	domain: 'product2.tqwi.nl',
	hashMode: true,
});

trackPageview({
	url: location.href,
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const checkAppVersion = () => {
	const localVersion = localStorage.getItem('version');

	if (!localVersion || parseInt(localVersion.replaceAll('.', '')) <= 341) {
		localStorage.clear();
	}

	localStorage.setItem('version', packageJson.version);
};

checkAppVersion();

root.render(
	<StrictMode>
		<Scorm>
			<CustomThemeProvider>
				<App />
				<DeveloperConsole />
			</CustomThemeProvider>
		</Scorm>
	</StrictMode>
);
