import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './bag_animated-transformed.glb';
import { AudioLibrary } from '../../../../audio/Audio';

type GLTFResult = GLTF & {
  nodes: {
    deliverybag: THREE.SkinnedMesh
    Bone: THREE.Bone
  }
  materials: {
    JET_deliverybag: THREE.MeshStandardMaterial
  }
  animations: GLTFAction[]
};

type ActionName = 'bag_order_disappointed' | 'bag_order_idle' | 'bag_order_putdown' | 'bag_order_receive' | 'bag_order_throw';
interface GLTFAction extends THREE.AnimationClip {
	name: ActionName
}

export default function Bag(props: JSX.IntrinsicElements['group'] & {animation: string} ) {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF(model) as GLTFResult;
	const { actions, mixer } = useAnimations(animations, group) as unknown as {actions: {[key in ActionName]: THREE.AnimationAction}, mixer: THREE.AnimationMixer};
	const [visible, setVisible] = useState(false);

	const options: {[key: string] : {name: ActionName, sounddelay: number}} = {
		'jet_order_receive' : {name: 'bag_order_receive', sounddelay: 2800},
		'jet_order_disappointed' : {name: 'bag_order_disappointed', sounddelay: 0},
		'jet_order_putdown' : {name: 'bag_order_putdown', sounddelay: 2000},
		'jet_order_throw': {name: 'bag_order_throw', sounddelay: 1000},
	};

	
	const idles: {[key: string] : ActionName} = {
		'bag_order_receive': 'bag_order_idle',
		'bag_order_disappointed': 'bag_order_idle',
		// 'bag_order_putdown': '',
		// 'bag_order_throw': '',
	};
	
	useEffect(() => {
		if(options[props.animation]){
			Object.values(actions).map((a) => a.stop().reset());
			setVisible(true);
			actions[options[props.animation].name].reset().play().setLoop(THREE.LoopOnce, 1).clampWhenFinished = true;
			
			setTimeout(() => {
				AudioLibrary[props.animation] && AudioLibrary[props.animation].load().play();
			}, options[props.animation].sounddelay);
		} 

		mixer.addEventListener('finished', (e) => {
			if(actions[idles[e.action.getClip().name]]){
				actions[idles[e.action.getClip().name]].play();
			}
			return;
		});

	}, [props.animation]);
	
	return (
		<group visible={visible} ref={group} {...props} dispose={null}>
			<group name="Scene">
				<group name="deliverybag_armature" position={ [0, 1.15, 0.223]} rotation={[0, -Math.PI / 4, 0]}>
					<primitive object={nodes.Bone} />
					<skinnedMesh name="deliverybag" geometry={nodes.deliverybag.geometry} material={materials.JET_deliverybag} skeleton={nodes.deliverybag.skeleton} />
				</group>
			</group>
		</group>
	);
}

useGLTF.preload(model);
