import { itemList } from '../../models/objects/itemList';
import { useAtom } from 'jotai';
import { activeItemIdAtom, Item as ItemType, SceneObjectType } from '../../../../atoms/scene';
import InteractiveSceneObject from '../InteractiveSceneObject';
import { useMemo } from 'react';
import { SceneObjectProps } from './SceneObject';
import { SceneObjectState } from '../sceneReducer';

const Item = (props: ItemProps) => {

	const interactive = useMemo(() => {
		return (props.state ? props.state.messageState === 0 : true) && props.object.item_id != undefined && props.interactive ? true : false;
	},[props.interactive, props.state, props.object]);

	const Model = itemList[props.object.mesh];

	const [, setActiveItemId] = useAtom(activeItemIdAtom);

	return ( 
		<group key={`item-${props.id}`}>
			<InteractiveSceneObject
				onClick={() => props.object.type === 'item' && interactive && setActiveItemId(props.object.item_id)}
				interactable={interactive}
				position={props.object.position ?? [0,0,0]}
				data={props.object}
				objectId={props.id}
			>
				<Model 
					rotation={props.object.rotation ? [props.object.rotation[0], props.object.rotation[1], props.object.rotation[2]] : [0,0,0]}  
					renderOrder={1}
				/>
			</InteractiveSceneObject> 
		</group>
	);
};

// types

type ItemProps = SceneObjectProps & {
	object: SceneObjectType<ItemType>
	state: SceneObjectState
};
 
export default Item;