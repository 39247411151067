import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './glb/3D_justeat_bike-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    stand: THREE.Mesh
  }
  materials: {
    mat_justeat_bike: THREE.MeshStandardMaterial
  }
  // animations: GLTFAction[]
};

// type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export default function Bike(props: JSX.IntrinsicElements['group']) {
	const { nodes, materials } = useGLTF(model) as GLTFResult;
	return (
		<group scale={0.9} {...props} dispose={null}>
			<mesh name="stand" geometry={nodes.stand.geometry} material={materials.mat_justeat_bike} />
		</group>
	);
}

// useGLTF.preload(model);
