import styled from 'styled-components';
import { Modal } from './Modal';
import { fadeIn, slideInTop } from '../../style/animations';
import { HTMLAttributes, ReactNode, useCallback, useRef, useState } from 'react';
import IconButton from '../buttons/IconButton';
import { MdOutlineClose } from 'react-icons/md';

const PopoverModal = (props: PopoverModalProps) => {

	const headerBarRef = useRef<HTMLDivElement>(null);

	const [spacerHeight, setSpacerHeight] = useState(0);

	const determineSpacerHeight = useCallback(() => {
		if(!headerBarRef.current) return;
		setSpacerHeight(headerBarRef.current.clientHeight);
	},[headerBarRef.current]);

	return (  
		
		<Container>
			<Content {...props} onAnimationStart={determineSpacerHeight}>
				<HeaderBar ref={headerBarRef}>
					<IconButton style={{visibility: 'hidden'}} disabled/>
					<h2>{props.title ?? 'title'}</h2>	
					<IconButton onClick={() => props.onClose && props.onClose()} $visible={props.onClose ?  true : false }><MdOutlineClose/></IconButton>
				</HeaderBar>
				<ScrollingContent $scrollable={props.$scrollable ?? false} id={props.$scrollabledivid}>
					<HeaderBarSpacer height={spacerHeight}/>
					{props.children}
				</ScrollingContent>
			</Content>
			{props.containerchildren}
		</Container>
	);
};
 
export default PopoverModal;

const Container = styled.div<PopoverModalProps>`
	position: absolute;
	inset: 0;
    
    pointer-events: all;
    overflow: hidden;

	display: flex;
	flex-direction: column;

	justify-content: ${p => p.fillHeight ? '' : 'center'};

	gap: 20px;
	padding: 10px;

	opacity: 0;

	background-color: rgba(0,0,0, 0.5);
	animation: ${fadeIn} 2s ease-out forwards;
	
	z-index: 2;
`;

const Content = styled(Modal)<PopoverModalProps>`

	position: relative; 

	margin: 0 auto;
	height: ${p => p.fillHeight ? '100%' : ''};
	max-width: 800px;
	width: 100%;

	padding: 0;

	display: flex;
	flex-direction: column;
	gap: 10px;

	opacity: 0;
	animation: ${slideInTop} 1s ease forwards;
	animation-delay: 1s;

	overflow: hidden;

	& > h1, h2 {
		margin: 0;
	}

`;

const ScrollingContent = styled.div<{$scrollable: boolean}>`
	overflow-y: ${p => p.$scrollable ? 'auto' : 'hidden'};
	overflow-x: hidden;
	position: relative;
	height: 100%;

	display: flex;
	flex-direction: column;
	gap: 20px;

	padding: 20px;
	padding-top: 0;

	z-index: -2;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		padding: 10px;
		padding-top: 0;
	}

`;

export const HeaderBar = styled.div`
	position: absolute;
	left:0; right:0; top:0;

	text-align: center;

	display: flex;
	align-items: center;

	color: ${p => p.theme.colors.neutralLightest};

	// styling for cross button
	& > button {
		margin-inline-start: auto;
		background-color: transparent;
		margin-inline-end: 10px;
	}

	& > h2 {
		margin: 0;
	}

	padding: 20px;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		padding: 10px;
	}

	z-index: 2;

	// blue shape background
	&:before {
		z-index: -1;
		content: '';
		position: absolute;
		left:50%;top:0;
		height: 200%;
		width: 200%;
		background-color: ${p => p.theme.colors.primary};
		border-radius: 100%;
		translate: -50% -50%;
		@media (max-width: ${p => p.theme.responsive.media.sm}){
		}
	}

	& > h2 {
		color: ${p => p.theme.colors.neutralLightest};
	}
`;

const HeaderBarSpacer = styled.div<{height: number}>`
	height: ${p => p.height}px;
	width: 100%;
	flex-shrink: 0;
`;

// types

interface PopoverModalProps extends HTMLAttributes<HTMLDivElement> {
	title?: string,
	onClose?: () => void,
	containerchildren?: ReactNode
	$scrollable?: boolean;
	$scrollabledivid?: string;
	fillHeight?:boolean;
}