import { useAtom } from 'jotai';
import { activeSceneAtom } from '../../../atoms/scene';
import { gameResultAtom } from '../../../atoms/gameResults';
import { scenarioDataAtom } from '../../../atoms/content';

const DCScenario = () => {

	const [scenarioData, ] = useAtom(scenarioDataAtom);
	const [gameResult, gameResultDispatch] = useAtom(gameResultAtom);
	const [activeScene, setActiveScene] = useAtom(activeSceneAtom);

	return ( 
		<>
			<label>
				set ending
				<select value={gameResult.ending_id} onChange={(e) => gameResultDispatch({type: 'set-ending', ending_id: e.target.value})}>
					<option>none</option>
					{ scenarioData && Object.keys(scenarioData.endings).map((value) => 
						<option value={value} key={`dc-gem-typeoption-${value}`}>{value}</option>
					)}
				</select>
			</label>
			{activeScene && <label>
				set scene
				<input type={'text'} onChange={(e) => setActiveScene(e.target.value)} value={activeScene}/>
			</label>}
		</>
	);
};
 
export default DCScenario;