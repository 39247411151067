import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';
import { degToRad } from 'three/src/math/MathUtils.js';

const scenes_scenario_11 : {[key: string]: SceneData} = {
	sf2_meetingroom: {
		camera_settings: {
			position: new Vector3(-6.651930, 1.54542, 0.8450850301),
			target: new Vector3(-8, 1.2, -0.5),
			maxAzimuthAngle: degToRad(140),
			minAzimuthAngle: degToRad(40),
		},
		objects: {
			'3':{
				type: 'character',
				default_mood: 'insecure',
				position: new Vector3(-7.6, -0.45, -1.2),
				rotation: [0,-0.4,0],
				sitting: true,
				idle: 'idle_sitting_lap',
			},
			'4':{
				type: 'character',
				position: new Vector3(-9, 0, 0.5),
				rotation: [0,-0.9+Math.PI,0],
				startMessages: ['78',''],
				default_mood: 'happy',
			},
			'5':{
				type : 'character', 
				position: new Vector3(-6.65, -0.45, -1.2),
				rotation: [0,-0.8,0],
				sitting: true,
				idle: 'idle_sitting_lap',
			},
			'posters': {
				type: 'item',
				item_id: '1',
				mesh: 'posters',
				position: new Vector3(-8, 0.8, -0.5),
				rotation: [0,0,0],
				indicatorOffset: {
					x: 0,
					y: 0.2,
					z: 0,
				},
			}
		},
	},
	sf2_ada: {
		camera_settings: {
			target: new Vector3(-7.6, 1.165, -0.221287526,),
			position: new Vector3(-7.6, 1.17, -0.2),
		},
		objects: {
			'3':{
				type: 'character',
				default_mood: 'insecure',
				position: new Vector3(-7.6, -0.45, -1.2),
				rotation: [0,-0.4,0],
				sitting: true,
				idle: 'idle_sitting_lap',
			},
		},
	}
};

export default scenes_scenario_11;