import * as THREE from 'three';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from '../glb/male_04_drunk_mesh.glb';
import { Expression } from './Human';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { scenarioDataAtom } from '../../../../../atoms/content';
import { objectsStatesAtom } from '../../../../../atoms/scene';
import { activeScenarioAtom } from '../../../../../atoms/scenario';
import { activeMessageAtom } from '../../../../../atoms/messaging';
import randomInterval from 'react-random-interval-hook'; 

type GLTFResult = GLTF & {
  nodes: {
    character: THREE.SkinnedMesh
    beerbottle: THREE.Mesh
    Hips: THREE.Bone
    kneeIKL: THREE.Bone
    heelIKL: THREE.Bone
    elbowIKL: THREE.Bone
    handIKL: THREE.Bone
    kneeIKR: THREE.Bone
    heelIKR: THREE.Bone
    elbowIKR: THREE.Bone
    handIKR: THREE.Bone
  }
  materials: {
    character_material: THREE.MeshStandardMaterial
    mat_beerbottle2: THREE.MeshStandardMaterial
  }
};

type ActionName =
  | 'action_aggitated_shouting'
  | 'action_body_shrug'
  | 'action_body_talking_01'
  | 'action_body_talking_02'
  | 'action_body_talking_03'
  | 'action_body_talking_happy'
  | 'action_body_talking_insecure'
  | 'action_body_talking_mad'
  | 'action_body_talking_sad'
  | 'action_bottle_putaway'
  | 'action_head_nod'
  | 'action_head_talking_01'
  | 'action_head_talking_02'
  | 'action_order_disappointed'
  | 'action_order_putdown'
  | 'action_order_receive'
  | 'action_order_throw'
  | 'action_ped_angry_01'
  | 'action_ped_angry_02'
  | 'action_provocative_01'
  | 'action_provocative_02'
  | 'action_provocative_03'
  | 'action_robber_01'
  | 'action_robber_02'
  | 'action_robber_03'
  | 'action_robber_04'
  | 'action_sitting_talking_01'
  | 'action_sitting_talking_02'
  | 'action_sitting_talking_03'
  | 'action_sitting_talking_happy'
  | 'action_sitting_talking_insecure'
  | 'action_sitting_talking_mad'
  | 'action_sitting_talking_sad'
  | 'action_threatening_01'
  | 'action_threatening_02'
  | 'action_threatening_stepback'
  | 'bag_order_disappointed'
  | 'bag_order_idle'
  | 'bag_order_putdown'
  | 'bag_order_receive'
  | 'bag_order_throw'
  | 'bg_morgan_armature|mixamo.com|Layer0'
  | 'phone_rob'
  | 'robberknife_threatening_01'
  | 'robberknife_threatening_02'
  | 'robberknife_threatening_idle';

  type IdleName =
  | 'idle_angry_02'
  | 'idle_body_01'
  | 'idle_body_02'
  | 'idle_body_happy'
  | 'idle_body_headscratch'
  | 'idle_body_insecure'
  | 'idle_body_mad'
  | 'idle_body_sad'
  | 'idle_conversation'
  | 'idle_drunk'
  | 'idle_order'
  | 'idle_pose'
  | 'idle_provocative'
  | 'idle_robber'
  | 'idle_sitting_desk'
  | 'idle_sitting_happy'
  | 'idle_sitting_insecure'
  | 'idle_sitting_lap'
  | 'idle_sitting_mad'
  | 'idle_sitting_rest'
  | 'idle_sitting_sad'
  | 'idle_threatening';

export type ModelProps = {
	animation: string
	mood: Expression
	objectId: string
};

export type Actions = { [x: string]: THREE.AnimationAction };

export default function Male04drunk(props: JSX.IntrinsicElements['group'] & ModelProps) {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF(model) as GLTFResult;
	const { actions, mixer } = useAnimations(animations, group) as unknown as { actions: Actions, mixer: THREE.AnimationMixer };
	const meshRef = useRef<THREE.SkinnedMesh>(null);

	const [nowPlaying, setNowPlaying] = useState<string>('idle_drunk');
	const [previousData, setPreviousData] = useState<{ previous: string, idle: string }>({ previous: '', idle: '' });
	const [scenarioData] = useAtom(scenarioDataAtom);
	const objectsStatesDispatch = useSetAtom(objectsStatesAtom);
	const activeScenarioId = useAtomValue(activeScenarioAtom);
	const activeMessageId = useAtomValue(activeMessageAtom);

	const NPCSpeaking = useMemo(() => {
		if (!scenarioData || !activeScenarioId || !activeMessageId || !scenarioData.messages) return;
		const message = scenarioData.messages[activeMessageId];
		if (message && message.message_type != 0) return;
		return message.speaking_character != -1;
	}, [activeMessageId]);

	//#region playing aniamtions

	const playAnimation = (animName: string, idleName: string) => {

		if(animName === 'action_body_just_talking'){ 
			actions['action_head_talking_02'].reset().play().setLoop(THREE.LoopOnce, 1);
			props.objectId != undefined && objectsStatesDispatch({//reset props.animation
				type: 'set-character-animation',
				object_id: props.objectId,
				animation: ''
			});
			return;
		}
		NPCSpeaking && actions['action_head_talking_02'].reset().play().setLoop(THREE.LoopOnce, 1);
		const previous = actions[nowPlaying];
		const anim = actions[animName];

		//animation
		if (anim && previous) {
			anim.reset().play().setLoop(THREE.LoopOnce, 1).clampWhenFinished = true;
			previous.crossFadeTo(anim, 0.8, false);
			setNowPlaying(animName);
		}

		mixer.addEventListener('finished', (e) => {
			setPreviousData({ previous: e.action.getClip().name, idle: idleName });
		});
	};

	useEffect(() => {
		if (previousData.previous === '' || previousData.idle === '') return;
		if (previousData.previous === nowPlaying) {

			const previous = actions[previousData.previous];
			const idle = actions[previousData.idle];

			if (idle && previous) {
				idle.reset().play();
				previous.crossFadeTo(idle, 0.5, false);
				setNowPlaying(previousData.idle);
			}				
			
			props.objectId != undefined && objectsStatesDispatch({//reset props.animation
				type: 'set-character-animation',
				object_id: props.objectId,
				animation: ''
			});
		}
	}, [previousData]);

	useEffect(() => {
		Object.values(actions).map((a) => a.stop().reset());

		actions['idle_drunk'].play();
	}, []);	

	useEffect(() => {		
		if(!props.animation) return;
		
		//plays animations with idles
		const idles: {[key in ActionName]? : IdleName} = {
			action_body_talking_mad: 'idle_body_mad',
			action_body_talking_happy: 'idle_body_happy',
			action_body_talking_sad: 'idle_body_sad',
			action_body_talking_insecure: 'idle_body_insecure',
			action_order_receive: 'idle_order',
			action_order_throw: 'idle_angry_02',
			action_order_disappointed: 'idle_order',
			action_robber_01: 'idle_robber',
			action_robber_02: 'idle_robber',
			action_robber_03: 'idle_robber',
			action_robber_04: 'idle_robber',
			action_threatening_01: 'idle_threatening',
			action_threatening_02: 'idle_threatening',
			action_provocative_01:'idle_provocative',
			action_provocative_02:'idle_provocative',
			action_ped_angry_01:'idle_angry_02',
			action_ped_angry_02:'idle_angry_02',
			action_bottle_putaway: 'idle_body_mad'
		};


		const animation = (props.animation.includes('jet_') ? props.animation.replace('jet_', 'action_') : 'action_body_'+props.animation) as ActionName;

		if(animation === 'action_bottle_putaway'){
			setTimeout(() => {
				if(nodes && nodes.beerbottle.morphTargetInfluences)
					nodes.beerbottle.morphTargetInfluences[0] = 1;
			}, 1000);
		}

		const emotionIdle = idles[animation] ? idles[animation] : undefined; 
		playAnimation(animation, emotionIdle ?? 'idle_conversation');

	}, [props.animation]);

	const resteMood = () => {
		if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;
		//reset
		meshRef.current.morphTargetInfluences[0] = 0; // = mouth open
		meshRef.current.morphTargetInfluences[1] = 0; // = mouth smile
		meshRef.current.morphTargetInfluences[2] = 0; // = mouth down (mad)
		meshRef.current.morphTargetInfluences[3] = 0; // = eye browes down (mad)
		meshRef.current.morphTargetInfluences[4] = 0; // = eye browes up (suprised)
		meshRef.current.morphTargetInfluences[5] = 0.4; // = eyelid closed (wink)
	};

	useEffect(() => {
		if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;
		meshRef.current.morphTargetInfluences[5] = 0.4;
	}, []);

	useEffect(() => {
		if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;
		resteMood();

		switch(props.mood){
		case 'mad':
			meshRef.current.morphTargetInfluences[2] = 0.3;
			meshRef.current.morphTargetInfluences[3] = 0.5;
			break;
		case 'happy':
			meshRef.current.morphTargetInfluences[4] = 0.8;
			meshRef.current.morphTargetInfluences[1] = 0.4;
			break;
		case 'sad':
			meshRef.current.morphTargetInfluences[2] = 0.8;
			break;
		case 'insecure':
			meshRef.current.morphTargetInfluences[2] = 0.5;
			break;
		}
	}, [props.mood]);

	const Blink = () => {		
		if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;

		meshRef.current.morphTargetInfluences[5] = 1;
		setTimeout(() => {
			if(!meshRef.current || !meshRef.current.morphTargetInfluences) return;
			meshRef.current.morphTargetInfluences[5] = 0.4;
		}, 100);
	};

	randomInterval(() => {
		Blink();
	}, 1000, 5000);

	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<group name="character_rig">
					<skinnedMesh
						name="character"
						ref={meshRef}
						geometry={nodes.character.geometry}
						material={materials.character_material}
						skeleton={nodes.character.skeleton}
						morphTargetDictionary={nodes.character.morphTargetDictionary}
						morphTargetInfluences={nodes.character.morphTargetInfluences}
					/>
					<primitive object={nodes.Hips} />
					<primitive object={nodes.kneeIKL} />
					<primitive object={nodes.heelIKL} />
					<primitive object={nodes.elbowIKL} />
					<primitive object={nodes.handIKL} />
					<primitive object={nodes.kneeIKR} />
					<primitive object={nodes.heelIKR} />
					<primitive object={nodes.elbowIKR} />
					<primitive object={nodes.handIKR} />
				</group>
			</group>
		</group>
	);
}

// useGLTF.preload(mdoel);