import { HTMLAttributes, useContext, useMemo } from 'react';
import { PiSmileyBold, PiSmileyMehBold, PiSmileySadBold } from 'react-icons/pi';
import styled, { ThemeContext } from 'styled-components';

export const Smiley = (props: SmileyProps) => {

	const themeContext = useContext(ThemeContext);

	const color = useMemo(() => {
		if(!themeContext) return;
		switch(props.$variant){
		case 'happy': {
			return themeContext.colors.correct;
		}
		case 'sad': {
			return themeContext.colors.danger;
		}
		case 'neutral': {
			return themeContext.colors.alert;
		}
		}
	},[props.$variant, themeContext]);

	return (  
		<StyledIconWrap {...props} style={{color: color}}>
			{props.$variant === 'happy' ? <PiSmileyBold/> : props.$variant === 'neutral' ? <PiSmileyMehBold/>: <PiSmileySadBold/> }
		</StyledIconWrap>
	);
};

//sc 

const StyledIconWrap = styled.div`

	position: relative;
	aspect-ratio: 1/1;
	flex-grow: 0;
	flex-shrink: 0;

	height: 30px;
	aspect-ratio: 1/1!important;

	& > svg {
		height: 100%;
		width: 100%;
	}
`;

// types

interface SmileyProps extends HTMLAttributes<HTMLDivElement>{
	$variant: 'happy' | 'neutral' | 'sad'
}
 
