import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './glb/beercrate-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    beercrate: THREE.Mesh
  }
  materials: {
    beercrate: THREE.MeshStandardMaterial
  }
  // animations: GLTFAction[]
};

// type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>;

export default function Crate(props: JSX.IntrinsicElements['group']) {
	const { nodes, materials } = useGLTF(model) as GLTFResult;
	return (
		<group {...props} dispose={null}>
			<mesh name="beercrate" geometry={nodes.beercrate.geometry} material={materials.beercrate} />
		</group>
	);
}

useGLTF.preload(model);
