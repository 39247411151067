import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';





const options = {
	order: ['localStorage', 'querystring', 'navigator'],
	lookupLocalStorage: 'i18nextLng',
	lookupQuerystring: 'lng'
};

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		detection: options,
		resources: {},
		fallbackLng: { 
			'nl-be': ['nl'],
			'default': ['en']
		},
		interpolation: {
			escapeValue: false,
		},
		// debug: true,
	});

export default i18n;