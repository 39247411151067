import { useAtom, } from 'jotai';
import { controlsEnabledAtom, showPerformanceAtom } from '../../../atoms/three';
import { scormDebugAtom } from '../../../atoms/auth';

const DCGeneral = () => {

	const [controlsEnabled, setControlsEnabled] = useAtom(controlsEnabledAtom);
	const [showPerfomance, setShowPerformance] = useAtom(showPerformanceAtom);
	const [scormDebug, setScormDebug] = useAtom(scormDebugAtom);


	return ( 
		<>
			<label>
				enable perf
				<input type={'checkbox'} onChange={(e) => setShowPerformance(e.target.checked)} checked={showPerfomance}/>
			</label>
			<label>
				enable controls
				<input type={'checkbox'} onChange={(e) => setControlsEnabled(e.target.checked)} checked={controlsEnabled}/>
			</label>
			<label>
				scorm debug
				<input type={'checkbox'} onChange={(e) => setScormDebug(e.target.checked)} checked={scormDebug}/>
			</label>
			<button onClick={() => {localStorage.clear(); location.reload();}}>clear local storage</button>
		</>
	);
};

export default DCGeneral;