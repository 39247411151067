import { ReactNode, useEffect } from 'react';
import pipwerks from 'pipwerks-scorm-api-wrapper';
import { useAtom } from 'jotai';
import { scormDebugAtom, scormInitializedAtom } from '../../atoms/auth';
import i18n from '../../tools/i18n';

const Scorm = (props: {children: ReactNode}) => {

	const [scormInitialized, setScormInitialized] = useAtom(scormInitializedAtom);
	const [scormDebug, ] = useAtom(scormDebugAtom);

	useEffect(() => {
		pipwerks.debug.isActive = false;
		if(pipwerks.SCORM.init())
			setScormInitialized(true);
		else 
			setScormInitialized(scormDebug ? true : false);
	}, [scormDebug]);

	useEffect(() => {
		if(scormInitialized){
			const preferedLanguage = pipwerks.SCORM.get('cmi.learner_preference.language');
			i18n.changeLanguage(preferedLanguage);
		}
	}, [scormInitialized]);

	return ( 
		<>
			{scormInitialized != undefined && props.children}
		</>
	);
};
 
export default Scorm;