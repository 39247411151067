import { Euler, Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';
import { degToRad } from 'three/src/math/MathUtils.js';

const scenes_scenario_69: {[key: string]: SceneData} = {
	'sl1_s1' : {		
		camera_settings: {
			position: new Vector3(0.6951935442588593, 1.5716814036539848, -6.199526797580308),
			target: new Vector3(0, 1.6, -5),
		},
		
		objects: {
			'5': {	
				type : 'character', 
				props: true,
				position: new Vector3(0.8,0,-3.5),
				rotation: [0,Math.PI+0.4,0],
			},
			'10': {	
				type : 'character', 
				props: true,
				position: new Vector3(-1.2,0,-4.8),
				rotation: [0,Math.PI-1.2,0],
			},
			'15': {	
				type : 'character', 
				props: true,
				position: new Vector3(-1,0,-4),
				rotation: [0,Math.PI-0.7,0],
			},
			'22': {	
				type : 'character', 
				props: true,
				position: new Vector3(0,0,-3.7),
				rotation: [0,Math.PI-0.1,0],
			},
		},
		background_characters: [
			{	
				position: new Vector3(0, 0, -10),
				rotation: new Euler(0,0,0),
				model: 'carlos',
				anim: 'checking_storage'
			},
			{	
				position: new Vector3(0, 0, -10),
				rotation: new Euler(0,-Math.PI / 2,0),
				model: 'morgan',
				anim: 'checking_storage'
			},
			{	
				position: new Vector3(0, 0, -10),
				rotation: new Euler(0,1,0),
				model: 'marc',
				anim: 'taking_02'
			},
			{	
				position: new Vector3(0, 0, -10),
				rotation: new Euler(0,-1.5,0),
				model: 'chris',
				anim: 'talking_01'
			}
		]
	},
	'sl1_s2' : {		
		camera_settings: {
			position: new Vector3(0.15, 1.65, -5.290),
			target: new Vector3(2, 1, -3),
			maxAzimuthAngle: degToRad(350),
			minAzimuthAngle: degToRad(180),
		},
		
		objects: {
			'10': {	
				type : 'character', 
				props: true,
				position: new Vector3(1.7, 0, 3),
				rotation: [0,-0.5,0],
				idle: 'idle_body_01'
			},
			'15': {	
				type : 'character', 
				props: true,
				position: new Vector3(3, 0, -3.5),
				rotation: [0,-Math.PI/2,0],
				default_mood: 'happy',
				startMessages: ['3367']
			},
		},
		background_characters: [
			{	
				position: new Vector3(1, 0, 3),
				rotation: new Euler(0,0,0),
				model: 'carlos',
				anim: 'checking_storage'
			},
			{	
				position: new Vector3(-1.5, 0, 0.7),
				rotation: new Euler(0,-Math.PI / 2,0),
				model: 'morgan',
				anim: 'checking_storage'
			},
			{	
				position: new Vector3(-2.2, 0, 3),
				rotation: new Euler(0,1,0),
				model: 'marc',
				anim: 'taking_02'
			},
			{	
				position: new Vector3(-1.2, 0, 3),
				rotation: new Euler(0,-1.5,0),
				model: 'chris',
				anim: 'talking_01'
			}
		]
	},
	'sl1_s3' : {		
		camera_settings: {
			position: new Vector3(0.5837272495948955, 1.2023246953236217, -2.526206545540605),
			target: new Vector3(-0.7, 1, -2.5),
		},
		room: 'fffcd77e-e772-420e-b9f4-0f2f447ce477',
		objects: {
			'15': {	
				type : 'character', 
				props: true,
				position: new Vector3(-0.7,-0.45,-2.5),
				rotation: [0,Math.PI/2,0],
				sitting: true,
				idle: 'idle_sitting_lap'
			},
		},
	},
	'sl1_s4' : {		
		camera_settings: {
			position: new Vector3(0.5837272495948955, 1.2023246953236217, -2.526206545540605),
			target: new Vector3(-0.7, 1, -2.5),
		},
		room: 'fffcd77e-e772-420e-b9f4-0f2f447ce477',
		objects: {
			'10': {	
				type : 'character', 
				props: true,
				position: new Vector3(-0.7,-0.45,-2.5),
				rotation: [0,Math.PI/2,0],
				sitting: true,
				idle: 'idle_sitting_lap'
			},
		},
	},
	'sl1_s5' : {		
		camera_settings: {
			position: new Vector3(0.15, 1.65, -5.290),
			target: new Vector3(0, 1.6, -5),
		},
		
		objects: {
			'5': {	
				type : 'character', 
				props: true,
				position: new Vector3(0.8,0,-3.5),
				rotation: [0,Math.PI+0.4,0],
			},
			'10': {	
				type : 'character', 
				props: true,
				position: new Vector3(-1.2,0,-4.8),
				rotation: [0,Math.PI-1.2,0],
			},
			'15': {	
				type : 'character', 
				props: true,
				position: new Vector3(-1,0,-4),
				rotation: [0,Math.PI-0.7,0],
			},
			'22': {	
				type : 'character', 
				props: true,
				position: new Vector3(0,0,-3.7),
				rotation: [0,Math.PI-0.1,0],
			},
		},
	},
};


export default scenes_scenario_69;