import { Vector3 } from 'three';
import { CameraSettings } from '../../components/Three/controls/ThreeControls';
import {feedbackSmallMeetingRoomPosition, messageTreeCameraPositions} from './messageTreeCameraPositions';

const cameraPositions: { [id: string]: CameraSettings } = {
	'sf2_adas_position': {
		target: new Vector3(-7.6, 1.165, -0.221287526,),
		position: new Vector3(-7.6, 1.17, -0.2),
	},

	'sf2_carlos_position': messageTreeCameraPositions['78'],

	'sf2_adas_final_position': {
		position: new Vector3(-8.002279, 1.10513065, -0.35627),
		target: new Vector3(-7.6, 1, -1.2),
	},

	'sf3_marc_office_position': feedbackSmallMeetingRoomPosition,

	//jet
	'sj3_take_step_back': {
		position: new Vector3(1.9660289, 1.606621323, 3.1),
		target: new Vector3(2, 1, 8),
	},

	'sj5_step_of_bike_forward': {
		position: new Vector3(-4.9660289, 1.406621323, -1),
		target: new Vector3(-4.9, 1, 8),
	},
	'sj5_step_of_bike_backward': {
		position: new Vector3(-4.9660289, 1.406621323, -1.6),
		target: new Vector3(-4.9, 1, 8),
	},
	//

	'ce1_talkingto_marc': {
		position: new Vector3(0, 1.653558, -6.266315),
		target: new Vector3(0, 1.5, -7),
	},
	'ce1_talkingto_lisa': {
		position: new Vector3(1.1, 1.6006794430343012, -1.1),
		target: new Vector3(1, 1.5, -2),
	},
	'ce1_talkingto_lisaandcarlos': {
		position: new Vector3(0.5, 1.6006794430343012, -1.1),
		target: new Vector3(0.3, 1.5, -2),
	},
	'ce1_talkingto_carlos': {
		position: new Vector3(2.5326522, 1.629702911, 5.82607331),
		target: new Vector3(2, 1.5, 7),
	},

	'bos1_talkingto_dana': {
		position: new Vector3(-1.711, 1.57463, 4.4693043),
		target: new Vector3(-2.5, 1.4, 5),
	},
	'looking_at_jackets':{
		position: new Vector3(-2.6519898830979556, 1.4638296973159481, 3.264728313467395),
		target: new Vector3(-3.2, 1.1, 4),
	},
	'looking_at_customer':{
		position: new Vector3(-2.5106248436851275, 1.5879035354959135, 3.141786010531283),
		target: new Vector3(-2.5, 1.4, 4),
	},

	'ce3_talkingto_lewis': {
		position: new Vector3(2.79636359, 1.60549361, -3.314934),
		target: new Vector3(-1,1.4,-4),
	},
	'ce3_talkingto_angela': {
		position: new Vector3(2.8, 1.506, -2.6312),
		target: new Vector3(2, 1.4, -1.5),
	},

	'fr1_talkingto_marc': {
		position: new Vector3(0.5, 1.6789423253696474, -5.9),
		target: new Vector3(0, 1.6, -5),
	},

	//
	'sl1_talkingto_marc': {
		position: new Vector3(0.47808884085115205, 1.5488961767315137, -4.273980127423516),
		target: new Vector3(0.8,1.4,-3.5),
	},

	'sl1_talkingto_angela': {
		position: new Vector3(0.07047761198597385, 1.5916497589816254, -4.638247754468956),
		target: new Vector3(0,1.4,-3.7),
	},

	'sl1_talkingto_lewis': {
		position: new Vector3(-0.5226251286322119, 1.656441279480977, -4.6120774287716),
		target: new Vector3(-1, 1.4, -4),
	},

	'sl1_talkingto_lisa': {
		position: new Vector3(-0.3515942983530509, 1.5762945851682184, -5.179070638931522),
		target: new Vector3(-1.2,1.4,-4.8),
	},

	'sl1_talkingto_group': {
		position: new Vector3(0.15, 1.65, -5.290),
		target: new Vector3(0, 1.6, -5),
	},

};

export default cameraPositions;

