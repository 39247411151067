import { useAtom } from 'jotai';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import i18n from 'i18next';
import { directus } from '../../tools/directus';
import { readItem, readItems } from '@directus/sdk';
import { useParams } from 'react-router-dom';
import { Content, availableLanguagesAtom, scenarioDataAtom } from '../../atoms/content';
import styled from 'styled-components';
import { sceneList } from '../../content/scenes/scene_list';

const ContentManager = (props: TranslationManagerProps) => {
	const params = useParams();

	/** 0 means retrieving data, 1 means processing retrieved data, 2 means loaded */
	const [loaded, setLoaded] = useState<number>();
	const [error, setError] = useState<string>();
	const [, setLanguages] = useAtom(availableLanguagesAtom);


	const [, setScenarioData] = useAtom(scenarioDataAtom);

	const delay = useRef<NodeJS.Timeout>();

	useEffect(() => {
		// by checking if once is set, we force this code to only run once.
		if (delay.current) clearTimeout(delay.current);
		delay.current = setTimeout(() => {
			try {
				(retrieveContent());
			} catch (e) { console.error(e); }
		}, 100);
	}, []);

	//** retrieves content. content that has a newer version available is retrieved and readied for proccesing. */
	const retrieveContent = async () => {
		if (!params.scenarioId) return;

		const contentPackages = await directus.request(readItem('product_2_scenarios', params.scenarioId, { fields: ['development_content_package', 'production_content_package', 'languages'] })).catch(() => {
			const newError = `Scenario ${params.scenarioId} does not exist`;
			setError(newError);
			throw new TypeError(newError);
		});

		if (!contentPackages) return;

		if(!sceneList[params.scenarioId]){
			const newError = `Scenario ${params.scenarioId} doesnt have local scene data`;
			setError(newError);
			throw new TypeError(newError);
		}

		setLanguages(contentPackages.languages);
		const env = import.meta.env.MODE as 'development' | 'production';

		//get content package out of scenario
		if (!contentPackages[`${env}_content_package`]) {
			const newError = `Scenario ${params.scenarioId} does not have a content package for ${env}`;
			setError(newError);
			throw new TypeError(newError);

		}

		const currentContent = await directus.request(readItem('product_2_content', contentPackages[`${env}_content_package`])) as Content;

		const texts = await directus.request(readItems('product_2_texts', { limit: -1 }));
		const textsTranslations = await directus.request(readItems('product_2_texts_translations', { limit: -1 }));

		await texts.forEach((text) => {
			text.translations.forEach((textTranslationId: number) => {
				const t = textsTranslations.find(tr => tr.id === textTranslationId);
				if (!t || !currentContent.data.translations) return;
				if (!currentContent.data.translations[t.product_2_languages_code]) currentContent.data.translations[t.product_2_languages_code] = {};
				currentContent.data.translations[t.product_2_languages_code][text.key] = t.text;
			});
		});

		setLoaded(1);

		processContent(currentContent);
	};

	/** process retrieved content */
	const processContent = async (content: Content) => {
		//add translation to i18n
		if (content.data.translations) {
			await Object.entries(content.data.translations).map((keyvalue) => {
				if (i18n.hasResourceBundle(keyvalue[0], 'translation')) i18n.removeResourceBundle(keyvalue[0], 'translation');
				i18n.addResourceBundle(keyvalue[0], 'translation', keyvalue[1], true);
			});
		}

		delete content.data.translations;

		await setScenarioData(content.data);

		setLoaded(2);
	};

	if (error) {
		return (
			<Error>{error}</Error>
		);
	} else {
		return (
			loaded === 2 ? props.children : <></>
		);
	}
};

// type

const Error = styled.div`
	z-index: 501000;
	inset: 0;
	position: absolute;
	background-color: white;
`;

type TranslationManagerProps = HTMLAttributes<HTMLDivElement> & {
	//
};

export default ContentManager;