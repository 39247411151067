import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { activeScenarioAtom, defaultScenarioId, prespeciedScenarioId } from './atoms/scenario';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { controlsEnabledAtom, threeLoadedAtom, threeLoadingProgressAtom } from './atoms/three';
import Introduction from './Introduction';
import Loading from './Loading';
import ContentManager from './components/managers/ContentManager';
import { scenarioDataAtom } from './atoms/content';
import { activeMessageAtom } from './atoms/messaging';
import Three from './components/Three/Three';
import Interface from './components/interface/Interface';

const Scenario = () => {

	const params = useParams();
	const navigate = useNavigate();

	const [activeScenarioId, setActiveScenarioId] = useAtom(activeScenarioAtom);
	const [threeLoaded,] = useAtom(threeLoadedAtom);
	const [threeLoadingProgress,] = useAtom(threeLoadingProgressAtom);
	const setControlsEnabled = useSetAtom(controlsEnabledAtom);
	const scenarioData = useAtomValue(scenarioDataAtom);
	const setActiveMessage = useSetAtom(activeMessageAtom);

	useEffect(() => {
		determineScenario();
	}, []);

	const determineScenario = () => {
		if (params.scenarioId && !prespeciedScenarioId) {
			setActiveScenarioId(params.scenarioId);
		} else {
			setActiveScenarioId(defaultScenarioId);
			navigate(`/${defaultScenarioId}`, { replace: true });
		}
	};

	const onIntroductionClose = () => {
		if (!scenarioData) return;
		if (scenarioData.starting_message) setActiveMessage(scenarioData.starting_message);
		else setControlsEnabled(true);
	};

	return (
		<>
			{activeScenarioId &&
				<>
					<ContentManager id={activeScenarioId}>
						<Three />
						<Interface />
						{/* render on load complete behind the loader */}
						<Introduction scenarioId={activeScenarioId} visible={threeLoaded} delayInMS={2000} onIntroductionClose={onIntroductionClose} />
						{/* add popup here */}
					</ContentManager>
					<Loading loadingComplete={threeLoaded} progress={threeLoadingProgress} />
				</>
			}
		</>
	);
};

export default Scenario;