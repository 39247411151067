import { PillButton } from '../../buttons/PillButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EllieSays from '../../Ellie/EllieSays';
import styled, { css } from 'styled-components';
import { fadeIn } from '../../../style/animations';
import { CompletionProps } from './Feedback';
import { MdOutlineReplay } from 'react-icons/md';
import { AudioLibrary } from '../../audio/Audio';
import { useEffect } from 'react';

const Summary = (props: CompletionProps) => {

	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		if(props.initialized || !props.endingData.achieved) return;
		AudioLibrary['ellie_happy'].load().play();
	}, []);

	return (
		<>
			<Container $initialized={props.initialized}>
				<EllieSays $emotion={props.endingData.achieved ? 'happy' : 'thinking'}>
					{t(`game.feedback.steps.2.ellie_explains_${props.endingData.achieved ? 'achieved' : 'failed'}`) }
				</EllieSays>
				<Buttons onAnimationEnd={() => props.onInitialized()}>
					<PillButton onClick={() => { navigate('/reset'); }}><MdOutlineReplay />{t('game.feedback.labels.playagain')}</PillButton>
				</Buttons>
				<p style={{ textAlign: 'center' }}>{t('game.feedback.labels.returntoplatform')}</p>
			</Container>
		</>
	);
};

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 20px;
	flex-grow: 1;
`;

const Container = styled.div<{ $initialized: boolean }>`

	height: 100%;
	gap: 20px;
	display: flex;
	flex-direction: column;

	& > * {
		opacity: 0;
		animation: ${fadeIn} 1s forwards;
	}

	& > :nth-child(1){
		animation-delay: 0.5;
	}

	& > :nth-child(2){
		animation-delay: 1s;
	}

	${p => p.$initialized && css`
		& > * {
			animation:none;
			opacity: 1;
		} 
	`}


`;

export default Summary;