import { Euler, Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_43: {[key: string]: SceneData} = {
	'ce2_marc' : {		
		camera_settings: {
			position: new Vector3(-0, 1.60652645, 8.04663389),
			target: new Vector3(0, 1.4, 7),
		},
		objects: {
			'5':{	
				type : 'character', 
				position: new Vector3(0, 0, 7),
			},
		},
		background_characters: [
			{	
				position: new Vector3(-2.5, 0, 6),
				rotation: new Euler(0,-1,0),
				model: 'carlos',
				anim: 'idle_01'
			},
			{	
				position: new Vector3(1.5, 0, 3),
				rotation: new Euler(0,1,0),
				model: 'morgan',
				anim: 'taking_02'
			},
			{	
				position: new Vector3(2.5, 0, 2.7),
				rotation: new Euler(0,-1.5,0),
				model: 'yara',
				anim: 'talking_01'
			}
		]
	},
	'ce2_marc_jackets' : {		
		camera_settings: {
			position: new Vector3(-2.5106248436851275, 1.5879035354959135, 3.141786010531283),
			target: new Vector3(-2.5, 1.4, 4),
		},
		objects: {
			'5':{	
				type : 'character', 
				position: new Vector3(-2.5, 0, 4),
				rotation: [0,Math.PI,0],
			}
		},
		background_characters: [
			{	
				position: new Vector3(-2.8, 0, 5.5),
				rotation: new Euler(0,-1,0),
				model: 'carlos',
				anim: 'idle_01'
			},
		]
	},
	'ce2_marc_desk' : {		
		camera_settings: {
			position: new Vector3(2.51364, 1.503348, -3.211),
			target: new Vector3(0, 1.4, -5),
		},
		objects: {
			'5':{	
				type : 'character', 
				default_mood: 'mad',
				position: new Vector3(1.2, 0, -4.1),
				rotation: [0,1,0],
			}
		},
	}
};

export default scenes_scenario_43;