import { Euler, Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_57: {[key: string]: SceneData} = {
	'fr1_marc' : {		
		camera_settings: {
			position: new Vector3(3.056160796918933, 1.5005433322386412, -2.792722723869409),
			target: new Vector3(0, 1, -7),
		},
		objects: {
			'5':{	
				type : 'character', 
				position: new Vector3(0, 0, -5),
				rotation: [0,Math.PI-0.5,0]
			}
		},
		background_characters: [
			{	
				anim: 'idle_01',
				model: 'morgan',
				position: new Vector3(0, 0, -7),
				rotation: new Euler(0,Math.PI,0)
			},
			{	
				position: new Vector3(1.5, 0, 2.5),
				rotation: new Euler(0,1,0),
				model: 'chris',
				anim: 'taking_02'
			},
			{	
				position: new Vector3(2.5, 0, 2.7),
				rotation: new Euler(0,-1.5,0),
				model: 'carlos',
				anim: 'talking_01'
			},
			{	
				position: new Vector3(-2.5, 0, 5),
				rotation: new Euler(0,-1,0),
				model: 'yara',
				anim: 'idle_01'
			},
		]
	},
	'fr1_emptystore' : {
		camera_settings: {
			position: new Vector3(-0, 1.60652645, 8.04663389),
			target: new Vector3(0, 1.4, 7),
		},
		objects: {
			'5':{	
				type : 'character', 
				position: new Vector3(0, 0, 7),
			},
		},
	},
	
};

export default scenes_scenario_57;