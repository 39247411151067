import { atom } from 'jotai';
import { CameraControlsReference } from '../components/Three/controls/ThreeControls';
import { atomWithStorage } from 'jotai/utils';

export const threeLoadedAtom = atom(false);
/** progress value of loading the three scene (0 - 100) */
export const threeLoadingProgressAtom = atom<number>(0);
export const threeLoadingTargetAtom = atom<string | undefined>(undefined); // determines which scenario we are loading
export const loadingScreenActiveAtom = atom<boolean>(false);

export const controlsAtom = atom<CameraControlsReference | undefined>(undefined);
export const controlsEnabledAtom = atom(false); // use this to determine wether or not the user's input is registered

export const debugCameraAtom = atom(false); // use this to change the restrictions placed on the camera
export const showPerformanceAtom = atomWithStorage('show_performance', false);

//camera
export const cameraProgressAtom = atom(0); // value between 0 and 100

export type CameraPosition = {
	position: THREE.Vector3,
	target: THREE.Vector3,
	azimuthAngle?: number,
	polarAngle?: number,
};