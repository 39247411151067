import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';
import { degToRad } from 'three/src/math/MathUtils.js';

const scenes_scenario_30: {[key: string]: SceneData} = {
	'sv1_office' : {		
		camera_settings: {
			position: new Vector3(-9.25845273, 1.2988865726, 4.51494765),
			target: new Vector3(-13, 1, 4.2),
			maxAzimuthAngle: degToRad(120),
			minAzimuthAngle: degToRad(40),
		},
		objects: {
			'3':{	
				type : 'character', 
				position: new Vector3(-11.6, -0.42, 1.7),
				rotation: [0,0,0],
				default_mood: 'mad',
				sitting: true,
				idle: 'idle_sitting_desk',
				startMessages: ['2488',''],
			},
			'5':{				
				type : 'character', 
				position: new Vector3(-12.5, 0, 7),
				rotation: [0,Math.PI-0.7,0],
				idle: 'idle_body_01',
				default_mood: 'happy',
			},
			'12':{				
				type : 'character', 
				position: new Vector3(-13.05, -0.42, 4.8),
				rotation: [0,0,0],
				idle: 'idle_sitting_desk',
			},
		},
	},
	'sv1_office_talkingtoada' : {		
		camera_settings: {
			position: new Vector3(-10.2308, 1.6290375012, 1.97),
			target: new Vector3(-11.2, 1.4, 1.5),
		},
		objects: {
			'3':{	
				type: 'character',
				position: new Vector3(-11.2, 0, 1.5),
				rotation: [0, -0.5 + Math.PI / 2, 0],
				default_mood: 'mad',
			},
		},
	},
	'sv1_meetingroom' : {
		camera_settings: {
			position: new Vector3(-12.41793627, 1.1965107240, -0.7261336891),
			target: new Vector3(-10.7, 1, -0.65),
			maxAzimuthAngle: degToRad(350),
			minAzimuthAngle: degToRad(210),
		},
		objects: {
			'3': {
				type: 'character',
				position: new Vector3(-10.7, -0.45, -0.65),
				rotation: [0, -Math.PI / 2, 0],
				startMessages: ['1155'],
				sitting: true,
				idle: 'idle_sitting_lap',
			},
		},
	},
};

export default scenes_scenario_30;