import * as THREE from 'three';
import { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './glb/3D_notepad-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    ['3D_office_notepad']: THREE.Mesh
  }
  materials: {
    mat_notepad: THREE.MeshStandardMaterial
  }
};

export default function Notepad({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials } = useGLTF(model) as GLTFResult;
	return (
		<group ref={group} {...props} dispose={null}>
			<mesh name="3D_office_notepad" geometry={nodes['3D_office_notepad'].geometry} material={materials.mat_notepad} />
		</group>
	);
}

useGLTF.preload(model);
