import { useProgress } from '@react-three/drei';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { threeLoadedAtom, threeLoadingProgressAtom } from '../../atoms/three';

const ThreeLoading = () => {

	const { progress } = useProgress();
	const [, setThreeLoaded] = useAtom(threeLoadedAtom);
	const setThreeLoadingProgress = useSetAtom(threeLoadingProgressAtom);

	/** update progress atom and see if it has completed yet */
	useEffect(() => {
		setThreeLoaded(false);

		return () => {
			setThreeLoaded(true);
		};
	}, []);

	useEffect(() => {
		setThreeLoadingProgress((p) => p > progress ? p : progress);
	}, [progress]);

	return (
		<>

		</>
	);
};

export default ThreeLoading;