import styled from 'styled-components';

import thinking from '../assets/images/ellie_thinking_lowres.png';
import neutral from '../assets/images/ellie_thinking_lowres.png';
import happy from '../assets/images/ellie_happy_lowres.png';


import { HTMLAttributes, useMemo } from 'react';

const EllieAvatar = (props: EllieAvatarProps) => {

	const avatar = useMemo(() => {
		if(!props.$emotion) return neutral;
		switch(props.$emotion){
		case 'happy': return happy;
		case 'thinking': return thinking;
		}
	},[props.$emotion]);

	return (  
		<Avatar {...props} $avatar={avatar}/>
	);
};

// sc

const Avatar = styled.div<{$avatar: string}>`

	background-image: url(${p => p.$avatar});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	scale: 1 1;

	aspect-ratio: 1/1;
	height: 200px;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		height: 120px;
	}
`;

// types

interface EllieAvatarProps extends HTMLAttributes<HTMLDivElement> {
	$emotion?: EllieEmotions
}

export type EllieEmotions = 'happy' | 'thinking';
 
export default EllieAvatar;