import styled, { ThemeContext } from 'styled-components';
import {HTMLAttributes, useEffect, useRef, useState} from 'react';
import {FaExclamation} from 'react-icons/fa';
import { PiSmileyBold, PiSmileyMehBold, PiSmileySadBold } from 'react-icons/pi';
import { useContext } from  'react';
import { slideInRight, slideOutRight } from '../../../../style/animations';
import { AudioLibrary } from '../../../audio/Audio';


const ImportantDecisionIndicator = (props: ImportantDecisionIndicatorProps) => {

	const [render, setRender] = useState<boolean>(false);
	
	const themeContext = useContext(ThemeContext);

	const audioTimeout = useRef<NodeJS.Timeout>();

	useEffect(() => {
		if(!props.important) return;
		if(audioTimeout.current) clearTimeout(audioTimeout.current);
		audioTimeout.current = setTimeout(() => {
			AudioLibrary['important_moment'].load().play();
			setRender(true);
		},1000);
	}, [props.important]);

	useEffect(() => {
		return () => {
			if(audioTimeout.current) clearTimeout(audioTimeout.current);
		};
	}, []);

	useEffect(() => {
		if(props.correct === undefined || !props.important) return;

		if(props.correct === 2){
			AudioLibrary['decision_correct'].load().play();
		} else{
			AudioLibrary['decision_incorrect'].load().play();
		}
	}, [props.correct]);

	const onAnimationEnd = () => {
		if(!props.important) setRender(false);
	};

	return ( 
		<>
			{render &&
				<Container $render={props.important} onAnimationEnd={onAnimationEnd}>					
					{props.correct === undefined ? <FaExclamation/> : 
						props.correct === 0 ? <PiSmileySadBold color={themeContext?.colors.danger}/>
							: props.correct === 1 ? <PiSmileyMehBold color={themeContext?.colors.alert}/>
								: <PiSmileyBold color={themeContext?.colors.correct}/>
					}
				</Container>
			}
		</>
	);
};

const Container = styled.div<{$render: boolean}>`
	margin-inline-start: auto;

	height: 70px;
	width: 70px;
	aspect-ratio: 1/1;

	display: flex;

	margin-top: 10px;
	color: ${p => p.theme.colors.primary};
	background-color: ${p => p.theme.colors.neutralLightest};

	border-radius: 50em;

	animation: ${p => p.$render ? slideInRight : slideOutRight} 1s forwards;

	& > svg {
		height: 80%;
		width: 80%;
		margin: auto;
	}
`;

// types

interface ImportantDecisionIndicatorProps extends HTMLAttributes<HTMLDivElement> {
	/**
	 * indicates wether or not the moment is important.
	 */
	important: boolean;
	correct: undefined | number;
}
 
export default ImportantDecisionIndicator;