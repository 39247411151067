import styled from 'styled-components';
import BaseButton from './Button';

export const PillButton = styled(BaseButton)`
	border-radius: 50em;
	max-width: 300px;

	display: flex;
	align-items: center;
	width: fit-content;

	&:focus {
		outline: none;
	}

	& > svg {
		flex-grow: 1;
		margin-inline-end: 10px;
		font-size: 1.5em;
	}
`;