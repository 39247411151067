import styled, { css } from 'styled-components';
import Loader from './components/loading/Loader';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { fadeIn, fadeOut } from './style/animations';

const Loading = ({ progress, loadingComplete }: LoadingProps) => {

	const [loading, setLoading] = useState(true);

	const loadingTimeout = useRef<NodeJS.Timeout>();

	useEffect(() => {
		if (loadingComplete) {
			if (loadingTimeout.current) clearTimeout(loadingTimeout.current);
			loadingTimeout.current = setTimeout(() => setLoading(false), 500);
		}
	}, [loadingComplete]);

	return (
		<Container $loading={loading}>
			<Loader />
			{progress !== undefined && <Bar $progress={progress} />}
		</Container>
	);
};

//sc

const Container = styled.div<{ $loading: boolean }>`
	position: absolute;
	height:100%;
	width: 100%;
	inset-inline-start:0;inset-block-start:0;


	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: ${p => p.theme.colors.neutralLightest};

	transition: all 1.7s ease-in-out;
	transition-delay: 1s;

	${p => !p.$loading && css`
		inset-block-start: 200%;
		& > :nth-child(1) {
			opacity: 0;
		}
	`}

	& > :nth-child(1) {
		transition: all 1s ease-in-out;
	}

`;

const Bar = styled.div<{ $progress: number }>`
	position: relative;
	margin-block-start: 50px;
	height: 10px;
	width: 220px;
	background-color: ${p => p.theme.colors.neutral};

	border-radius: 50em;
	animation: ${p => p.$progress !== 100 ? fadeIn : fadeOut} 1s ease-in-out forwards;

	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		background-color: ${p => p.theme.colors.primary};
		inset-inline-start: 0;
		inset-block-start: 0;
		height: 100%;
		width: ${p => p.$progress}%;
	}
`;

// type

type LoadingProps = HTMLAttributes<HTMLDivElement> & {
	loadingComplete: boolean;
	progress?: number;
};

export default Loading;