import { useAtom, useAtomValue } from 'jotai';
import { controlsAtom, debugCameraAtom } from '../../../atoms/three';
import { Vector3 } from 'three';
import { radToDeg } from 'three/src/math/MathUtils.js';

const DCCamera = () => {

	const controls = useAtomValue(controlsAtom);
	const [debugCamera, setDebugCamera] = useAtom(debugCameraAtom);

	return (
		<>
			<label>debug camera
				<input type={'checkbox'} checked={debugCamera} onChange={(e) => setDebugCamera(e.target.checked)}/>
			</label>
			{controls && <button onClick={() => {
				console.log(
					`%c azimuth angle: ${radToDeg(controls.azimuthAngle)}`, 
					'background-color: cyan; color: black;'
				);
				console.log(
					`%c polar angle: ${radToDeg(controls.polarAngle)}`, 
					'background-color: yellow; color: black;'
				);
			}}
			>
				log camera angles
			</button>}
			<button onClick={() => {
				const pos = new Vector3();
				controls?.getPosition(pos);
				console.log(
					`%c camera position: ${pos.x}, ${pos.y}, ${pos.z}`, 
					'background-color: orange; color: black;'
				);
			}}
			>
				log camera position
			</button>
		</>
	);
};
 
export default DCCamera;