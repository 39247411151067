import { Euler, Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_44: {[key: string]: SceneData} = {
	'ce3_marc' : {		
		camera_settings: {
			position: new Vector3(2.79636359, 1.60549361, -3.314934),
			target: new Vector3(1, 1.4, -3.7),
		},
		objects: {
			'15':{	
				type : 'character', 
				position: new Vector3(1.5, 0, -3.6),
				rotation: [0,(Math.PI/2)-0.2,0],
			},
			'22':{	
				type : 'character', 
				position: new Vector3(2.2, 0, -1.8),
				rotation: [0,Math.PI-0.6,0],
			},

		},
		background_characters: [
			{
				position: new Vector3(-2.5, 0, 4),
				rotation: new Euler(0,-1,0),
				model: 'yara',
				anim: 'idle_01'
			},
			{	
				position: new Vector3(1.5, 0, 3),
				rotation: new Euler(0,1,0),
				model: 'morgan',
				anim: 'taking_02'
			},
			{	
				position: new Vector3(2.5, 0, 2.7),
				rotation: new Euler(0,-1.5,0),
				model: 'carlos',
				anim: 'talking_01'
			}
		]
	}
};

export default scenes_scenario_44;