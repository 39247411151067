import { Billboard, Plane, useCursor } from '@react-three/drei';
import { GroupProps, useLoader } from '@react-three/fiber';
import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { TextureLoader, Vector3 } from 'three';
import { SceneObjectsType, objectsStatesAtom } from '../../../atoms/scene';
import { useAtom } from 'jotai';

// assets

import item_indicator_image from '../../../assets/images/inspect.png';
import character_indicator_image from '../../../assets/images/chat_indicator.png';
import waypoint_indicator_image from '../../../assets/images/waypoint_indicator.png';
import backdrop_indicator_image from '../../../assets/images/backdrop_indicator.png';
import { ThemeContext } from 'styled-components';

const InteractiveSceneObject : FC<InteractiveSceneObjectProps> = (props) => {

	const [sceneObjectStates,] = useAtom(objectsStatesAtom);

	// manipulates cursor states
	const [hovered, setHovered] = useState<boolean>(false);
	useCursor(hovered);

	// materials
	const [itemIndicatorMaterial, characterIndicatorMaterial, waypointIndicatorMaterial, backdropIndicatorMaterial] = useLoader(TextureLoader,[
		item_indicator_image,
		character_indicator_image,
		waypoint_indicator_image,
		backdrop_indicator_image
	]);

	useEffect(() => {
		if(!props.interactable && hovered) setHovered(false);
	}, [props.interactable]);

	const onHoverChange = (hover: boolean) => {
		if(!props.interactable) return;
		setHovered(hover);
	};

	const visible = useMemo<boolean>(() => {	
		if(sceneObjectStates[props.objectId]) {		
			return sceneObjectStates[props.objectId].visible;
		} else {
			return false;
		}
	} ,[sceneObjectStates]);

	const type = useMemo(() => {
		return props.data.type;
	},[props.data]);

	const indicatorMaterial = useMemo(() => {
		switch(type){
		case 'character':
			return characterIndicatorMaterial;
		case 'item':
			return itemIndicatorMaterial;
		case 'waypoint':
			return waypointIndicatorMaterial;
		}
	},[type]);

	const position = useMemo(() => {
		const newPos = new Vector3(0,0,0);
		switch(props.data.type){
		case 'character':
			newPos.copy(new Vector3(0,2.1,0));
			break;
		case 'item':
			if(props.data.indicatorOffset){
				newPos.copy(new Vector3(0 + props.data.indicatorOffset.x, 0 + props.data.indicatorOffset.y, 0 + props.data.indicatorOffset.z));
			} else{
				newPos.copy(new Vector3(0,0.1,0));
			}
			break;
		}
		return newPos;
	},[type, props.data]);

	const scale = useMemo(() => {
		switch(type){
		case 'character':
			return props.data.indicatorScale ??  0.4;
		case 'item':
			return props.data.indicatorScale ?? 0.1;
		default: return 0.5;
		}
	},[type, props.data]);

	const themeContext = useContext(ThemeContext);

	return ( 		
		<group
			{...props}
			onPointerOver={() => onHoverChange(true)}
			onPointerOut={() => onHoverChange(false)}
			visible={visible}
		>
			<Billboard
				visible={props.interactable}
				follow
				position={position}
				scale={scale}
			>
				<Plane renderOrder={2}>
					<meshBasicMaterial
						map={backdropIndicatorMaterial}
						transparent
						// depthWrite={false}
						// depthTest={false}
						color={!hovered ? themeContext?.colors.primary : themeContext?.colors.primaryDarker}
					/>
					<Plane renderOrder={3} scale={0.55}>
						<meshBasicMaterial
							map={indicatorMaterial}
							transparent
							// depthWrite={false}
							// depthTest={false}
							color={!hovered ? themeContext?.colors.neutralLightest : themeContext?.colors.neutralLight}
						/>
					</Plane>
				</Plane>
			</Billboard>
			{props.children}
		</group>
	);
};

// types

interface InteractiveSceneObjectProps extends GroupProps {
	interactable?: boolean;
	data: SceneObjectsType
	objectId: string
}
 
export default InteractiveSceneObject;