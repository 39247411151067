import { BackgroundCharacter as BackgroundCharacterType } from '../../../../../atoms/scene';
import BackgroundCharacter from './BackgroundCharacter';

const BackgroundCharacters = ({characters}: {characters: BackgroundCharacterType[]}) => {

	return (<>
		{characters.map((values,index) => {

			return (
				<BackgroundCharacter key={`char-${index}`} {...values}/>
			);
		})}
	</>);


};
 
export default BackgroundCharacters;