import { useEffect, useMemo, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import styled, { css } from 'styled-components';
import { BackgroundFadeIn } from '../../../style/animations';
import { AARContainer } from '@tqwi/aarc';
import { GemDirectQueueAtom, GemEventQueueEntry, GemQueueAtom, handleGemQueueAtom } from '../../../atoms/gem';
import { enableFifoAtom } from '../../../atoms/fifo';
import { gameResultAtom } from '../../../atoms/gameResults';
import { activeScenarioAtom } from '../../../atoms/scenario';
import { activeMessageAtom, resetingStateAtom, seenMessagesAtom, talkingToAtom } from '../../../atoms/messaging';
import {ContextMessage as ContextMessageType, GameEvent, Message as MessageType, StandardMessage as StandardMessageType, scenarioDataAtom } from '../../../atoms/content';
import ContextMessage from './ContextMessage/ContextMessage';
import { activeSceneAtom, activeWaypointAtom } from '../../../atoms/scene';
import { Vector3 } from 'three';
import { CameraPosition, controlsAtom, controlsEnabledAtom } from '../../../atoms/three';
import { sceneList } from '../../../content/scenes/scene_list';
import { messageTreeCameraPositions } from '../../../content/camerapositions/messageTreeCameraPositions';
import StandardMessage from './StandardMessage/StandardMessage';

const Message = () => {

	const activeScenario = useAtomValue(activeScenarioAtom);
	const [currentScenarioData,] = useAtom(scenarioDataAtom);

	//#region Required Data for component
	const [activeMessageId, setActiveMessage] = useAtom(activeMessageAtom);
	const [seenMessages, setSeenMessages] = useAtom(seenMessagesAtom);
	const [payloadMessage, setPayloadMessage] = useState('');
	const setHandleGemQueue = useSetAtom(handleGemQueueAtom);
	const [resetingState, setResetingState] = useAtom(resetingStateAtom);
	const [, setGemDirectQueue] = useAtom(GemDirectQueueAtom);
	const [, GemQueueDispatch] = useAtom(GemQueueAtom);
	const [ending, setEnding] = useState<string | undefined>();
	const setEnableFifo = useSetAtom(enableFifoAtom);
	const [useFifo, setUseFifo] = useState<boolean | undefined>(undefined);
	const [activeScenarioId,] = useAtom(activeScenarioAtom);
	const [activeWaypoint,] = useAtom(activeWaypointAtom);
	const [activeScene,] = useAtom(activeSceneAtom);
	const [, setTalkingTo] = useAtom(talkingToAtom);
	const [, setControlsEnabled] = useAtom(controlsEnabledAtom);
	const [activatedOnce, setActivatedOnce] = useState<boolean>(false);
	const [lastPosition, setLastPosition] = useState<{ position: Vector3 }>({ position: new Vector3 });
	const [transisioned, setTransisioned] = useState<boolean>(false);
	const controls = useAtomValue(controlsAtom);

	const [gameResults, gameResultDispatch] = useAtom(gameResultAtom);

	/** Data of the message that is currently shown. This value is updated when activeMessageId changes */
	const currentMessageData = useMemo<MessageType<StandardMessageType | ContextMessageType> | undefined>(() => {
		if (!activeMessageId || !currentScenarioData || !currentScenarioData.messages || !currentScenarioData.messages[activeMessageId]) return undefined;

		return currentScenarioData.messages[activeMessageId];
	}, [activeMessageId]);

	//#endregion

	//#region useEffects

	//on message change
	useEffect(() => {
		if (!currentMessageData && resetingState != 1) {
			setControlsEnabled(true);
			setTalkingTo('');
			transitionCamera(false, undefined);
		} else {
			if (!activatedOnce && !currentMessageData) return;
			setControlsEnabled(false);
			transitionCamera(true, messageTreeCameraPositions[activeMessageId]);
		}
		
		//store message as seen
		if (activeMessageId && !seenMessages.includes(activeMessageId)) setSeenMessages((e) => [...e, activeMessageId]);
	}, [activeMessageId]);

	//reseting
	useEffect(() => {
		switch (resetingState) {
		case 1:
			setActiveMessage('');
			setEnableFifo(true);
			break;
		case 2:
			gameResults.choices.length != 0 && gameResultDispatch({ type: 'store-reset' });
			setActiveMessage(payloadMessage);
			break;
		}
	}, [resetingState]);

	//#endregion

	//#region camera change

	const transitionCamera = async (messageTreeActive: boolean, newValues?: CameraPosition) => {
		if (!activatedOnce) setActivatedOnce(true);
		if (!controls) return;
		//go back
		if (!messageTreeActive && transisioned) {//only go back when there has been a transition with camera
			const sceneData = activeScene && activeScenarioId && sceneList[activeScenarioId][activeScene];
			if (!sceneData) return;

			const target = activeWaypoint && sceneData.positions ? sceneData.positions[activeWaypoint].target : sceneData.camera_settings.target;
			controls.setTarget(target.x, target.y, target.z, true);
			await controls.setPosition(lastPosition.position.x, lastPosition.position.y, lastPosition.position.z, true);

			setTransisioned(false);
		}
		//transition to character
		else if (newValues && !transisioned) {

			//store last known position
			const pos = new Vector3();
			controls?.getPosition(pos);
			setLastPosition({ position: pos });

			// set target to focus
			const targetPos = newValues.target;
			const cameraPos = newValues.position;
			// controls.rotateTo(newValues.azimuthAngle, newValues.polarAngle, true);
			controls.setTarget(targetPos.x, targetPos.y, targetPos.z, true);
			await controls.setPosition(cameraPos.x, cameraPos.y, cameraPos.z, true);
			setTransisioned(true);
		}
	};


	//#endregion

	// #region events

	useEffect(() => {
		checkEvents();
		checkEnding();
	}, [currentMessageData]);

	const checkEvents = async () => {

		if (!currentMessageData) return;

		const events = currentMessageData.events;

		addRandomAnimationEvent(events);
		setGemDirectQueue(events.filter(event => event.direct === true));
		processEvents(events.filter(event => !event.direct));
	};

	const checkEnding = async () => {
		if (!currentMessageData) return;
		if (currentMessageData.ending) setEnding(currentMessageData.ending);
	};

	const addRandomAnimationEvent = (events: GameEvent[]) => {
		if (currentMessageData?.message_type === 0 && currentMessageData.speaking_character != -1 && !events.find((e) => e.type === 'set-character-animation')) {
			const both = { direct: true, object_id: currentMessageData.speaking_character.toString(), fifo: false };
			const options = ['talking_01', 'talking_02', 'talking_03'];
			const random = options[Math.floor(Math.random() * options.length)];
			events.push({ ...both, type: 'set-character-animation', animation: random });
		}
	};

	const processEvents = async (events: GemEventQueueEntry[]) => {
		events.map((event) => {//for every action in the data
			if (event.fifo) setUseFifo(true);
			GemQueueDispatch({ type: 'add', payload: event });
		});
	};

	//#endregion

	//#region methods

	// triggered when trying to get the next message.
	const onContinue = (index?: number) => {
		if (!currentMessageData) return;

		let followUp: string | undefined;

		// check if there is next message when there are no answer options
		if (index === undefined && currentMessageData.follow_up_message)
			followUp = currentMessageData.follow_up_message;
		// check if tehre is next message connected to this answer option
		if (index !== undefined && currentMessageData.message_type === 0 && currentMessageData.answers[index].follow_up_message)
			followUp = currentMessageData.answers[index].follow_up_message;

		if (followUp) {//check if there is a next message
			//check for reset
			if (currentScenarioData && currentMessageData.message_type === 1 && currentMessageData.reset === true && currentScenarioData.messages[followUp].message_type != 1) {
				setPayloadMessage(followUp);
				setResetingState(1);
				return;
			}
			setActiveMessage(followUp);
		} else {
			onEnd();
		}
	};

	const onEnd = () => {
		setActiveMessage('');
		// FIFO or direct handling queue
		useFifo ? setEnableFifo(true) : setHandleGemQueue(true);
		setUseFifo(false);
		if (ending && activeScenario) {
			gameResultDispatch({ type: 'set-ending', ending_id: ending });
			//store this attempt if user is on scorm
			gameResultDispatch({ type: 'store-attempt', scenario: activeScenario });
		}
	};

	//#endregion
	if (currentMessageData)
		return (
			<Container 
				onClick={currentMessageData.message_type === 1 ? () => onContinue() : () => {}}
				$overlay={currentMessageData.message_type === 1}
			>
				<AARContainer
					$fitparent
					$aspectratio='1/1'
					$verticalalignment='bottom'
					$fontSize={50}
					$originalresolution={[1920, 1080]}
					style={{ direction: 'ltr' }}
					$outerstyle={{
						direction: 'ltr',
					}}
				>
					{currentMessageData.message_type === 1 ?
						<ContextMessage
							messageData={currentMessageData}
						/>
						:
						<StandardMessage
							messageData={currentMessageData}
							onContinue={onContinue}
						/>
					}
				</AARContainer>
			</Container>
		);
	else
		return <></>;
};

// styled components
const Container = styled.div<{ $overlay: boolean}>`
	position: absolute;
	inset:0;
	pointer-events: all;
	cursor: ${p => p.$overlay === true ? 'pointer' : ''};

		background: none;
		background-color: none;

	${p => p.$overlay === true && css`
		animation: ${BackgroundFadeIn} .5s forwards;
	`}
`;


export default Message;