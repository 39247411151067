import { useEffect, useState } from 'react';

// icons
import {AiOutlineFullscreen, AiOutlineFullscreenExit} from 'react-icons/ai';
import IconButton from '../buttons/IconButton';

const FullscreenToggle = (props: React.HTMLProps<HTMLButtonElement>) => {

	const [fullscreen, setFullscreen] = useState<boolean>(false);

	const toggle = () => {
		if(!document.fullscreenElement){ 
			document.body.requestFullscreen();
		}
		else{
			document.exitFullscreen();
		}
	};

	const onFullscreenChange = () => {
		setFullscreen(document.fullscreenElement ? true : false);
	};

	useEffect(() => {
		document.addEventListener('fullscreenchange', onFullscreenChange);
		return () => {
			document.removeEventListener('fullscreenchange', onFullscreenChange);
		};
	},[]);


	return (  
		<IconButton tabIndex={props.tabIndex} $selected={fullscreen} onClick={toggle} >
			{!fullscreen ? <AiOutlineFullscreen/> : <AiOutlineFullscreenExit/> }
		</IconButton>
	);
};
 
export default FullscreenToggle;