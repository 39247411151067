import styled from 'styled-components';
import { keyframes } from 'styled-components';
import logo from '../../assets/images/tqw_logo_lowres.png';
import { HTMLAttributes } from 'react';

const Loader = (props: LoaderProps) => {
	return ( <_Loader {...props}><div/></_Loader> );
};

// styled components

const loading = keyframes`
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
`;

const _Loader = styled.div<LoaderProps>`
    width: 100px;
    height: 100px;
    & > div {
        width: 100%;
        height: 100%;
        background-image: url(${logo});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        animation: ${loading} 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    }
`;

// type

type LoaderProps = HTMLAttributes<HTMLDivElement> & {
	loaderState?: 'start' | 'loading' | 'end'
};
 
export default Loader;