import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;

export const fadeOut = keyframes`
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
`;

export const slideInLeft = keyframes`
    0%{
        transform: translateX(-500%);
    }
    100%{
        transform: translateX(0);
    }
`;

export const slideOutRight = keyframes`
    100%{
		translate: 110% 0;
		opacity: 0;
    }
    0%{
		translate: 0 0;
		opacity: 1;
    }
`;

export const slideOutTop = keyframes`
    0%{
		translate: 0 0;
		opacity: 1;
    }
    100%{
		translate: 0 -110%;
		opacity: 0;
    }
`;

export const slideInTop = keyframes`
    0%{
		translate: 0 -110%;
		opacity: 0;
    }
    100%{
		translate: 0 0;
		opacity: 1;
    }
`;

export const slideInRight = keyframes`
    0%{
		translate: 110% 0;
		opacity: 0;
    }
    100%{
		translate: 0 0;
		opacity: 1;
    }
`;

export const pulse = keyframes`
    0%{
        opacity: 1;
    }
    50%{
        opacity: .5;
    }
    100%{
        opacity: 1;
    }
`;

export const slideInBottom = keyframes`
    0%{
		translate: 0 110%;
		opacity: 0;
    }
    100%{
		translate: 0 0;
		opacity: 1;
    }
`;

export const BackgroundFadeIn = keyframes`
	0% {
		background-color:  rgba(0,0,0,0)
	}
	100% {
		background-color:  rgba(0,0,0,0.5)
	}
`;