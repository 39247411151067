import { useAtomValue } from 'jotai';
import {  useContext, useEffect, useMemo } from 'react';
import styled, { ThemeContext, css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import EllieSays from '../../Ellie/EllieSays';
import ReactMarkdown from 'react-markdown';
import { Smiley } from '../smileys/Smileys';
import { fadeIn } from '../../../style/animations';
import { CompletionProps } from './Feedback';
import { gameResultAtom } from '../../../atoms/gameResults';
import pipwerks from 'pipwerks-scorm-api-wrapper';
import { AudioLibrary } from '../../audio/Audio';
import { activeScenarioAtom } from '../../../atoms/scenario';
import Plausible from 'plausible-tracker';
import i18n from '../../../tools/i18n';

const Completion = (props: CompletionProps) => {

	const gameResult = useAtomValue(gameResultAtom);
	const scenarioId = useAtomValue(activeScenarioAtom);
	const { trackEvent } = Plausible();

	const {t} = useTranslation();
	const themeContext = useContext(ThemeContext);

	const complete = () => {	
		pipwerks.SCORM.set('cmi.completion_status', 'completed');
		pipwerks.SCORM.save();
	};

	//check if player reached an end that completes the scenario
	const achieved = useMemo<boolean>(() => {
		scenarioId && trackEvent('end scenario',{props:{scenario: scenarioId, achieved: props.endingData.achieved}}, {domain: 'product2.tqwi.nl'});
		//get value that has been given by local data
		if(props.endingData.achieved === true){
			complete();
			return true;
		} else {
			return false;
		}
	},[props.endingData]);

	useEffect(() => {
		if(props.initialized || ! achieved) return;
		setTimeout(() => {
			AudioLibrary['scenario_completed'].load().play();
		}, 1500);
	}, []);

	const ellieDescription = useMemo(() => {
		const customEllieDescription = (i18n.exists(`ending.${gameResult.ending_id}.ellie_description`)) ? t(`ending.${gameResult.ending_id}.ellie_description`) : null;

		if(customEllieDescription) return customEllieDescription;
		else return t(`game.feedback.steps.0.scenario${achieved ? 'achieved' : 'failed'}`);
	},[t]);	

	return (
		<>
			<h2 style={{textAlign: 'center', color: achieved ? themeContext?.colors.correct : themeContext?.colors.danger}}>
				{t(achieved ? 'game.feedback.labels.achieved' : 'game.feedback.labels.failed')}
			</h2>

			{gameResult.ending_id != undefined && <EndText $initialized={props.initialized}>
				{t(`ending.${gameResult.ending_id}.description`)}
			</EndText>}

			<CountGrid $initialized={props.initialized}>
				<div><Smiley $variant='happy'/>{gameResult.correct}</div> 
				<div><Smiley $variant='neutral'/>{gameResult.neutral}</div> 
				<div><Smiley $variant='sad'/>{gameResult.incorrect}</div> 
			</CountGrid>

			<StyledEllieSays 
				onAnimationEnd={() => props.onInitialized()} 
				$initialized={props.initialized} 
				$emotion={achieved ? 'happy' : 'thinking'}>
				<ReactMarkdown>{ellieDescription}</ReactMarkdown>
			</StyledEllieSays>
		</>
	);
};

//sc

const EndText = styled.div<{$initialized: boolean}>`
	text-align: center;
	opacity: 0;
	animation: ${fadeIn} 1s forwards;
	animation-delay: 2.5s;

	${p => p.$initialized && css`
		animation:none;
		opacity: 1;
	`}
`;

const StyledEllieSays = styled(EllieSays)<{$initialized: boolean}>`
	opacity: 0;
	animation: ${fadeIn} 1s forwards;
	animation-delay: 5.5s;

	${p => p.$initialized && css`
		animation:none;
		opacity: 1;
	`}
	
`;

const CountGrid = styled.div<{$initialized: boolean}>`
	display: flex;
	width: 100%;

	align-items: center;
	justify-content: space-around;

	font-size: 2em;
	margin-block: 30px;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		flex-direction: column;
	}

	& > div > div > svg {
		height: 100%;
		width: 100%;
	}

	& > div > div {
		height: 50px;
		width: 50px;
	}

	& > p {
		margin: 0;
	}

	& > div {
		display: flex;
		gap: 10px;
		font-weight: 100;
		align-items: center;
		margin: 0;
		opacity: 0;
		animation: ${fadeIn} 1s forwards;
		& > span {
			font-weight: 600;
			color: ${p => p.theme.colors.correct};
		}
	}

	& > :nth-child(1){
		animation-delay: 3.5s;
	}

	& > :nth-child(2){
		animation-delay: 4s;
	}

	& > :nth-child(3){
		animation-delay: 4.5s;
	}

	${p => p.$initialized && css`
		& > div{
			animation:none;
			opacity: 1;
		}
	`}
`;
 
export default Completion;