import { useTranslation } from 'react-i18next';
import { Box } from '@react-three/drei';
import Markdown from 'react-markdown';
import EllieSays from './components/Ellie/EllieSays';
import { PillButton } from './components/buttons/PillButton';
import { useEffect, useRef, useState } from 'react';
import PopoverModal from './components/containers/PopoverModal';
import Plausible from 'plausible-tracker';

const Introduction = ({scenarioId, delayInMS, visible, onIntroductionClose}: IntroductionProps) => {

	const {t} = useTranslation();	
	const [open, setOpen] = useState(false);
	const { trackEvent } = Plausible();

	const delayTimeout = useRef<NodeJS.Timeout>();

	useEffect(() => {
		if(delayTimeout.current) clearTimeout(delayTimeout.current);
		if(delayInMS && visible){
			delayTimeout.current = setTimeout(() => setOpen(true), delayInMS);
		} 
	},[visible, delayInMS]);

	const onClose = () => {
		trackEvent('start scenario',{props:{scenario: scenarioId}}, {domain: 'product2.tqwi.nl'});
		onIntroductionClose();
		setOpen(false);
	};

	
	return ( 
		<> 
			{ visible && open &&
				<PopoverModal title={t(`scenario.${scenarioId}.name`)} $scrollable>
					<Box>
						<Markdown className='markdown'>
							{t(`scenario.${scenarioId}.description`)}	
						</Markdown>
					</Box>
					<EllieSays>
						{t(`scenario.${scenarioId}.ellie_description`)}
					</EllieSays>
					<PillButton style={{margin: 'auto'}} onClick={onClose}>{t('pages.selection.scenario_selected.start_button')}</PillButton>
				</PopoverModal>
			}
		</>
	);
};

// types

type IntroductionProps = {
	scenarioId: string;
	delayInMS?: number;
	visible: boolean;
	onIntroductionClose: () => void;
};
 
export default Introduction;