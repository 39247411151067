import { atom } from 'jotai';

export const prespeciedScenarioId = document.getElementById('root')?.getAttribute('scenarioId');
export const defaultScenarioId =  prespeciedScenarioId ?? '10';

// active scenario is determined by the following ways (order determines priority)
// 1. the prespecifiedScenarioId variable, which is set by adding a 'scenarioId' value to the root div.
// 2. parameter given behind the first / in the routing.
// 3. the defaultScenarioId variable.
//
// activeScenarioAtom is set by an useEffect in the Game.tsx component.

export const activeScenarioAtom = atom<string | undefined>(undefined);


