import { useEffect } from 'react';
import { GemDirectQueueAtom, GemEventQueueEntry, GemQueueAtom, handleGemQueueAtom} from '../../../atoms/gem';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { activeSceneAtom, objectsStatesAtom } from '../../../atoms/scene';
import { activeMessageAtom} from '../../../atoms/messaging';
import { controlsAtom } from '../../../atoms/three';
import cameraPositions from '../../../content/camerapositions/cameraPositions';
import { fifoDelay } from '../../../atoms/fifo';
import { AudioLibrary } from '../../audio/Audio';

/**
 * The Global Event Manager (GEM for short) is a component that tracks changes in the gemQueueAtom and handles changes from said queue.
 * @returns 
 */
const GlobalEventManager = () => {

	const [directQueue, setDirectQueue] = useAtom(GemDirectQueueAtom);
	const [queue, dispatch] = useAtom(GemQueueAtom);

	const [handleGemQueue, setHandleGemQueue] = useAtom(handleGemQueueAtom);

	useEffect(() => {
		if(!handleGemQueue) return;
		handleQueue();
	},[handleGemQueue]);

	useEffect(() => {
		if(!directQueue || directQueue.length === 0) return;
		handleDirectQueue();
	},[directQueue]);

	const handleDirectQueue = () => {
		directQueue.map((event) => {
			handleEvent(event);
		});
		setDirectQueue([]);
	};

	const handleQueue = () => {
		const fifoIsUsed = queue.find((event) => event.fifo === true);
		if(fifoIsUsed){
			queue.map((event) => {
				if(event.fifo){	//if fifo is used execute events with fifo when fading 
					handleEvent(event);
				}else{ //execute when fifo is over
					setTimeout(() => {
						handleEvent(event);
					}, fifoDelay);
				}
			});
		} else { //if there are no fifos just execute directly
			queue.map((event) => {
				handleEvent(event);
			});
		}
		dispatch({type: 'clear'});
		setHandleGemQueue(false);
	};

	//#region handling events

	//#region required variables for handling events
	const objectsStatesDispatch = useSetAtom(objectsStatesAtom);
	const setMessage = useSetAtom(activeMessageAtom);
	const [, setActiveScene] = useAtom(activeSceneAtom);
	const controls = useAtomValue(controlsAtom);

	//#endregion

	const handleEvent = async (event: GemEventQueueEntry) => {

		switch(event.type){
		case 'set-message':
			setMessage(event.message_id);
			break;
		case 'change-scene':
			setActiveScene(event.scene_id);
			break;
		case 'set-object-visibility':{
			objectsStatesDispatch({
				type: 'set-object-visibility',
				object_id: event.object_id,
				visible: event.visible
			});
		} break;
		case 'set-object-state': {
			objectsStatesDispatch({
				type: 'set-object-state',
				object_id: event.object_id,
				state: event.state
			});
		} break;
		case 'set-character-animation': {
			if(event.animation != 'none') objectsStatesDispatch({
				type: 'set-character-animation',
				object_id: event.object_id,
				animation: event.animation
			});
		} break;
		case 'set-character-mood': {
			objectsStatesDispatch({
				type: 'set-character-mood',
				object_id: event.object_id,
				mood: event.mood
			});
		} break;
		case 'play-sound': {
			AudioLibrary[event.sound] && AudioLibrary[event.sound].load().play();
		} break;
		case 'set-camera-position': {
			if(!controls) return;
			const target = cameraPositions[event.camera_position_id].target;
			controls.setTarget(target.x, target.y, target.z, true);
			await controls.setPosition(cameraPositions[event.camera_position_id].position.x, cameraPositions[event.camera_position_id].position.y, cameraPositions[event.camera_position_id].position.z, true);
		} break;
		}
	};
	

	//#endregion

	return (<></>);
};
 
export default GlobalEventManager;