import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './knife_animated-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    Blade: THREE.SkinnedMesh
    Bone: THREE.Bone
  }
  materials: {
    ['Tactical Knife']: THREE.MeshStandardMaterial
  }
  animations: GLTFAction[]
};

type ActionName = 'robberknife_threatening_01' | 'robberknife_threatening_02' | 'robberknife_threatening_04' | 'robberknife_threatening_idle';
interface GLTFAction extends THREE.AnimationClip {
  name: ActionName
}

export default function Model(props: JSX.IntrinsicElements['group'] & {animation: string}) {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF(model) as GLTFResult;
	const { actions, mixer } = useAnimations(animations, group) as unknown as {actions: {[key in ActionName]: THREE.AnimationAction}, mixer: THREE.AnimationMixer};
	const [visible, setVisible] = useState(false);

	const options: {[key: string] : ActionName} = {
		'jet_robber_01': 'robberknife_threatening_01',
		'jet_robber_02':'robberknife_threatening_02',
		'jet_robber_03':'robberknife_threatening_02',
		'jet_robber_04':'robberknife_threatening_04',

	};

	
	const idles: {[key: string] : ActionName} = {
		'robberknife_threatening_01': 'robberknife_threatening_idle',
		'robberknife_threatening_02': 'robberknife_threatening_idle',
		'robberknife_threatening_04': 'robberknife_threatening_idle',

	};
	
	useEffect(() => {
		if(options[props.animation]){	
			Object.values(actions).map((a) => a.stop().reset());
			setVisible(true);
			actions[options[props.animation]].reset().play().setLoop(THREE.LoopOnce, 1).clampWhenFinished = true;
		} 

		mixer.addEventListener('finished', (e) => {
			if(actions[idles[e.action.getClip().name]]){
				actions[idles[e.action.getClip().name]].play();
			}
			return;
		});

	}, [props.animation]);
  
	return (
		<group visible={visible} ref={group} {...props} dispose={null}>
			<group name="Scene">
				<group name="knife_armature" position={[0, -0.002, 0] } scale={0.427}>
					<primitive object={nodes.Bone} />
					<skinnedMesh name="Blade" geometry={nodes.Blade.geometry} material={materials['Tactical Knife']} skeleton={nodes.Blade.skeleton} />
				</group>
			</group>
		</group>
	);
}

useGLTF.preload(model);