import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './glb/kitchen_mug-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    kitchen_mug: THREE.Mesh
  }
  materials: {
    ['mug texture']: THREE.MeshStandardMaterial
  }
};

export default function KitchenMug(props: JSX.IntrinsicElements['group']) {
	const { nodes, materials } = useGLTF(model) as GLTFResult;
	return (
		<group {...props} dispose={null}>
			<mesh name="kitchen_mug" geometry={nodes.kitchen_mug.geometry} material={materials['mug texture']} rotation={[0, -1, 0]} />
		</group>
	);
}

useGLTF.preload(model);
