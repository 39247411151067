import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_22 : {[key: string]: SceneData} = {
	'sj3_s1' : 	{	
		camera_settings: {
			position: new Vector3(0,1.5,0),
			target: new Vector3(0,1.5, 0.1)
		},
	
		objects: {
			'15':{	
				type : 'drunkcharacter', 
				props: true,
				position: new Vector3(2,0,4.5),
				rotation: [0,Math.PI,0],
				startMessages: ['1500',''],
				start_sound: 'background_party'
			},
			'bottles1':{
				type:'item',
				mesh: 'bottle',
				position: new Vector3(1.2, 1.95, 4.9),
			},
			'bottles2':{
				type:'item',
				mesh: 'bottle',
				position: new Vector3(2.57, 1.4, 7),
				rotation: [0,0.5,0]
			},
			'crate':{
				type:'item',
				mesh: 'crate',
				position: new Vector3(1.3, 0.5, 5.2),
			}

		}
	},
};

export default scenes_scenario_22;