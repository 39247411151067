import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

const BaseButton = styled.button<BaseButtonProps>`

	position: relative;

	border: none;
	outline: none;

	background-color: ${p => p.theme.colors.primary};
	color: ${p => p.theme.colors.neutralLightest};

	padding-block: .65em;
	padding-inline: 3em;

	cursor: pointer;

	&:disabled {
		pointer-events: none;
		background-color: ${p => p.theme.colors.neutralDarker};
		color: ${p => p.theme.colors.neutral};
	}

	&:hover:before {
		content: '';
		position: absolute; inset:0;
		background-color: rgba(0,0,0,0.1);
		border-radius: inherit;
	}

	${p => p.$visible !== undefined && css`
		visibility: ${p.$visible ? 'visible' : 'hidden'};
		pointer-events: ${p.$visible ? 'all' : 'none'};
	`}

	${p => p.$interactive !== undefined && css`
		pointer-events: ${p.$interactive ? 'all' : 'none'}!important;
	`}

	${p => (p.$selectable) ? (p.$selected ? css`
		background-color: ${p => p.theme.colors.primary};
		` : css`
		background-color: ${p => p.theme.colors.neutral};
		`): ''}
`;

export interface BaseButtonProps extends HTMLAttributes<HTMLButtonElement> {
	$visible?:boolean
	$interactive?:boolean;
	$selectable?: boolean;
	$selected?: boolean;
}
 
export default BaseButton;