import styled from 'styled-components';

export const Modal = styled.div`

	background-color: ${p => p.theme.colors.neutralLightest};
	border-radius: ${p =>p.theme.properties.borderRadius};

	overflow: hidden;
	max-width: 900px;
	margin-inline: auto;

	display: block;
	padding: ${p => p.theme.responsive.whitespace.lg}px;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		padding: ${p => p.theme.responsive.whitespace.sm}px;
	}

	& > h1, h2 {
		margin-bottom: .5em;
	}

	& > p {
		margin-bottom: 2em;
	}

	& > :first-child {
		margin-top: 0;
	}

	& > :last-child {
		margin-bottom: 0;
	}
	
`;