import { useAtom } from 'jotai';
import { activeMessageAtom, resetingStateAtom } from '../../../atoms/messaging';
import { scenarioDataAtom } from '../../../atoms/content';

const DCMessaging = () => {

	const [activeMessage, setActiveMessage] = useAtom(activeMessageAtom);
	const [resetingState,] = useAtom(resetingStateAtom);

	const [currentScenarioData] = useAtom(scenarioDataAtom);

	return(
		<>
			{resetingState}
			{currentScenarioData && <div>
				<label>message: </label>
				<select onChange={(e) => setActiveMessage(e.currentTarget.value)} value={activeMessage}>
					<option value={''}>none</option>
					{currentScenarioData.messages && Object.entries(currentScenarioData.messages).map((message, index) => {
						return <option key={index} value={message[0]}>{message[1].message_type === 1 && 'context: '}{message[0]}</option>;
					})}
				</select>
			</div>}
		</>
	);
};
 
export default DCMessaging;