import { atom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';
import { reducer } from '../components/Three/scene/sceneReducer';
import { CameraSettings } from '../components/Three/controls/ThreeControls';
import { Expression } from '../components/Three/models/characters/Human/Human';
import { itemList } from '../components/Three/models/objects/itemList';
import { Euler, Vector3 } from 'three';
import { ActionName, Models } from '../components/Three/models/characters/BackgroundCharacters/BackgroundCharacter';

// determines which scene is active
export const activeSceneAtom = atom<string | undefined>(undefined);

export const activeItemIdAtom = atom<string | undefined>(undefined);

export const objectsStatesAtom = atomWithReducer({}, reducer);

export const activeWaypointAtom = atom<string | undefined>(undefined);

export type SceneData = {
	objects: {[key: string]: SceneObjectsType},
	camera_settings: CameraSettings
	positions?: {[key: string]: ScenePosition}
	background_characters?: BackgroundCharacter[],
	startingPosition?: string;
	room?: string;
};

/** An object containing data for a scene position */
export type ScenePosition = {
	position: Vector3, 
	target: Vector3
};

export type SceneObjectsType = SceneObjectType<(Item | Character | Waypoint)>;

export type SceneObjectType<T> = {
	props?: boolean
	position?: Vector3, 
	rotation?: number[],
	indicatorScale?: number
	visible?: boolean
	messages?: string[] // list of keys of messages that this object opens per state (not in use yet for items)
} & T;

export type Item = {
	type: 'item',
	item_id?: string, //if undefined its not interactable
	mesh: keyof typeof itemList,
	indicatorOffset?: {x:number, y:number, z:number}

};
export type Character = {
	type: 'character' | 'drunkcharacter',
	default_mood?: Expression
	startMessages?: string[]
	start_sound?: string,
} & (SittingModel | StandingModel);

type SittingModel = {
	sitting?: true,
    idle: 'idle_sitting_lap' | 'idle_sitting_desk' | 'idle_sitting_rest'
};

type StandingModel = {
	sitting?: false,
    idle?:  'idle_body_01'	| 'idle_body_02'
};

export type BackgroundCharacter = {
	position?: Vector3, 
	rotation?: Euler,
	anim: ActionName,
	model: Models
};

export type Waypoint = {
	type: 'waypoint',
	positionId: string
};

