
import { useEffect } from 'react';
import { ModelProps } from './Human';

type ActionName =
  | 'action_aggitated_shouting'
  | 'action_body_shrug'
  | 'action_body_talking_01'
  | 'action_body_talking_02'
  | 'action_body_talking_03'
  | 'action_body_talking_happy'
  | 'action_body_talking_insecure'
  | 'action_body_talking_mad'
  | 'action_body_talking_sad'
  | 'action_order_disappointed'
  | 'action_order_putdown'
  | 'action_order_receive'
  | 'action_order_throw'
  | 'action_ped_angry_01'
  | 'action_ped_angry_02'
  | 'action_provocative_01'
  | 'action_provocative_02'
  | 'action_provocative_03'
  | 'action_robber_01'
  | 'action_robber_02'
  | 'action_robber_03'
  | 'action_robber_04'
  | 'action_threatening_01'
  | 'action_threatening_02'
  | 'action_threatening_stepback';

  type IdleName =
  | 'idle_angry_02'
  | 'idle_body_01'
  | 'idle_body_02'
  | 'idle_body_happy'
  | 'idle_body_headscratch'
  | 'idle_body_insecure'
  | 'idle_body_mad'
  | 'idle_body_sad'
  | 'idle_order'
  | 'idle_conversation'
  | 'idle_provocative'
  | 'idle_robber'
  | 'idle_threatening';

const StandingAnimationHandler = ({playAnimation, actions, props} :{playAnimation: (anim: string, idle: string) => void, actions: {[x: string]: THREE.AnimationAction}, props: ModelProps}) => {

	useEffect(() => {
		Object.values(actions).map((a) => a.stop().reset());

		actions[props.idle ?? 'idle_conversation'].play();
	}, [props.idle]);	

	useEffect(() => {		
		if(props.animation === '') return;
		
		//plays animations with idles
		const idles: {[key in ActionName]? : IdleName} = {
			action_body_talking_mad: 'idle_body_mad',
			action_body_talking_happy: 'idle_body_happy',
			action_body_talking_sad: 'idle_body_sad',
			action_body_talking_insecure: 'idle_body_insecure',
			action_order_receive: 'idle_order',
			action_order_throw: 'idle_angry_02',
			action_order_disappointed: 'idle_order',
			action_robber_01: 'idle_robber',
			action_robber_02: 'idle_robber',
			action_robber_03: 'idle_robber',
			action_robber_04: 'idle_robber',
			action_threatening_01: 'idle_threatening',
			action_threatening_02: 'idle_threatening',
			action_provocative_01:'idle_provocative',
			action_provocative_02:'idle_provocative',
			action_ped_angry_01:'idle_angry_02',
			action_ped_angry_02:'idle_angry_02',
		};

		
		const animation = (props.animation.includes('jet_') ? props.animation.replace('jet_', 'action_') : 'action_body_'+props.animation) as ActionName;

		const emotionIdle = idles[animation] ? idles[animation] : undefined; 
		playAnimation(animation, emotionIdle ?? 'idle_conversation');

	}, [props.animation]);

	return ( 
		<></>
	);
};
 
export default StandingAnimationHandler;