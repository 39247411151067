import { Suspense } from 'react';
import Routing from './Routing';

const App = () => {
	return ( 
		<Suspense>
			<Routing/>
		</Suspense>
	);
};

export default App;