
const ThreeLighting = () => {

	return (
		<>
			<directionalLight position={[10, 20, 10]} intensity={1} color={'#dbf3ff'} />
			<ambientLight intensity={1} color={'#fffdee'} />
		</>
	);
};

export default ThreeLighting;