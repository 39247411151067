import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_23: {[key: string]: SceneData} = {
	'sj4_s1' : 	{	
		camera_settings: {
			position: new Vector3(0,1.5,0),
			target: new Vector3(0,1.5, 0.1)
		},
		objects: {
			'4':{	
				type : 'character', 
				props: true,
				position: new Vector3(2,0,4.5),
				rotation: [0,Math.PI,0],
				startMessages: ['1055',''],
			},

		}
	},
};


export default scenes_scenario_23;