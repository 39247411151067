/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { Vector3 } from 'three';
import { controlsAtom, controlsEnabledAtom } from '../../../atoms/three';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { activeScenarioAtom } from '../../../atoms/scenario';
import { activeSceneAtom, activeWaypointAtom } from '../../../atoms/scene';
import { sceneList } from '../../../content/scenes/scene_list';

export const CameraManager = () => {

	const [controls] = useAtom(controlsAtom);
	const activeScenario = useAtomValue(activeScenarioAtom);
	const activeScene = useAtomValue(activeSceneAtom);

	const setControlsEnabled = useSetAtom(controlsEnabledAtom);

	const sceneData = useMemo(() => {
		if (!activeScene || !activeScenario) return undefined;
		return sceneList[activeScenario][activeScene];
	}, [activeScene]);

	//#endregion

	//#region waypoint specific movement

	const [activeWaypoint,] = useAtom(activeWaypointAtom)

	// actual waypoint that is active now
	const [currentWaypoint, setCurrentWaypoint] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (currentWaypoint === activeWaypoint) return;
		transitionCameraToActiveWaypoint()
	}, [activeWaypoint]);

	//** Transitions the camera to a waypoint */
	const transitionCameraToActiveWaypoint = async () => {
		if (!activeWaypoint || !sceneData || !sceneData.positions) return;

		const newPos = sceneData.positions[activeWaypoint].position;
		const newTarget = sceneData.positions[activeWaypoint].target
		await transitionCameraToDestination(newPos, newTarget);

		setCurrentWaypoint(activeWaypoint);
	}

	const transitionCameraToDestination = async (destinationPosition: Vector3, destinationTarget: Vector3) => {
		if (!controls) return;

		setControlsEnabled(false);
		controls.setTarget(destinationTarget.x, destinationTarget.y, destinationTarget.z, true);
		await controls.setPosition(destinationPosition.x, destinationPosition.y, destinationPosition.z, true);
		setControlsEnabled(true);
	}

	// set camera values based on sceneData
	useEffect(() => {
		if (!sceneData)
			setDefaultCamera()
		else
			sceneData.positions ? setCameraSceneDataForWaypoints() : setCameraSceneData()
	}, [controls, sceneData]);

	// set camera sceneData
	const setDefaultCamera= () => {
		if (!controls) return;
		controls.setPosition(0, 1, 0);
		controls.setTarget(0, 1, 0);
	};
	
	// set camera sceneData
	const setCameraSceneData = () => {
		if (!controls || !sceneData) return;
		const newPos = sceneData.camera_settings.position;
		controls.setPosition(newPos.x, newPos.y, newPos.z);
		const newTarget = sceneData.camera_settings.target;
		controls.setTarget(newTarget.x, newTarget.y, newTarget.z);
		if (sceneData.camera_settings.minAzimuthAngle !== undefined) controls.minAzimuthAngle = sceneData.camera_settings.minAzimuthAngle;
		if (sceneData.camera_settings.maxAzimuthAngle !== undefined) controls.maxAzimuthAngle = sceneData.camera_settings.maxAzimuthAngle;
		if (sceneData.camera_settings.minPolarAngle !== undefined) controls.minPolarAngle = sceneData.camera_settings.minPolarAngle;
		if (sceneData.camera_settings.maxPolarAngle !== undefined) controls.maxPolarAngle = sceneData.camera_settings.maxPolarAngle;
	};

	const [, setActiveWaypoint] = useAtom(activeWaypointAtom);

	// set camera sceneData for waypoint movement
	const setCameraSceneDataForWaypoints = () => {
		if (!controls || !sceneData) return;

		const startingPosition = sceneData.positions && sceneData.startingPosition && sceneData.positions[sceneData.startingPosition].position;
		const startingTarget = sceneData.positions && sceneData.startingPosition && sceneData.positions[sceneData.startingPosition].target;

		if (!startingPosition || !startingTarget) return;

		controls.setPosition(startingPosition.x, startingPosition.y, startingPosition.z);
		controls.setTarget(startingTarget.x, startingTarget.y, startingTarget.z); // might need to change to make sure we can give a specific starting direction

		setActiveWaypoint(sceneData.startingPosition);
	};

	//#endregion

	//#endregion

	return (<></>)

};