import styled from 'styled-components';
import Button, { BaseButtonProps } from './Button';

const IconButton = styled(Button)<IconButtonProps>`

    border-radius: 100%;
	height: 50px;

    aspect-ratio: 1/1;
	padding: 0;
	
    color: ${p => p.theme.colors.neutralLightest};
    background-color: ${p => p.$primary ? p.theme.colors.primary : p.theme.colors.neutral};

    display: flex;
	justify-content: center;
	align-items: center;

	& > svg {
		position: absolute;
		left: 50%; top:50%;
		translate: -50% -50%;
		width: 70%;
		height: 70%;
	}

	&:focus{
		outline-color: ${p => !p.$primary ? p.theme.colors.primary : p.theme.colors.neutralLightest};;
	}

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		height: 40px;
	}


	
`;

export interface IconButtonProps extends BaseButtonProps {
	$selected?: boolean,
	$primary?: boolean,
}

export default IconButton;