import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';
import { degToRad } from 'three/src/math/MathUtils.js';

const scenes_scenario_10: { [key: string]: SceneData } = {
	'sf_s1': {
		camera_settings: {
			position: new Vector3(-8.10307, 1.58734366, 7.5207460862),
			target: new Vector3(-7.5, 1.4, 8.7),
		},
		objects: {
			'5': {
				type: 'character',
				position: new Vector3(-7, 0, 8.7),
				rotation: [0, -1.9, 0],
				startMessages: ['21', ''],
			},
			'13': {
				type: 'character',
				position: new Vector3(-8, 0, 8.6),
				rotation: [0, 0.2 + Math.PI / 2, 0],
			},
			'kopje': {
				type: 'item',
				item_id: '8',
				mesh: 'kopje',
				position: new Vector3(-7.5, 0.92, 9.5),
				rotation: [0, 1, 0],
			},
		},
	},
	'sf_s2_ianpresentatie': {
		camera_settings: {
			position: new Vector3(-12.41793627, 1.1965107240, -0.7261336891),
			target: new Vector3(-10.7, 1, -0.65),
			maxAzimuthAngle: degToRad(350),
			minAzimuthAngle: degToRad(210),
		},
		objects: {
			'5': {
				type: 'character',
				position: new Vector3(-10.7, -0.45, -0.65),
				rotation: [0, -Math.PI / 2, 0],
				startMessages: ['33'],
				sitting: true,
				idle: 'idle_sitting_lap',
			},
		},
	},
	'sf_s2_ianfeedback': {
		camera_settings: {
			position: new Vector3(-12.41793627, 1.1965107240, -0.7261336891),
			target: new Vector3(-10.7, 1, -0.65),
			maxAzimuthAngle: degToRad(350),
			minAzimuthAngle: degToRad(210),
		},
		objects: {
			'5': {
				type: 'character',
				position: new Vector3(-10.7, -0.45, -0.65),
				rotation: [0, -Math.PI / 2, 0],
				startMessages: ['43'],
				sitting: true,
				idle: 'idle_sitting_lap',
			},
			'kopje': {
				type: 'item',
				mesh: 'kopje',
				position: new Vector3(-11.4, 0.83, -0.65),
				rotation: [0, -1, 0],
			},
		},
	},
};

export default scenes_scenario_10;