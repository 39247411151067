import { useAtom, useAtomValue } from 'jotai';
import { talkingToAtom } from '../../../atoms/messaging';
import { useEffect, useState } from 'react';
import { Expression } from '../../Three/models/characters/Human/Human';
import { GemDirectQueueAtom } from '../../../atoms/gem';
import { objectsStatesAtom } from '../../../atoms/scene';

const DCCharacterAppearance = () => { 

	const [talkingto] = useAtom(talkingToAtom);
	const [, setGemDirectQueue] = useAtom(GemDirectQueueAtom);
	const objectsStates= useAtomValue(objectsStatesAtom);

	const [anim, setAnim] = useState<string>('');
	const [mood, setMood] = useState<Expression>('neutral');

	useEffect(() => {
		if(!anim || !talkingto) return;		
		setGemDirectQueue([{type: 'set-character-animation', object_id: talkingto, animation: anim}]);		
	}, [anim]);

	useEffect(() => {
		if(!objectsStates[talkingto]) return;
		if(objectsStates[talkingto].animation === '')
			setAnim('');
	}, [objectsStates, talkingto]);

	useEffect(() => {
		if(!talkingto) return;		
		setGemDirectQueue([{type: 'set-character-mood', object_id: talkingto, mood: mood}]);
	}, [mood]);

	return ( 
		<>
			<label>talking to: {talkingto}</label>
			{talkingto && 
				<>
					<label> anim
						<select disabled={objectsStates[talkingto].animation != ''} value={objectsStates[talkingto].animation} onChange={(e) => setAnim(e.currentTarget.value)} >
							<option value={''} ></option>
							{ animOptions.map((value) => 
								<option value={value} key={`dc-animoption-${value}`}>{value}</option>
							)}
						</select>
					</label>
					<label> mood
						{/* @ts-expect-error string to specified type */}
						<select value={mood} onChange={(e) => setMood(e.currentTarget.value)} >
							{ moodOptions.map((value) => 
								<option value={value} key={`dc-moodoption-${value}`}>{value}</option>
							)}
						</select>
					</label>
				</>
			}
		</>
	);
};

const moodOptions: Expression[] = [
	'neutral' ,
	'happy' ,
	'mad' ,
	'sad' ,
	'insecure'
];

const animOptions: string[] = [
	'talking_01',
	'talking_02',
	'talking_03',
	'talking_happy',
	'talking_insecure',
	'talking_mad',
	'talking_sad',
	'action_head_nod',
	'jet_aggitated_shouting',
	'jet_order_disappointed',
	'jet_order_putdown',
	'jet_order_receive',
	'jet_order_throw',
	'jet_provocative_01',
	'jet_provocative_02',
	'jet_provocative_03',
	'jet_threatening_01',
	'jet_threatening_02',
	'jet_threatening_stepback',
	'jet_ped_angry_01',
	'jet_ped_angry_02',
	'jet_robber_01',
	'jet_robber_02',
	'jet_robber_03',
	'jet_robber_04'
];
 
export default DCCharacterAppearance;