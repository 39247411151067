import { useAtom, useSetAtom } from 'jotai';
import { enableFifoAtom } from '../../../atoms/fifo';
import { GemEventQueueEntry, GemQueueAtom, handleGemQueueAtom } from '../../../atoms/gem';
import { useEffect, useState } from 'react';

const DCGem = () => {

	const setEnableFifo = useSetAtom(enableFifoAtom);
	const [GemQueue, GemQueueDispatch] = useAtom(GemQueueAtom);
	const [,setHandleGemQueue] = useAtom(handleGemQueueAtom);
	const [type, setType] = useState<string>('set-object-state');
	const [firstParam, setFirstParam] = useState<string>('');
	const [secondParamBoolean, setSecondParamBoolean] = useState<boolean>(false);
	const [secondParamString, setSecondParamString] = useState<string>('');

	useEffect(() => {
		switch(type){
		case 'set-message':
			setEvent({type: 'set-message', message_id: secondParamString ?? ''} );
			break;
		case 'set-object-visibility':
			setEvent({type: 'set-object-visibility', object_id: firstParam, visible: secondParamBoolean});
			break;
		case 'set-object-state':
			setEvent({type: 'set-object-state', object_id: firstParam, state: parseInt(secondParamString)} );
			break;
		}
	}, [type, firstParam, secondParamString, secondParamBoolean]);

	useEffect(() => {
		setSecondParamString('');
		setSecondParamBoolean(false);
		setFirstParam('');
	}, [type]);

	const [event, setEvent] = useState<GemEventQueueEntry>();

	return ( 
		<>
			<button onClick={() => setEnableFifo(true)}>handle Queue by FIFO</button>
			<button onClick={() => setHandleGemQueue(true)}>handle Queue</button>
			<div>queue: {JSON.stringify(GemQueue)}</div>
			<label> type
				<select value={type} onChange={(e) => setType(e.currentTarget.value)} >
					{ TypeOptions.map((value) => 
						<option value={value} key={`dc-gem-typeoption-${value}`}>{value}</option>
					)}
				</select>
			</label>
			<label>
				{type === 'set-message' && 
				<>
					key <input type={'text'} onChange={(e) => setFirstParam(e.target.value)} value={firstParam}/><br></br>
					message key <input type={'text'} onChange={(e) => setSecondParamString(e.target.value)} value={firstParam}/>
				</>}
				{type === 'set-object-visibility' && 
				<>
					key <input type={'text'} onChange={(e) => setFirstParam(e.target.value)} value={firstParam}/>
					visible <input type='checkbox' checked={secondParamBoolean} onChange={(e) => setSecondParamBoolean(e.target.checked)}/>
				</>
				}
				{type === 'set-object-state' && 
				<>
					key <input type={'text'} onChange={(e) => setFirstParam(e.target.value)} value={firstParam}/>
					state <input type={'number'} onChange={(e) => setSecondParamString(e.target.value)} value={secondParamString}/>
				</>
				}
			</label>
			<button onClick={() => event && GemQueueDispatch({type: 'add', payload: event})}>
				add
			</button>
			<button onClick={() => event && GemQueueDispatch({type: 'remove', payload: event})}>
				remove
			</button>
			<button onClick={() => GemQueueDispatch({type: 'clear'})}>
				clear
			</button>
		</>
	);
};

// data

const TypeOptions = [
	'set-object-visibility', 
	'set-message',
	'set-object-state',
];
 
export default DCGem;