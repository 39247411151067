import styled from 'styled-components';
import { useAtomValue } from 'jotai';
import { threeLoadedAtom } from '../../atoms/three';
import Feedback from './feedback/Feedback';
import GameOptions from './GameOptions';
import InteractiveObject from './InteractiveItem/InteractiveItem';
import { FIFO } from './FIFO';
import Message from './Message/Message';

// components
const Interface = () => {

	const threeLoaded = useAtomValue(threeLoadedAtom);

	return (
		<>
			{threeLoaded &&
				<Container>
					<Message />
					<GameOptions />
					<InteractiveObject />
					<Feedback />
					<FIFO />
				</Container>
			}
		</>
	);
};

// styled components

const Container = styled.div`
	position: absolute;
	inset:0;
	z-index: 1;
	pointer-events: none;
	display: flex;
	flex-direction: column;
`;

export default Interface;