import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';

import model from './glb/3D_poster_pile-transformed.glb';

type GLTFResult = GLTF & {
	nodes: {
		['2D_poster_pile']: THREE.Mesh
	}
	materials: {
		p2_poster_atlas: THREE.MeshStandardMaterial
	}
  };

export const Posters = (props: JSX.IntrinsicElements['group']) => {
	const { nodes, materials } = useGLTF(model) as GLTFResult;
	return (
		<group {...props} dispose={null}>
			<mesh name="2D_poster_pile" geometry={nodes['2D_poster_pile'].geometry} material={materials.p2_poster_atlas} />
		</group>
	);
};

useGLTF.preload(model);
