import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { TimerResult } from 'react-timer-hook';

//message
export const activeMessageAtom = atom('');
export const messageTimerAtom = atom<TimerResult | undefined>(undefined);

export const talkingToAtom = atom<string>(''); //indicate to which appearance you are speaking
export const selectionDelay = 1; // in seconds

export const resetingStateAtom = atom<number>(0);//0 = no reset message, 1 = reseting, 2 = looking at reset message
export const seenMessagesAtom = atom<string[]>([]);

//reset message
export const seenClickToContinueContextMessageAtom = atomWithStorage<boolean>('clicktocontinueseen', false);

