import { Vector3 } from 'three';
import { Character, SceneObjectType } from '../../atoms/scene';

//positions for Office
export const officePositions:{[key:string]:SceneObjectType<Character>} = {

	//character at meeting room, sitting.
	characterPosition1:{

		type: 'character',
		position: new Vector3(-7.626248944283985, -0.425, -1.3192186196365214),
		rotation: [0, 0, 0],
		sitting: true,
		idle: 'idle_sitting_desk',
	},

	//character on the left at kitchen
	characterPosition2:{

		type: 'character',
		position: new Vector3(-7.202475580421008, 0, 8.828399339316148),
		rotation: [0, 4, 0],
		sitting: false,
		idle: 'idle_body_01',
	},

	//character on the left at kitchen
	characterPosition3:{

		type: 'character',
		position: new Vector3(-8.6, 0, 8.828399339316148),
		rotation: [0, 1.5, 0],
		sitting: false,
		idle: 'idle_body_01',
	},

	
	characterPosition4:{

		type: 'character',
		position: new Vector3(-11.75, -0.42, 6.734498226390701),
		rotation: [0, 3, 0],
		sitting: true,
		idle: 'idle_sitting_desk',
	},

};

//positions for Office
export const callCenterPositions:{[key:string]:SceneObjectType<Character>} = {

	//character at meeting room, sitting.
	characterPosition1:{

		type: 'character',
		position: new Vector3(-7.626248944283985, -0.425, -1.3192186196365214),
		rotation: [0, 0, 0],
		sitting: true,
		idle: 'idle_sitting_desk',
	},

	//character at pc position1
	characterPosition2:{

		type: 'character',
		position: new Vector3(-8.27, -0.42, 7),
		rotation: [0, -0.4, 0],
		sitting: true,
		idle: 'idle_sitting_desk',
	},

	//character at watercooler position1
	characterPosition3:{

		type: 'character',
		position: new Vector3(-8.8193584956855, 0, 9.5),
		rotation: [0, -2.1, 0],
		sitting: false,
		idle: 'idle_body_01',
	},

	//character at watercooler position2
	characterPosition4:{

		type: 'character',
		position: new Vector3(-10.5, 0, 9.5),
		rotation: [0, 1.3, 0],
		sitting: false,
		idle: 'idle_body_01',
	},

	//character at pc position
	characterPosition5:{

		type: 'character',
		position: new Vector3(-11.6, -0.42, 4.76),
		rotation: [0, 0, 0],
		sitting: true,
		idle: 'idle_sitting_desk',
	},
	
};