import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';
import { degToRad } from 'three/src/math/MathUtils.js';

const scenes_scenario_31: {[key: string]: SceneData} = {
	'sv2_office' : {		
		camera_settings: {
			position: new Vector3(-6.564209272337963, 1.3350658368155877, 3.1405318706981227),
			target: new Vector3(-9.5, 1, 6),
			maxAzimuthAngle: degToRad(200),
			minAzimuthAngle: degToRad(130),
		},
		objects: {
			'10':{	
				type: 'character',
				position: new Vector3(-7, 0, 8.7),
				rotation: [0, -1.9, 0],
				startMessages: ['1243', ''],
			},
			'3':{				
				type : 'character', 
				position: new Vector3(-13.05, -0.42, 4.8),
				rotation: [0,0,0],
				idle: 'idle_sitting_desk',
			},
		},
	},
	'sv2_meetingroom' : {		
		camera_settings: {
			position: new Vector3(-12.41793627, 1.1965107240, -0.7261336891),
			target: new Vector3(-10.7, 1, -0.65),
			maxAzimuthAngle: degToRad(350),
			minAzimuthAngle: degToRad(210),
		},
		objects: {
			'10': {
				type: 'character',
				position: new Vector3(-10.7, -0.45, -0.65),
				rotation: [0, -Math.PI / 2, 0],
				startMessages: ['2099'],
				sitting: true,
				idle: 'idle_sitting_lap',
				default_mood: 'insecure',
			},
		},
	},
};

export default scenes_scenario_31;