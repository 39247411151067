import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_29 : {[key: string]: SceneData} = {
	'sj6_s1' : {
		camera_settings: {
			position: new Vector3(-4,1.5,0),
			target: new Vector3(-4,1.5,0.1),
		},
		objects: {
			'4':{	
				type : 'character', 
				props: true,
				position: new Vector3(-3,0,3),
				rotation: [0,Math.PI,0],
				startMessages: ['1061',''],
			},

		}
	}
};

export default scenes_scenario_29;