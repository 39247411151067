import { Fragment, useMemo } from 'react';
import { SceneObjectsType, objectsStatesAtom } from '../../../../atoms/scene';
import Character from './Character';
import Waypoint from './Waypoint';
import Item from './Item';
import { useAtomValue } from 'jotai';

const SceneObject = (props: ExtendSceneObjectProps) => {

	const sceneObjectStates = useAtomValue(objectsStatesAtom);

	const state = useMemo(() => {
		return {...sceneObjectStates[props.id]};
	},[props.id, sceneObjectStates]);

	return (
		<Fragment>
			{(props.object.type === 'character' || props.object.type === 'drunkcharacter') &&
				<Character
					interactive={props.interactive}
					object={props.object}
					id={props.id}
					state={state}
				/>
			}
			{props.object.type === 'item' &&
				<Item
					interactive={props.interactive}
					object={props.object}
					id={props.id}
					state={state}
				/>
			}
			{props.object.type === 'waypoint' &&
				<Waypoint
					interactive={props.interactive}
					object={props.object}
					id={props.id}
				/>
			}
		</Fragment>
	);
};

// type
type ExtendSceneObjectProps = SceneObjectProps & {
	object: SceneObjectsType
};

export type SceneObjectProps = {
	interactive: boolean
	id: string
};

export default SceneObject;