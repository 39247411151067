import { useAtomValue } from 'jotai';
import GlobalEventManager from '../managers/GEM/GlobalEventManager';
import { CameraManager } from './controls/CameraManager';
import ThreeControls from './controls/ThreeControls';
import SceneManager from './scene/SceneManager';
import ThreeCanvas from './ThreeCanvas';
import ThreeLighting from './ThreeLighting';
import ThreePerformance from './ThreePerformance';
import WaypointControls from './controls/WaypointControls';
import { activeSceneAtom } from '../../atoms/scene';
import { useMemo } from 'react';
import { sceneList } from '../../content/scenes/scene_list';
import { activeScenarioAtom } from '../../atoms/scenario';


const Three = () => {

	const activeSceneId = useAtomValue(activeSceneAtom);
	const activeScenarioId = useAtomValue(activeScenarioAtom);
	
	const movementType = useMemo(() => {
		const scene = activeScenarioId && activeSceneId && sceneList[activeScenarioId][activeSceneId];
		if(scene && scene.positions) return 'waypoints';
		else return 'default';
	},[activeScenarioId, activeSceneId]);

	return ( 
		<ThreeCanvas>
			<GlobalEventManager/>
			{/* Determine Movement System based on activeTopic */}
			{ movementType === 'default' ?
				<>
					<ThreeControls/>
				</> : movementType === 'waypoints' ?
					<>
						<WaypointControls/>
					</> : <></>
			}
			<CameraManager/>
			<ThreePerformance/>
			<ThreeLighting/>
			<SceneManager/>
		</ThreeCanvas>
	);
};
 
export default Three;