import complete from '../../assets/sounds/complete.wav';
import negative_beep from  '../../assets/sounds/negative_beep.wav';
import alert from '../../assets/sounds/alert.wav';
import office from '../../assets/sounds/background_office.mp3';
import street from '../../assets/sounds/ambient_street.mp3';
import party from '../../assets/sounds/ambient_party.mp3';
import bag_crinkle_01 from '../../assets/sounds/bag_crinkle_01.mp3';
import bag_crinkle_02 from '../../assets/sounds/bag_crinkle_02.mp3';
import bag_throw from '../../assets/sounds/bag_throw.mp3';
import character_female_angry_01_v1 from '../../assets/sounds/character/character_female_angry_01_v1.mp3';
import character_female_angry_01_v2 from '../../assets/sounds/character/character_female_angry_01_v2.mp3';
import character_female_angry_01_v3 from '../../assets/sounds/character/character_female_angry_01_v3.mp3';
import character_female_angry_holler_v2 from '../../assets/sounds/character/character_female_angry_holler_v2.mp3';
import character_female_angry_holler from '../../assets/sounds/character/character_female_angry_holler.mp3';
import character_female_dissatisfied_01_v1 from '../../assets/sounds/character/character_female_dissatisfied_01_v1.mp3';
import character_female_dissatisfied_01_v2 from '../../assets/sounds/character/character_female_dissatisfied_01_v2.mp3';
import character_male_angry_01_v1 from '../../assets/sounds/character/character_male_angry_01_v1.mp3';
import character_male_angry_01_v2 from '../../assets/sounds/character/character_male_angry_01_v2.mp3';
import character_male_angry_02 from '../../assets/sounds/character/character_male_angry_02.mp3';
import character_male_angry_03_v1 from '../../assets/sounds/character/character_male_angry_03_v1.mp3';
import character_male_angry_03_v2 from '../../assets/sounds/character/character_male_angry_03_v2.mp3';
import character_male_angryshouting_01_v1 from '../../assets/sounds/character/character_male_angryshouting_01_v1.mp3';
import character_male_angryshouting_01_v2 from '../../assets/sounds/character/character_male_angryshouting_01_v2.mp3';
import character_male_angryshouting_01_v3 from '../../assets/sounds/character/character_male_angryshouting_01_v3.mp3';
import character_male_angryshouting_01_v4 from '../../assets/sounds/character/character_male_angryshouting_01_v4.mp3';
import character_male_creepyflirting_01_v1 from '../../assets/sounds/character/character_male_creepyflirting_01_v1.mp3';
import character_male_creepyflirting_01_v2 from '../../assets/sounds/character/character_male_creepyflirting_01_v2.mp3';
import character_male_creepyflirting_02_v1 from '../../assets/sounds/character/character_male_creepyflirting_02_v1.mp3';
import character_male_creepyflirting_02_v2 from '../../assets/sounds/character/character_male_creepyflirting_02_v2.mp3';
import character_male_dissatisfied_01_v1  from '../../assets/sounds/character/character_male_dissatisfied_01_v1.mp3';
import character_male_dissatisfied_01_v2 from '../../assets/sounds/character/character_male_dissatisfied_01_v2.mp3';
import character_male_dissatisfied_01_v3 from '../../assets/sounds/character/character_male_dissatisfied_01_v3.mp3';
import character_male_dissatisfied_02_v1 from '../../assets/sounds/character/character_male_dissatisfied_02_v1.mp3';
import character_male_dissatisfied_02_v2 from '../../assets/sounds/character/character_male_dissatisfied_02_v2.mp3';
import character_male_greeting_01_v1 from '../../assets/sounds/character/character_male_greeting_01_v1.mp3';
import character_male_greeting_01_v2 from '../../assets/sounds/character/character_male_greeting_01_v2.mp3';
import character_male_greeting_02_v1 from '../../assets/sounds/character/character_male_greeting_02_v1.mp3';
import character_male_greeting_02_v2 from '../../assets/sounds/character/character_male_greeting_02_v2.mp3';
import character_male_greeting_03_v1 from '../../assets/sounds/character/character_male_greeting_03_v1.mp3';
import character_male_greeting_03_v2 from '../../assets/sounds/character/character_male_greeting_03_v2.mp3';
import character_male_happy_01_v1 from '../../assets/sounds/character/character_male_happy_01_v1.mp3';
import character_male_happy_01_v2 from '../../assets/sounds/character/character_male_happy_01_v2.mp3';
import character_male_happy_02_v1 from '../../assets/sounds/character/character_male_happy_02_v1.mp3';
import character_male_happy_02_v2 from '../../assets/sounds/character/character_male_happy_02_v2.mp3';
import character_male_hiccup from '../../assets/sounds/character/character_male_hiccup.mp3';
import character_male_robbery_01 from '../../assets/sounds/character/character_male_robbery_01.wav';
import ellie_happy from '../../assets/sounds/ellie_happy.mp3';
import ellie_reset from '../../assets/sounds/ellie_reset_v2.mp3';
import scenario_failed from '../../assets/sounds/scenario_failed.mp3';
import scenario_completed from '../../assets/sounds/scenario_completed.mp3';

import { Howl } from 'howler';

export const AudioLibrary: {[key: string] : Howl} = {
	decision_correct: new Howl({
		src: [complete],
		loop: false,
		preload: false,
		autoplay: false,
	}),
	decision_incorrect: new Howl({
		src: [negative_beep],
		loop: false,
		preload: false,
		autoplay: false,
	}),
	important_moment: new Howl({
		src: [alert],
		loop: false,
		preload: false,
		autoplay: false,
	}),
	background_office: new Howl({
		src: [office],
		loop: true,
		preload: false,
		autoplay: false,
		volume: 0.1
	}),
	ellie_reset: new Howl({
		src: [ellie_reset],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.25
	}),
	ellie_happy: new Howl({
		src: [ellie_happy],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.25
	}),
	scenario_completed: new Howl({
		src: [scenario_completed],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.25
	}),
	scenario_failed: new Howl({
		src: [scenario_failed],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.25
	}),

	//JET

	//background
	background_street: new Howl({
		src: [street],
		loop: true,
		preload: false,
		autoplay: false,
		volume: 0.1
	}),
	background_party: new Howl({
		src: [party],
		loop: true,
		preload: false,
		autoplay: false,
		volume: 0.25
	}),	
	//props
	jet_order_putdown: new Howl({
		src: [bag_crinkle_01],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	jet_order_receive: new Howl({
		src: [bag_crinkle_02],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	jet_order_throw: new Howl({
		src: [bag_throw],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),

	bag_crinkle_01: new Howl({
		src: [bag_crinkle_01],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_female_angry_01_v1 : new Howl({
		src: [character_female_angry_01_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_female_angry_01_v2: new Howl({
		src: [character_female_angry_01_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_female_angry_01_v3: new Howl({
		src: [character_female_angry_01_v3],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_female_angry_holler_v2: new Howl({
		src: [character_female_angry_holler_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_female_angry_holler: new Howl({
		src: [character_female_angry_holler],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_female_dissatisfied_01_v1: new Howl({
		src: [character_female_dissatisfied_01_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_female_dissatisfied_01_v2: new Howl({
		src: [character_female_dissatisfied_01_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_angry_01_v1 : new Howl({
		src: [character_male_angry_01_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_angry_01_v2: new Howl({
		src: [character_male_angry_01_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_angry_02: new Howl({
		src: [character_male_angry_02],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_angry_03_v1: new Howl({
		src: [character_male_angry_03_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_angry_03_v2: new Howl({
		src: [character_male_angry_03_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_angryshouting_01_v1: new Howl({
		src: [character_male_angryshouting_01_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_angryshouting_01_v2: new Howl({
		src: [character_male_angryshouting_01_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_angryshouting_01_v3: new Howl({
		src: [character_male_angryshouting_01_v3],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_angryshouting_01_v4: new Howl({
		src: [character_male_angryshouting_01_v4],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_creepyflirting_01_v1: new Howl({
		src: [character_male_creepyflirting_01_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_creepyflirting_01_v2: new Howl({
		src: [character_male_creepyflirting_01_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_creepyflirting_02_v1: new Howl({
		src: [character_male_creepyflirting_02_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_creepyflirting_02_v2: new Howl({
		src: [character_male_creepyflirting_02_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_dissatisfied_01_v1 : new Howl({
		src: [character_male_dissatisfied_01_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_dissatisfied_01_v2: new Howl({
		src: [character_male_dissatisfied_01_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_dissatisfied_01_v3: new Howl({
		src: [character_male_dissatisfied_01_v3],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_dissatisfied_02_v1: new Howl({
		src: [character_male_dissatisfied_02_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_dissatisfied_02_v2: new Howl({
		src: [character_male_dissatisfied_02_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_greeting_01_v1: new Howl({
		src: [character_male_greeting_01_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_greeting_01_v2: new Howl({
		src: [character_male_greeting_01_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_greeting_02_v1: new Howl({
		src: [character_male_greeting_02_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_greeting_02_v2: new Howl({
		src: [character_male_greeting_02_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_greeting_03_v1: new Howl({
		src: [character_male_greeting_03_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_greeting_03_v2: new Howl({
		src: [character_male_greeting_03_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_happy_01_v1: new Howl({
		src: [character_male_happy_01_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_happy_01_v2: new Howl({
		src: [character_male_happy_01_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_happy_02_v1: new Howl({
		src: [character_male_happy_02_v1],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_happy_02_v2: new Howl({
		src: [character_male_happy_02_v2],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_hiccup: new Howl({
		src: [character_male_hiccup],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	}),
	character_male_robbery_01: new Howl({
		src: [character_male_robbery_01],
		loop: false,
		preload: false,
		autoplay: false,
		volume: 0.5
	})
};
