import { CameraControls, CameraControlsProps } from '@react-three/drei';
import { useEffect, useRef, useState } from 'react';
import { CameraControlsReference } from './ThreeControls';
import { useAtom } from 'jotai';
import { controlsAtom } from '../../../atoms/three';
import { Vector3 } from 'three';

const WaypointControls = () => {

	const cameraControls = useRef<CameraControlsReference>(null);
	const [controls, setControls] = useAtom(controlsAtom);
	const [initialized, setInitialized] = useState<boolean>(false);


	// when controls ref is set, set camera to starting position
	useEffect(() => {
		setControls(cameraControls.current);
		if(cameraControls.current && !controls){
			setCameraToStartingPos();
		}
	}, [cameraControls]);

	// sets the camera to a starting position
	const setCameraToStartingPos = () => {
		if(initialized) return;
		if(!cameraControls.current) return;
		const cameraPos : Vector3 = cameraStartingPosition;
		const targetPos : Vector3 = targetStartingPosition;
		cameraControls.current.setPosition(cameraPos.x, cameraPos.y, cameraPos.z, false);
		cameraControls.current.setTarget(targetPos.x, targetPos.y, targetPos.z, false);
		// cameraControls.current.rotateTo(5.6, 1.5, false);
		if(!initialized) setInitialized(true);
	};

	return ( 
		<>
			<CameraControls
				ref={cameraControls}
				makeDefault
				{...config}
			/>
		</>
	);
};

// data
export const cameraStartingPosition: Vector3 = new Vector3(0,1.8,0);
export const targetStartingPosition: Vector3 = new Vector3(cameraStartingPosition.x,cameraStartingPosition.y,cameraStartingPosition.z - 0.1);
const config : CameraControlsProps = {
	truckSpeed: 0,
	dollySpeed: 0,
	azimuthRotateSpeed: 1,
	restThreshold: 0.001,

	// min maxes
	polarRotateSpeed: 1,
	// minAzimuthAngle: 5.0,
	// maxAzimuthAngle: 8.11,
};
 
export default WaypointControls;