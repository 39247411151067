import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { availableLanguagesAtom } from '../../../atoms/content';

const DCLocalization = () => {

	const {i18n} = useTranslation();
	const [languages] = useAtom(availableLanguagesAtom);


	return(
		<>
			{languages && languages.map((lng) => {
				return (<button disabled={i18n.language === lng} onClick={() => i18n.changeLanguage(lng)} key={lng}>{lng}</button>);
			})}
		</>
	);
};

export default DCLocalization;
