import { useAtom, useAtomValue, } from 'jotai';
import { SceneData, activeItemIdAtom } from '../../../atoms/scene';
import { controlsEnabledAtom } from '../../../atoms/three';
import { gameResultAtom } from '../../../atoms/gameResults';
import SceneObject from './objects/SceneObject';
import { useMemo } from 'react';
import { activeMessageAtom } from '../../../atoms/messaging';

const SceneObjects = ({objects}: {objects: SceneData['objects']}) => {

	const controlsEnabled = useAtomValue(controlsEnabledAtom);
	const gameResult = useAtomValue(gameResultAtom);

	const [activeItem,] = useAtom(activeItemIdAtom);
	const [activeMessage,] = useAtom(activeMessageAtom);


	// determines wether any SceneObject is interactive.
	const interactive = useMemo(() => {
		if(!controlsEnabled) {return false;}
		if(activeItem !== undefined) {return false;}
		if(activeMessage !== '') {return false;}
		if(gameResult.ending_id !== undefined) {return false;}
		return true;
	},[controlsEnabled, activeItem, activeMessage, gameResult]);

	return ( 
		<>
			{Object.entries(objects).map(([id, object]) => {
				
				return <SceneObject
					interactive={interactive}
					object={object}
					id={id}
					key={`scene-object-${id}`}
				/>;

			})}
		</>
	);
};
 
export default SceneObjects;