import { Euler, Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_55: {[key: string]: SceneData} = {
	'bos1_marc' : {		
		camera_settings: {
			position: new Vector3(3.056160796918933, 1.5005433322386412, -2.792722723869409),
			target: new Vector3(0, 1, -7),
		},
		objects: {
			'5':{	
				type : 'character', 
				position: new Vector3(0, 0, -7),
			},

		},			
		background_characters: [
			{	
				anim: 'idle_01',
				model: 'morgan'
			}
		]
	},
	'bos1_marc_jackets' : {		
		camera_settings: {
			position: new Vector3(-2.5106248436851275, 1.5879035354959135, 3.141786010531283),
			target: new Vector3(-2.5, 1.4, 4),
		},
		objects: {
			'5':{	
				type : 'character', 
				position: new Vector3(-2.5, 0, 4),
				rotation: [0,Math.PI,0],
			}
		},			
		background_characters: [
			{	
				position: new Vector3(2, 0, 8),
				rotation: new Euler(0,1.5,0),
				anim: 'idle_01',
				model: 'morgan'
			}
		]
	},
	'bos1_marc_desk' : {		
		camera_settings: {
			position: new Vector3(2.51364, 1.503348, -3.211),
			target: new Vector3(0, 1.4, -5),
		},
		objects: {
			'5':{	
				type : 'character', 
				position: new Vector3(1.2, 0, -4.1),
				rotation: [0,1,0],
			}
		},
	}
};

export default scenes_scenario_55;