import styled, { css } from 'styled-components';
import EllieAvatar, { EllieEmotions } from '../EllieAvatar';
import { Children, HTMLAttributes } from 'react';
import Markdown from 'react-markdown';

const EllieSays = (props: EllieSaysProps) => {

	return ( 
		<Container {...props} >
			<EllieAvatar $emotion={props.$emotion}/>
			<Message>
				{Children.toArray(props.children).map((child, index) => {
					return(	
						<div key={`child-${index}`}>	
							{typeof child === 'string' ? <Markdown className='markdown'>{child}</Markdown> : child}
						</div>
					);
				})}
			</Message>
		</Container>
	);
};

// sc

const Container = styled.div`
	gap: 15px;
	display: flex;
	width: 100%;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		flex-direction: column;
	}
`;

const Message = styled.div`

	background-color: ${p => p.theme.colors.neutral};
	color: ${p => p.theme.colors.neutralLightest};

	padding: 20px;
	border-radius: 30px;

	width: 100%;
	position: relative;

	font-style: italic;

	height: fit-content;
	margin: auto;


	&:before{
		content:'';
		position:absolute;

		border: 10px solid transparent;


		${p => p.theme.direction === 'ltr' ? css`
			right:100%;bottom:50%;
			translate: 5% 50%;
			border-right-color: ${p => p.theme.colors.neutral};

		` : css`
			left:100%;bottom:50%;
			translate: -5% 50%;
			border-left-color: ${p => p.theme.colors.neutral};
		`}

		height:0;width:0;
		border-width: 10px;
	}


	@media (max-width: ${p => p.theme.responsive.media.sm}){
		&:before{
			bottom:100%; right:50%;
			translate: 50% 0;
			border: 10px solid transparent;
			border-bottom-color: ${p => p.theme.colors.neutral};
			border-width: 10px;
			margin-bottom: 0;
		}
	}
`;

// types

interface EllieSaysProps extends HTMLAttributes<HTMLDivElement>{
	$emotion?: EllieEmotions;
}
 
export default EllieSays;