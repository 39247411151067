import styled from 'styled-components';
import AudioToggle from './AudioToggle';
import FullscreenToggle from './FullscreenToggle';
import IconButton from '../buttons/IconButton';
import { useMemo, useState } from 'react';

// icons

import {SlOptions} from 'react-icons/sl';
import { MdOutlineClose } from 'react-icons/md';
import { slideInTop } from '../../style/animations';

const GameOptions = () => {

	const [open, setOpen] = useState<boolean>(false);

	const tabIndex = useMemo(() => open ? 2 : -1,[open]);


	return ( 
		<>
			<Container open={open}>
				<ToggleButton onClick={() => {setOpen(o => !o);}} tabIndex={1}>{!open ? <SlOptions/> : <MdOutlineClose/>}</ToggleButton>
				<FullscreenToggle tabIndex={tabIndex}/>
				<AudioToggle tabIndex={tabIndex}/>
			</Container>
		</>

	);
};

// styled

const ToggleButton = styled(IconButton)`
	
	color: ${p => p.theme.colors.neutral};
    background-color: ${p => p.theme.colors.neutralLightest};
`;

const sizeData = {
	sm : {
		buttonSize : 40,
		containerSize : 60,
		padding : 0,
		buttonCount : 2

	},
	lg: {
		buttonSize : 50,
		containerSize : 70,
		padding : 0,
		buttonCount : 2
	}
};

Object.values(sizeData).map((value) => {value.padding = (value.containerSize - value.buttonSize) / 2;});


const Container = styled.div<{open: boolean}>`
	position: absolute;
	top: 0; left:0; right:0;
	margin-inline-end: auto;
	margin-inline-start: ${p => p.theme.styles.padding / 2}px;
	margin-block-start: ${p => p.theme.styles.padding / 2}px;
	pointer-events: all;

	background-color: ${p => p.theme.colors.neutralLightest};
	border-radius: 50em;

	display: flex;
	height: ${sizeData.lg.containerSize};
	width: ${p => !p.open ? `${sizeData.lg.containerSize}px` : `${sizeData.lg.containerSize + (sizeData.lg.padding * (sizeData.lg.buttonCount)) + (sizeData.lg.buttonSize * sizeData.lg.buttonCount)}px`};
	overflow: clip;
	align-items: center;
	padding: ${sizeData.lg.padding}px;
	gap: ${sizeData.lg.padding}px;

	opacity: 0;
	animation: ${slideInTop} 1s forwards;
	animation-delay: 2s;

	transition: width .5s ease;
	& > button {
		height: ${sizeData.lg.buttonSize}px;
	}

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		left: 10px; top: 10px;
		height: ${sizeData.sm.containerSize};
		width: ${p => !p.open ? `${sizeData.sm.containerSize}px` : `${sizeData.sm.containerSize + (sizeData.sm.padding * (sizeData.sm.buttonCount)) + (sizeData.sm.buttonSize * sizeData.sm.buttonCount)}px`};
		padding: ${sizeData.sm.padding}px;
		gap: ${sizeData.sm.padding}px;
		& > button {
			height: ${sizeData.sm.buttonSize}px;
		}
	}


`;
 
export default GameOptions;