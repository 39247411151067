import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';
import { degToRad } from 'three/src/math/MathUtils.js';

const scenes_scenario_12: { [key: string]: SceneData } = {
	sf3_workspaces: {
		camera_settings: {
			position: new Vector3(-5.957972, 1.553006, 1.9625445),
			target: new Vector3(-7.5, 1, 3),
			maxAzimuthAngle: degToRad(140),
			minAzimuthAngle: degToRad(70),
		},
		objects: {
			'22': {
				type: 'character',
				position: new Vector3(-8.55, -0.45, 7.05),
				rotation: [0, Math.PI - 0.7, 0],
				startMessages: ['181', '195', ''],
				idle: 'idle_sitting_lap',
				sitting: true,
				default_mood: 'neutral',
			},
			'5': {
				type: 'character',
				position: new Vector3(-11.4, 0, 1.5),
				rotation: [0, -0.5 + Math.PI / 2, 0],
				startMessages: ['', '315'],
				default_mood: 'neutral',
			},
			'monitor': {
				type: 'item',
				item_id: '2',
				mesh: 'monitor',
				position: new Vector3(-11.2, .772, 2.35),
				rotation: [0, -Math.PI, 0],
				indicatorScale: 0.2,
				indicatorOffset: {
					x: 0,
					y: 0.2,
					z: -0.1,
				},
			}
		}
	},

	sf3_boss: {
		camera_settings: {
			position: new Vector3(-12.41793627, 1.1965107240, -0.7261336891),
			target: new Vector3(-10.7, 1, -0.65),
			maxAzimuthAngle: degToRad(350),
			minAzimuthAngle: degToRad(210),
		},
		objects: {
			'22': {
				type: 'character',
				position: new Vector3(-9.7, -0.45, 10.25),
				rotation: [0, (Math.PI / 2) + 0.2, 0],
				idle: 'idle_sitting_lap',
				sitting: true,
				default_mood: 'neutral',
			},
			'5': {
				type: 'character',
				position: new Vector3(-10.7, -0.45, -0.65),
				rotation: [0, -Math.PI / 2, 0],
				sitting: true,
				idle: 'idle_sitting_lap',
				default_mood: 'neutral',
			},
		}
	}
};

export default scenes_scenario_12;