import { useAtom } from 'jotai';
import { activeWaypointAtom, SceneObjectType, Waypoint as WaypointType } from '../../../../atoms/scene';
import InteractiveSceneObject from '../InteractiveSceneObject';
import { SceneObjectProps } from './SceneObject';

const Waypoint = (props: WaypointProps) => {

	const [activeWaypoint, setActiveWaypoint] = useAtom(activeWaypointAtom);

	const onClick = () => {
		setActiveWaypoint(props.object.positionId);
	};

	return ( 
		<InteractiveSceneObject
			onClick={() => {onClick();}}
			interactable={props.interactive}
			position={props.object.position}
			data={props.object}
			objectId={props.id}
			visible={activeWaypoint !== props.object.positionId}
		>

		</InteractiveSceneObject>
	);
};

// types

type WaypointProps = SceneObjectProps & {
	//
	object: SceneObjectType<WaypointType>
};
 
export default Waypoint;