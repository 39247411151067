import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';

const scenes_scenario_21: {[key: string]: SceneData} = {
	'sj2_s1' : {		
		camera_settings: {
			position: new Vector3(0,1.5,0),
			target: new Vector3(0,1.5, 0.1)
		},
		
		objects: {
			'5':{	
				type : 'character', 
				props: true,
				position: new Vector3(1.95,0,4.5),
				rotation: [0,Math.PI,0],
				startMessages: ['1075',''],
			},

		}
	},
	
};


export default scenes_scenario_21;