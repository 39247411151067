import { Vector3 } from 'three';
import { SceneData } from '../../../atoms/scene';
import { degToRad } from 'three/src/math/MathUtils.js';

const scenes_scenario_32: {[key: string]: SceneData} = {
	'sv3_office' : {		
		camera_settings: {
			position: new Vector3(-9.25845273, 1.2988865726, 4.51494765),
			target: new Vector3(-13, 1, 4.2),
			maxAzimuthAngle: degToRad(120),
			minAzimuthAngle: degToRad(40),
		},
		objects: {
			'4':{
				type: 'character',
				position: new Vector3(-11.2, 0, 1.5),
				rotation: [0, -0.5 + Math.PI / 2, 0],
				startMessages: ['1296', ''],
				default_mood: 'mad',
			},
			'10':{				
				type : 'character', 
				position: new Vector3(-12.5, 0, 7),
				rotation: [0,Math.PI-0.7,0],
				idle: 'idle_body_02',
				default_mood: 'happy',
			},
			'13':{				
				type : 'character', 
				position: new Vector3(-13.05, -0.42, 4.8),
				rotation: [0,0,0],
				idle: 'idle_sitting_desk',
			},
		},
	},
	'sv3_meetingroom' : {		
		camera_settings: {
			position: new Vector3(-12.41793627, 1.1965107240, -0.7261336891),
			target: new Vector3(-10.7, 1, -0.65),
			maxAzimuthAngle: degToRad(350),
			minAzimuthAngle: degToRad(210),
		},
		objects: {
			'4': {
				type: 'character',
				position: new Vector3(-10.7, -0.45, -0.65),
				rotation: [0, -Math.PI / 2, 0],
				startMessages: ['1302'],
				sitting: true,
				idle: 'idle_sitting_lap',
			},
		},
	},
};

export default scenes_scenario_32;